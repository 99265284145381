class Cookie {
    public static parse = (raw: string, key: string): string|null => {
        const line = raw.split(';')
            .map(s => s.trim())
            .find(s => s.startsWith(key));

        if (!line) {
            return null;
        }

        return line?.split('=')[1] ?? null;
    };

    public static write = (
        key: string,
        value: string,
        expiresTime?: number,
    ): void => {
        let expires = '';
        if (expiresTime) {
            const timeTo = new Date(new Date().getTime() + expiresTime).toUTCString();
            expires = `expires=${timeTo};`;
        }

        document.cookie = `${key}=${value};${expires}Path=/;`;
    };
}

export default Cookie;
