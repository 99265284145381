import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import {
    Header24Bold,
    Text12Medium,
    Text16Medium
} from '@/shared/typography/text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  ${Header24Bold};
`;

export const Description = styled.div`
  ${Text16Medium};
`;

export const ErrorStatusDescription = styled.div`
  ${Text12Medium};

  opacity: .2;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const BottomWrapper = styled.div`
  flex-shrink: 0;
`;

export const TextWrapper = styled.div`
  margin-top: ${variables['spacers-m']};
`;
