import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';
import KZPromo from '@/services/kz-promo';

type QiwiKzWalleProps = {
    onCopy: (paycode: string, title: string) => void;
    referenceNumber: string;
    promotion: KZPromo;
}

const QiwiKzWalle: FC<QiwiKzWalleProps> = ({
    referenceNumber,
    onCopy,
    promotion,
}) => {
    const { t } = useTranslation();

    return (
        <PaycodeView
            paycode={referenceNumber}
            title={t('views.PaymentMethods.Cash.QiwiKz.caption')}
            onClick={() => onCopy(referenceNumber, t('views.PaymentMethods.Cash.QiwiKz.numberCopiedLabel'))}
            promotion={promotion}
        >
            <HowToTopUpInfo
                steps={
                    [
                        t('views.PaymentMethods.Cash.QiwiKz.instructionStep1'),
                        t('views.PaymentMethods.Cash.QiwiKz.instructionStep2'),
                        t('views.PaymentMethods.Cash.QiwiKz.instructionStep3'),
                        t('views.PaymentMethods.Cash.QiwiKz.instructionStep4')
                    ]
                }
            />
        </PaycodeView>
    );
};

export default QiwiKzWalle;
