import { gql } from '@apollo/client';

export type TopupWithYunoMutation = {
    input: TopupWithYunoInput,
}

export type TopupWithYunoInput = {
    methodName: string;
    vaultedToken: string;
    oneTimeToken: string;
    paymentMethodCode: string;
    session?: string;
    amount?: number;
    email?: string
}

export type TopupWithYunoResponse = {
    topupWithYuno: {
        sdkActionIsRequired: boolean;
    }
}

export default gql(`
    mutation topupWithYuno ($input: TopupWithYunoInput!){
        topupWithYuno(
            input: $input
        ) {
            sdkActionIsRequired
        }
    }
`);
