import NavigatorService from '@/services/navigator-service';

class KZPromo {
    private navigatorService: NavigatorService;
    private cityId?: number;
    private userId?: string;

    constructor(navigatorService: NavigatorService) {
        this.navigatorService = navigatorService;
    }

    public setUserId = (userId: string) => {
        this.userId = userId;
    };

    public setCityId = (userId: number) => {
        this.cityId = userId;
    };

    public shouldShowPromo = () => {
        const isTimedOut = new Date().getTime() - new Date('2024-10-04').getTime() >= 0;
        if (isTimedOut) {
            return false;
        }

        return this.cityId === 242; // Karaganda
    };

    public getUserId = () => {
        return this.userId || '';
    };

    public showPromo = () => {
        if (!this.userId) {
            return;
        }

        this.navigatorService.goOutside(
            `https://dxy.indrive.com/execute/rh/kazakhstan/ru/kupon?uid=${this.userId}#formSection`
        );
    };
}

export default KZPromo;
