/* eslint-disable i18next/no-literal-string */
import { Component, ChangeEvent } from 'react';

import { copyToClipboard } from '@/lib/forms/clipboard';
import { currentTargetChecked, currentTargetValue } from '@/lib/forms/selectors';
import JWTToken from '@/lib/jwt';
import { Countries } from '@/lib/locale/countries';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';

import Cookie from '../../lib/cookie';
import Global from '../../lib/global';

import * as UI from './ui';

type Suggestion = {
    city_id: number;
    city_name: string;
    country_id: number;
    country_name: string;
    payment_type: string;
    provider: string;
    token: string;
};

type DevPanelProps = {
    authorizationService: AuthorizationService;
    logsService: LogsService;
    confugurationService: ConfigurationService;
};

type DevPanelState = {
    show: boolean;
    shouldReload: boolean;
    value: string;
    suggestions: Suggestion[];
    loading: boolean;
    currentLocation: string;
    hidePanel: boolean;
    isMobDebug: boolean;
    namespace: string;
    namespaceError: boolean;
    isLocalhostEnabled: boolean;
    logs: string[];
    showLogs: boolean;
};

class DevPanel extends Component<DevPanelProps, DevPanelState> {
    constructor(props: DevPanelProps) {
        super(props);

        const localhostPPFromCookie = Cookie.parse(
            document.cookie || '', ConfigurationService.LOCALHOST_PP_ENABLED_COOKIE
        );
        const isDisabled = Global.LocalStorage.getItem('locationDevPanel') === '0';

        this.state = {
            show: !props.confugurationService.isProd() && !isDisabled,
            shouldReload: true,
            value: '',
            suggestions: [],
            loading: false,
            currentLocation: '',
            hidePanel: false,
            isMobDebug: props.confugurationService.isMobileDev(),
            namespace: this.props.confugurationService.getNamespace() || '',
            namespaceError: false,
            isLocalhostEnabled: Boolean(localhostPPFromCookie),
            logs: props.logsService.getLogs(),
            showLogs: false,
        };

        if (this.props.confugurationService.isDev()) {
            this.props.authorizationService.addEventListener('onToken', token => {
                if (token.isExpired()) {
                    this.regenerateToken(token);
                }
            });
        }
    }

    componentDidMount = () => {
        if (this.state.show) {
            this.init();
            this.props.logsService.addEventListener('onLogsUpdate', logs => {
                this.setState({ logs });
            });

            this.props.authorizationService.addEventListener('onToken', (jwt) => {
                const { country_name, city_id } = jwt.getToken();
                this.setState({ currentLocation: `${country_name}/${city_id}` });
            });
        }
    };

    private handleNamespaceChange = (ns: string) => {

        this.setState({
            namespace: ns,
            namespaceError: !/^[a-z]+-[a-z]+-\d{1,3}-\d{4}-\d{1,2}-\d{1,2}$/.test(ns),
        });

        Cookie.write(ConfigurationService.NAMESPACE_NAME_COOKIE, ns);
    };

    private handleClose = () => {
        Global.LocalStorage.setItem('locationDevPanel', '0');
        this.setState({ show: false });
    };

    private handleCopyClick = () => {
        const jwt = this.props.authorizationService.getJWT();
        copyToClipboard(jwt.getJwtValue());
        console.log('Copied!', jwt.getToken());
    };

    private handleSetPPLocalhost = () => {
        this.setState((prevState) => ({ isLocalhostEnabled: !prevState.isLocalhostEnabled }),
            () => {
                Cookie.write(
                    ConfigurationService.LOCALHOST_PP_ENABLED_COOKIE, this.state.isLocalhostEnabled ? '1' : ''
                );
                window.location.reload();
            }
        );
    };

    private saveToken = (token: string) => {
        const jwt = new JWTToken(token);
        this.props.authorizationService.writeJWT(jwt);
        if (this.state.shouldReload) {
            window.open(NavigatorService.AppRoutes.MAIN, '_self');
        }
    };

    private handlePutClick = () => {
        const token = prompt('Put your JWT') || '';
        this.saveToken(token);
    };

    private handleResetClick = () => {
        Cookie.write(ConfigurationService.COOKIE_AUTH_NAME, '');
        if (this.state.shouldReload) {
            window.open(NavigatorService.AppRoutes.MAIN, '_self');
        }
    };

    private handleShowLogs = () => {
        this.setState({ showLogs: !this.state.showLogs });
    };

    private handleMobileDebugging = (checked: boolean) => {
        Global.LocalStorage.setItem(ConfigurationService.MOBILE_DEV_LS_ITEM, checked ? '1' : '0');
        this.setState({ isMobDebug: checked });
        window.location.reload();
    };

    private handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.currentTarget.value.toLowerCase();
        this.setState({ value: inputValue });

        if (inputValue === '') {
            this.setState({ suggestions: [] });

            return;
        }

        const data = await this.request('/suggest', inputValue);
        this.setState({ suggestions: data.foundItems });
    };

    private handleClickSuggestion = (token: string) => {
        this.saveToken(token);
    };

    private request = async (arm: string, body = '') => {
        let host = 'localhost';
        if (this.props.confugurationService.isMobileDev()) {
            host = window.location.hostname;
        }

        const response = await fetch(`http://${host}:4444${arm}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        if (response.ok) {
            try {
                return await response.json();
            } catch (_) {
                /** do nothing */
            }
        }
    };

    private init = () => {
        if (this.state.show && !this.props.confugurationService.isDevPlatform()) {
            this.request('/dev-search', JSON.stringify({
                countries: Countries,
                paymentTypes: PaymentType,
                providers: PaymentProviderName,
            }));
        }
    };

    private handleScan = () => {
        const connection = new WebSocket(ConfigurationService.jwtServer);

        connection.onopen = () => {
            this.setState({ loading: true });

            connection.send(
                JSON.stringify({
                    nsHash: this.props.confugurationService.getNamespaceHash(),
                    isLocalhost: this.state.isLocalhostEnabled,
                })
            );
        };

        connection.onclose = () => {
            this.setState({ loading: false, value: '' });
            this.init();
        };

        connection.onerror = (error) => {
            this.setState({ loading: false });
            console.error(error);
        };
    };
    private generateToken = async (
        countryId: string,
        cityId: string,
        userId: string,
    ) => {
        const result = await this.request('/gen', JSON.stringify({
            nsHash: this.props.confugurationService.getNamespaceHash(),
            countryId,
            cityId,
            userId,
        }));

        this.saveToken(result.token);
    };

    private regenerateToken = (token: JWTToken) => {
        const { country_id, city_id, user_id } = token.getToken();

        this.generateToken(
            country_id.toString(),
            city_id.toString(),
            user_id.toString(),
        );
    };

    private handleGenerate = async () => {
        let defaultCountryId = '';
        let defaultCityId = '';
        let defaultUserId = '';

        try {
            const token = await this.props.authorizationService.getJWT();
            const { city_id, country_id, user_id } = token.getToken();
            defaultCountryId = country_id.toString();
            defaultCityId = city_id.toString();
            defaultUserId = user_id.toString();
        } catch (_) {
            /** do nothing */
        }

        const countryId = prompt('country id', defaultCountryId);
        const cityId = prompt('city id', defaultCityId);
        const userId = prompt('user id', defaultUserId);

        if (!countryId || !cityId || !userId) {
            return;
        }

        this.generateToken(countryId, cityId, userId);
    };

    render() {
        if (!this.state.show) {
            return null;
        }

        return (
            <UI.Wrapper $hide={this.state.hidePanel}>
                {
                    this.state.showLogs ? (
                        <UI.LogWrapper>
                            {this.state.logs.map(log => <UI.Log key={log}>{log}</UI.Log>)}
                        </UI.LogWrapper>
                    ) : null
                }

                <UI.TitleWrapper>
                    <UI.Title onClick={() => this.setState({ hidePanel: true })}>
                        <strong>DevPanel</strong> <span>{this.state.currentLocation}</span>
                    </UI.Title>

                    {
                        !this.props.confugurationService.isDevPlatform() && (
                            <div>
                                <UI.NamespaceInput
                                    type='text'
                                    $error={this.state.namespaceError}
                                    placeholder='NS'
                                    value={this.state.namespace}
                                    onChange={currentTargetValue(this.handleNamespaceChange)}
                                />

                                <UI.Button
                                    $enabled={this.state.isLocalhostEnabled ? 1 : 0}
                                    title='PP on localhost'
                                    onClick={this.handleSetPPLocalhost}
                                >
                                    L
                                </UI.Button>
                            </div>
                        )
                    }
                </UI.TitleWrapper>

                <UI.Line>
                    <UI.Button
                        disabled={this.state.loading}
                        title='Copy JWT'
                        onClick={this.handleCopyClick}
                    >
                        C
                    </UI.Button>

                    <UI.Button
                        disabled={this.state.loading}
                        title='Put JWT'
                        onClick={this.handlePutClick}
                    >
                        P
                    </UI.Button>

                    <UI.Button
                        disabled={this.state.loading}
                        title='Reset JWT'
                        onClick={this.handleResetClick}
                    >
                        R
                    </UI.Button>

                    <UI.Button
                        disabled={this.state.loading}
                        title='Show logs'
                        onClick={this.handleShowLogs}
                    >
                        L
                    </UI.Button>

                    <div>
                        {
                            !this.props.confugurationService.isDevPlatform() && (
                                <UI.DrowndownWrapper>
                                    <UI.Items $hide={this.state.suggestions.length === 0}>
                                        {
                                            this.state.suggestions.map((suggestion) => (
                                                <div
                                                    key={suggestion.city_id}
                                                    onClick={() => this.handleClickSuggestion(suggestion.token)}
                                                >
                                                    {`${suggestion.country_name}/${suggestion.city_name}`}
                                                </div>
                                            ))
                                        }
                                    </UI.Items>

                                    <input
                                        placeholder='Search...'
                                        type='text'
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                    />
                                </UI.DrowndownWrapper>
                            )
                        }
                    </div>

                    {
                        this.props.confugurationService.isDev() && (
                            <>
                                <UI.Button
                                    disabled={this.state.loading}
                                    title='Scan all JWT'
                                    onClick={this.handleScan}
                                >
                                    S
                                </UI.Button>

                                <UI.Button
                                    disabled={this.state.loading}
                                    title='Generate JWT'
                                    onClick={this.handleGenerate}
                                >
                                    G
                                </UI.Button>
                            </>
                        )
                    }

                    <UI.Checkbox
                        disabled={this.state.loading}
                        title='Enable reload page'
                        type='checkbox'
                        checked={this.state.shouldReload}
                        onChange={currentTargetChecked((checked) => this.setState({ shouldReload: checked }))}
                    />

                    <UI.Checkbox
                        disabled={this.state.loading}
                        title='Enable mobile debugging'
                        type='checkbox'
                        checked={this.state.isMobDebug}
                        onChange={currentTargetChecked(this.handleMobileDebugging)}
                    />

                    <UI.Close onClick={this.handleClose}>X</UI.Close>
                </UI.Line>
            </UI.Wrapper>
        );
    }
}

export default DevPanel;
