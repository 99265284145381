import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  $overlay?: boolean;
  $flex?: boolean;
}>`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;

  ${props => props.$flex && css`
    display: flex;
    flex: 1;
  `}
  
  ${props => !props.$overlay && css`
    height: 100%;
  `}
`;

export const Overlay = styled.div<{ $hide: boolean }>`
  ${props => props.$hide && css`
    display: none;
  `}

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div<{
  $showWithOpacity: boolean;
  $flex?: boolean;
}>`
  transition: .3s opacity;
  
  ${props => props.$flex && css`
    display: flex;
    flex: 1;
  `}

  ${props => props.$showWithOpacity && css`
    opacity: .3;
  `}
`;

export const Title = styled.div`
    margin-top: 8px;
`;
