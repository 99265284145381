import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';
import { formatExpDate } from '@/lib/format/time';

type FawryCashProps = {
    referenceNumber: string;
    paymentExpiry: number;
    onCopy: (paycode: string, title: string) => void;
};

const FawryCash: FC<FawryCashProps> = ({
    referenceNumber,
    paymentExpiry,
    onCopy,
}) => {
    const { t } = useTranslation();
    const handleCopy = useCallback(() => {
        onCopy(referenceNumber, t('views.PaymentMethods.Cash.Fawry.referenceCopiedLabel'));
    }, [referenceNumber, onCopy]);

    return (
        <PaycodeView
            title={t('views.PaymentMethods.Cash.Fawry.referenceTitle')}
            paycode={referenceNumber}
            onClick={handleCopy}
        >
            <HowToTopUpInfo
                alertText={
                    t('views.PaymentMethods.Cash.Fawry.howToTopUpDescription', {
                        date: formatExpDate(new Date(paymentExpiry)),
                    })
                }
            />
        </PaycodeView>
    );
};

export default FawryCash;
