import Global from '../../global';

import {
    RegularField,
    REGULAR_FIELDS,
} from './indentificators';

const PREFIX = 'f_v1_';

namespace FieldStorage {
    export const set = (field: RegularField, value: string): void =>
        Global.LocalStorage.setItem(PREFIX + field, value);

    export const clear = (field: RegularField): void =>
        Global.LocalStorage.removeItem(PREFIX + field);

    export const get = (field: RegularField): string =>
        Global.LocalStorage.getItem(PREFIX + field) || '';

    export const isRegularField = (key: string): key is RegularField => {
        return REGULAR_FIELDS.includes(key);
    };
}

export default FieldStorage;
