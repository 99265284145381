import i18n from 'i18next';

import ErrorStatus from './status';

type DisplayedErrorType = {
    title: string;
    messages: string[];
    errorStatus: string;
}

class DisplayedError {
    private data: DisplayedErrorType;

    constructor(error: DisplayedErrorType) {
        this.data = error;
    }

    get title() {
        return this.data.title || i18n.t('lib.errors.defaultTitle');
    }

    get messages() {
        if (this.data.messages.length === 0) {
            this.data.messages.push(i18n.t('lib.errors.defaultMessage'));
        }

        return this.data.messages;
    }

    get errorStatus() {
        return this.data.errorStatus;
    }

    static parse = (query: Record<string, string>): DisplayedError => {
        const { code, message, errorStatus } = query;

        const pureMessage = Array.isArray(message) ? message[0] : message;

        if (!pureMessage) {
            return new DisplayedError({
                title: i18n.t('lib.errors.defaultTitle'),
                messages: [i18n.t('lib.errors.defaultMessage')],
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        }

        return new DisplayedError({
            title: code,
            messages: [decodeURI(pureMessage)],
            errorStatus
        });
    };
}

export default DisplayedError;
