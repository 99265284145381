import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text14Regular, Text16Medium } from '../../../typography/text';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.div`
  ${Text16Medium};

  display: flex;
`;

export const Description = styled.div<{ $highlight?: boolean }>`
  ${Text14Regular};
  
  color: ${props => props.$highlight ? variables['text-and-icon-accent'] : variables['text-and-icon-secondary']}
`;

export const IconWrapper = styled.div`
  margin-inline-end: 16px;
`;
