import { variables } from '@indriver/yrel';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${variables['background-secondary']};
`;

export const TopSection = styled.div`
  background-color: ${variables['background-primary']};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom: 4px;
`;

export const MiddleSection = styled.div`
  background-color: ${variables['background-primary']};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom: 4px;
`;

export const BottomSection = styled.div`
  background-color: ${variables['background-primary']};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1; 
`;
