import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
`;

export const CardIcon = styled.div`
    padding-top: 1px;
    margin-right: 13px;
    display: flex;
    align-items: center;
`;
