import {
    extractOnlyOneError,
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { FormValues } from '@/lib/payments/forms';
import { PaymentProviderName } from '@/lib/payments/providers';
import AnalyticsService from '@/services/analytics-service';
import NavigatorService from '@/services/navigator-service';
import { PaymentMethod } from '@/services/payment-info-service';
import PaymentProviderService from '@/services/payment-provider-service';

class TopupService {
    private analyticService: AnalyticsService;
    private paymentProviderService: PaymentProviderService;
    private navigatorService: NavigatorService;

    constructor(
        paymentProviderService: PaymentProviderService,
        analyticService: AnalyticsService,
        navigatorService: NavigatorService,
    ) {
        this.paymentProviderService = paymentProviderService;
        this.analyticService = analyticService;
        this.navigatorService = navigatorService;
    }

    public async topUp(
        amount: string,
        method: PaymentMethod,
        formFillValues: FormValues,
    ) {
        // This is a smooth migration for formless payments
        // Now it works only for Payrails
        // https://indriver.atlassian.net/browse/PRC-2181
        if (method.providerName === PaymentProviderName.Payrails) {
            this.topupPayrailsGenericMethod(amount, method, formFillValues);

            return;
        }

        return undefined;
    }

    private topupPayrailsGenericMethod = async (
        amount: string,
        method: PaymentMethod,
        formFillValues: FormValues,
    ) => {
        this.analyticService.event('processing.topup_pay.click', {
            payment_option: method.paymentType,
            provider: PaymentProviderName.Payrails,
            sum: parseInt(amount, 10),
        });

        const error = await this.paymentProviderService.process(async (req) => {
            if (!method.session?.payrails || !method.paymentMethodCode) {
                this.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topupPayrailsGenericMethod({
                session: {
                    sessionId: method.session.payrails.session,
                    transactionId: method.session.payrails.paymentId,
                    amount: amount,
                },
                paymentMethodType: method.paymentType,
                paymentMethodCode: method.paymentMethodCode,
                // todo https://indriver.atlassian.net/browse/PRC-1375
                document: formFillValues.document || '',
            });

            if (data?.topupPayrailsGenericMethod.redirectUrl) {
                this.navigatorService.goOutside(data.topupPayrailsGenericMethod.redirectUrl);

                return;
            }

            this.navigatorService.showFinalPage('success');
        });

        if (error) {
            this.navigatorService.showFinalPage('error', {
                message: extractOnlyOneError(error),
            });
        }

        return error;
    };
}

export default TopupService;
