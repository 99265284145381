import { FC, ReactElement, useCallback, useRef, useState } from 'react';

import { PaymentProviderGQLFormError } from '@/lib/errors/payment-provider/graphql-errors';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';

import SectionForm from '../../shared/ui/domain/section-form';
import SubmitForm from '../../shared/ui/domain/submit-form';
import { AppData } from '../../singlepage/app';

import FillForm from '../payment-fill-form';

import * as UI from './ui';

type HidableFormFieldViewProps = {
    title?: string;
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    billingFields: FormSection[];
    checkbox: ReactElement;
    checked: boolean;
    children?: JSX.Element;
    onSubmit: (formFillValues: FormValues) => Promise<PaymentProviderGQLFormError>;
    childrenTitle?: string;
};

const HidableFormFieldView: FC<HidableFormFieldViewProps> = ({
    title,
    country,
    savedDocuments,
    formFields,
    billingFields,
    checkbox,
    checked,
    onSubmit,
    children,
    childrenTitle,
}) => {
    const paymentFillFormRef = useRef<FillForm>(null);
    const paymentBillingFillFormRef = useRef<FillForm>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        if (loading) {
            // double click prevent
            return;
        }

        if (!paymentFillFormRef.current?.validate()) {
            return;
        }
        if (paymentBillingFillFormRef.current && !paymentBillingFillFormRef.current.validate() ) {
            return;
        }

        const paymentFillFormData = paymentFillFormRef.current.getData();
        const paymentBillingFillFormData = paymentBillingFillFormRef.current?.getData();

        setLoading(true);

        const error = await onSubmit({ ...paymentFillFormData, ...paymentBillingFillFormData });

        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }
            if (paymentBillingFillFormRef.current) {
                paymentBillingFillFormRef.current.setError(error);
            }
        }

        setLoading(false);
    }, [onSubmit]);

    let childrenSection = <></>;
    if (children) {
        childrenSection = childrenTitle ? (
            <SectionForm title={childrenTitle}>
                {children}
            </SectionForm>
        ) : children;
    }

    return (
        <SubmitForm
            title={title}
            loading={loading}
            disabled={loading}
            onSubmit={handleSubmit}
        >
            {childrenSection}

            <FillForm
                ref={paymentFillFormRef}
                country={country}
                savedDocuments={savedDocuments}
                fields={formFields}
            />

            <>
                {checkbox ? checkbox : null}
            </>
            <UI.Wrapper>
                {
                    !checked && (
                        <FillForm
                            ref={paymentBillingFillFormRef}
                            country={country}
                            savedDocuments={savedDocuments}
                            fields={billingFields}
                        />
                    )
                }
            </UI.Wrapper>
        </SubmitForm>
    );
};

export default HidableFormFieldView;
