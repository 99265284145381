import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import {
    FormValues,
    getCashStruct
} from '@/lib/payments/forms';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const DlocalCashController: FC<PaymentFormData> = ({ store, services, paymentInfo }) => {
    const { t } = useTranslation();

    const handlePayment = async <T extends DataType['CreateCashDlocalInput']>(input: T) => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Dlocal,
            payment_option: PaymentType.CASH,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.createDlocalCash(input);

            if (data?.createCashDlocal?.redirectUrl === '') {
                await services.navigatorService.showFinalPage('success');
            }

            if (data?.createCashDlocal?.redirectUrl) {
                await services.navigatorService.goOutside(data.createCashDlocal.redirectUrl);
            }
        });
    };

    const handleSubmitPayment = async (
        formFillValues: FormValues,
    ): Promise<PaymentProviderGQLFormError> => {
        if (!store.selectedMethod.paymentMethodCode) {
            services.navigatorService.showFinalPage('error', {
                message: getSessionErrorMessage(),
            });

            return;
        }

        return handlePayment({
            ...formFillValues,
            username: formFillValues.username,
            email: formFillValues.email,
            document: formFillValues.document,
            amount: parseInt(store.amount, 10),
            paymentMethodCode: store.selectedMethod.paymentMethodCode,
        });
    };

    return (
        <FormFieldView
            title={t('views.PaymentMethods.getVoucherSubmitCaption')}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getCashStruct(
                    PaymentProviderName.Dlocal,
                    paymentInfo.country,
                )
            }
            onSubmit={handleSubmitPayment}
        />
    );
};

export default DlocalCashController;
