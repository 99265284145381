import { useTranslation } from 'react-i18next';

import { CurrencyCode } from '@/lib/locale';
import { formatLast4toShortDots } from '@/lib/payments/credit-card/format';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { PaymentMethod } from '@/services/payment-info-service';
import PaymentMethodIcon from '@/shared/ui/icons/payment-method';

import {
    PAYRAILS_TYPE_ALFA,
    PAYRAILS_TYPE_CARD,
    PAYRAILS_TYPE_EASYPAISA,
    PAYRAILS_TYPE_FAWRY,
    PAYRAILS_TYPE_JAZZCASH,
    PAYRAILS_TYPE_KONNECT,
    PAYRAILS_TYPE_WAFA_CASH
} from '../../graphql/payrails';

export type PaymentMethodViewItem = {
    id: string;
    dataMethodTypeId: string;
    name: string;
    show: boolean;
    description: string;
    isRemovable: boolean;
    inFrameShow: boolean;
    originalMethod: PaymentMethod;
    icon: React.ReactElement;
};

export type PaymentMethodAutoTopupItem = {
    name: string;
    originalMethod: PaymentMethod;
    icon: React.ReactElement;
}

class PaymentMethodsViewService {
    private t: ReturnType<typeof useTranslation>['t'];

    constructor(translations: ReturnType<typeof useTranslation>) {
        this.t = translations.t;
    }

    public isInFrameMethod = (
        method: PaymentMethod
    ): boolean => {
        switch (method.providerName) {
            case PaymentProviderName.Puntoxpress:
            case PaymentProviderName.ImePay:
            case PaymentProviderName.Gifty:
            case PaymentProviderName.Kassa24:
            case PaymentProviderName.QiwiKz:
            case PaymentProviderName.KaspiKz:
                return true;
            case PaymentProviderName.Fawry:
                return Boolean(method.session?.fawry);
            case PaymentProviderName.Izi:
                return Boolean(method.session?.izi);
            default:
                return false;
        }
    };

    private getPaymentMethodCaption = (method: PaymentMethod): string => {
        if (method.providerName === PaymentProviderName.Yuno) {
            if (method.methodName === 'Card') {
                return this.t('views.PaymentMethods.creditCardCaption');
            }

            // https://indriver.atlassian.net/browse/PRC-2007
            if (method.paymentType === PaymentType.BANK_CARD && method.methodName === 'Tarjeta de crédito/débito') {
                return this.t('views.PaymentMethods.creditCardCaption');
            }

            if (method.paymentType === PaymentType.BANK_TRANSFER && method.methodName === 'Transferencia bancaria') {
                return this.t('views.PaymentMethods.bankAccountTransferCaption');
            }

            if (method.paymentType || method.isInstrument) {
                return this.formatYunoMethodName(method.methodName);
            }
        }

        if (method.providerName === PaymentProviderName.Dlocal) {
            // TODO https://indriver.atlassian.net/browse/PRC-2208
            if (method.isInstrument) {
                const savedCard = method.savedInstruments?.bankCard;
                if (savedCard?.[0]) {
                    const formatted = formatLast4toShortDots(savedCard[0].last4);

                    return `${this.t('views.PaymentMethods.savedCardCaption')} (${formatted})`;
                }
            }

            if (method.paymentMethodCode && method.paymentType !== PaymentType.BANK_CARD) {
                return method.methodName;
            }
        }

        if (method.providerName === PaymentProviderName.Payrails) {
            if (method.isInstrument) {
                if (method.paymentType === PaymentType.BANK_CARD) {
                    return this.formatPayrailsMethodName(method.methodName);
                }

                return method.methodName;
            }

            if (method.paymentMethodCode) {
                if (method.paymentMethodCode === PAYRAILS_TYPE_FAWRY) {
                    return this.t('views.PaymentMethods.fawryCashCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_EASYPAISA) {
                    return this.t('views.PaymentMethods.easyPaisaCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_JAZZCASH) {
                    return this.t('views.PaymentMethods.jazzCashCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_ALFA) {
                    return this.t('views.PaymentMethods.alfaCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_KONNECT) {
                    return this.t('views.PaymentMethods.konnectCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_WAFA_CASH) {
                    return this.t('views.PaymentMethods.wafaCashCaption');
                }

                if (method.paymentMethodCode === PAYRAILS_TYPE_CARD) {
                    return this.t('views.PaymentMethods.creditCardCaption');
                }
            }

            return method.methodName;
        }

        switch (method.providerName) {
            case PaymentProviderName.KaspiKz:
                return this.t('views.PaymentMethods.kaspiCaption');
            case PaymentProviderName.Kassa24:
                return this.t('views.PaymentMethods.kassa24Caption');
            default:
                switch (method.paymentType) {
                    case PaymentType.BANK_CARD:
                        return this.t('views.PaymentMethods.creditCardCaption');
                    case PaymentType.BANK_TRANSFER:
                        return this.t('views.PaymentMethods.bankAccountTransferCaption');
                    case PaymentType.CASH:
                        return this.t('views.PaymentMethods.cashCaption');
                    case PaymentType.WALLET:
                        return this.t('views.PaymentMethods.walletCaption');
                    case PaymentType.PHONE_BALANCE:
                        return this.t('views.PaymentMethods.phoneBalanceCaption');
                    case PaymentType.TERMINAL:
                        return this.t('views.PaymentMethods.terminalCaption');
                    default:
                }
        }

        return method.methodName;
    };

    public adaptPaymentMethods = (
        methods: PaymentMethod[],
        currency: CurrencyCode,
    ): PaymentMethodViewItem[] => {
        return methods.map((method, index) => {
            const methodName = this.getPaymentMethodCaption(method);

            // for data-ids in e2e test
            let dataMethodTypeId: string = method.paymentType;
            if (method.isInstrument) {
                dataMethodTypeId = `SAVED_${dataMethodTypeId}`;
            }

            return {
                id: method.paymentType + method.paymentMethodCode + methodName + index,
                dataMethodTypeId,
                show: method.available,
                name: methodName,
                description: `${currency} ${method.limits.min}–${method.limits.max}`,
                isRemovable: method.isRemovable,
                inFrameShow: this.isInFrameMethod(method),
                originalMethod: method,
                icon: <PaymentMethodIcon method={method} />
            };
        });
    };

    public getAutoTopupMethods = (
        methods: PaymentMethod[],
    ): PaymentMethodAutoTopupItem[] => {
        return methods.map((method) => {
            const methodName = this.getPaymentMethodCaption(method);

            return {
                name: methodName,
                originalMethod: method,
                icon: <PaymentMethodIcon method={method} />
            };
        });
    };

    private formatYunoMethodName = (value: string) => {
        if (/\*\*\*\*/g.test(value)) {
            const [cardType, last4] = value.split(' ****');

            return `${cardType} ${formatLast4toShortDots(last4)}`;
        }

        return value;
    };

    public formatPayrailsMethodName = (value: string) => {
        if (/\*\*\*\*/g.test(value)) {
            const [cardType, last4] = value.split(' ****');

            return `${cardType} ${formatLast4toShortDots(last4)}`;
        }

        return value;
    };
}

export default PaymentMethodsViewService;
