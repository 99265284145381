import { gql } from '@apollo/client';

export type TopupWithCredoraxCardMutation = {
    input: TopupWithCredoraxCardInput,
}

export type TopupWithCredoraxCardInput = {
    amount: number;
    pKeyResponse: string;
    email: string;
    zipCode: string;
    city: string;
    street: string;
    houseNumber: string;
    firstName: string;
    lastName: string;
}

export type TopupWithCredoraxCardResponse = {
    result: boolean;
}

export default gql(`
    mutation topupWithCredoraxCard ($input: TopupWithCredoraxCardInput!){
        topupWithCredoraxCard(
            input: $input
        ) {
            result
        }
    }
`);
