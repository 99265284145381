import { gql } from '@apollo/client';

export type TopupWithSlickMutation = {
    input: TopupWithSlickInput,
}

export type TopupWithSlickInput = {
    amount: number;
}

export type TopupWithSlickResponse = {
    topupWithSlick: {
        redirectURL: string;
    }
}

export default gql(`
    mutation topupWithSlick ($input: TopupWithSlickInput!){
        topupWithSlick(
            input: $input
        ) {
            redirectURL
        }
    }
`);
