import { BILLING_ADDRESS_SECTION_ID, DOCUMENT_ID } from './indentificators';
import { FormId } from './index';

export enum HintType {
    BILLING_ADDRESS,
    DOCUMENT,
}

export const getHintType = (id: FormId): HintType|undefined => {
    switch (id) {
        case BILLING_ADDRESS_SECTION_ID:
            return HintType.BILLING_ADDRESS;
        case DOCUMENT_ID:
            return HintType.DOCUMENT;
        default:
            return undefined;
    }
};

export const hasHint = (id: FormId) => getHintType(id) !== undefined;
