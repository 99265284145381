import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Input } from '../ui';

export const Wrapper = styled.div`
  display: flex;
`;

export const MonthInput = styled(Input)`
  padding: 0;
  width: 25px;
`;
export const YearInput = styled(Input)`
  margin-left: 5px;
`;

export const Divider = styled.div`
  &:after {
    content: '/';
    display: flex;
    align-items: center;
    height: 100%;
    color: ${variables['text-and-icon-primary']};
  }
`;
