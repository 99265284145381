import { ApolloError } from '@apollo/client';

import PaymentProviderI18N, { PaymentProviderI18NError } from '../../../i18n/payment-provider';

// This list should be deleted with the legacy gql flow
/** @deprecated */
export const PaymentProviderGQLErrorType = {
    INTERNAL_SERVER_ERROR_CODE: 'INTERNAL_SERVER_ERROR_CODE',
    PROVIDER_ERROR_CODE: 'PROVIDER_ERROR_CODE',
    VALIDATION_ERROR_CODE: 'VALIDATION_ERROR_CODE',
    VERIFICATION_CHECK_ERROR_CODE: 'VERIFICATION_CHECK_ERROR_CODE',
};

/** @deprecated */
export type PaymentProviderGQLFormError = {
    type: 'VALIDATION_ERROR_CODE',
    errors: { field: string; message: string; }[]
} | {
    type: 'INTERNAL_SERVER_ERROR_CODE',
    message: string,
} | undefined;

/** @deprecated */
export const extractOnlyOneError = (error: PaymentProviderGQLFormError): string => {
    if (error?.type === 'VALIDATION_ERROR_CODE') {
        return error.errors[0]?.message || '';
    } else if (error?.type === 'INTERNAL_SERVER_ERROR_CODE') {
        return error.message;
    }

    return '';
};

export const extractValidationErrorByField = (error: PaymentProviderGQLFormError, field: string): string | null => {
    if(error?.type === 'VALIDATION_ERROR_CODE') {
        return error.errors.find((e) => e.field === field)?.message || null;
    }

    return null;
};

/** @deprecated */
export const parsePaymentProviderError = (error: ApolloError): PaymentProviderGQLFormError => {
    const ppI18N = new PaymentProviderI18N();
    const errorData = error.graphQLErrors[0];
    if (!errorData) {
        return undefined;
    }

    const errorCode = errorData.extensions.errorCode;
    // typecast because ApolloError have no generic args
    const ppi18nErrors: PaymentProviderI18NError[]  = errorData.extensions?.errors as [] || [];

    if (
        errorCode == PaymentProviderGQLErrorType.INTERNAL_SERVER_ERROR_CODE ||
        errorCode == PaymentProviderGQLErrorType.PROVIDER_ERROR_CODE ||
        errorCode == PaymentProviderGQLErrorType.VERIFICATION_CHECK_ERROR_CODE
    ) {
        return {
            type: 'INTERNAL_SERVER_ERROR_CODE',
            message: ppI18N.getErrorText(
                ppi18nErrors[0], 'paymentProvider.providerErrorCodeText'
            ),
        };
    }

    if (errorCode !== PaymentProviderGQLErrorType.VALIDATION_ERROR_CODE) {
        return undefined;
    }

    try {
        return {
            type: 'VALIDATION_ERROR_CODE',
            errors: ppi18nErrors.map((i18nError: PaymentProviderI18NError) => {
                return {
                    field: i18nError.i18nArgs.field,
                    message: ppI18N.getErrorText(
                        i18nError, 'services.paymentProvider.errorMessage'
                    )
                };
            }),
        };
    } catch (_) { /** do nothing */ }

    return undefined;
};
