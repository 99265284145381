import { FC, useCallback, useEffect, useState } from 'react';

import { copyToClipboard } from '@/lib/forms/clipboard';
import { Countries } from '@/lib/locale/countries';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import KZPromo from '@/services/kz-promo';
import { PaymentMethod } from '@/services/payment-info-service';

import FawryCash from './fawry-cash';
import GiftyCash from './gifty-cash';
import ImePayWallet from './ime-pay-wallet';
import IziCash from './izi-cash';
import KaspiBankTransfer from './kaspi-bank-transfer';
import Kassa24Terminal from './kassa24-terminal';
import PuntoxpressCash from './puntoxpress-cash';
import QiwiKzWallet from './qiwi-kz-wallet';
import QiwiKzTerminal from './qiwi-terminal';

import { AppData } from '../../../index';

type InFramePaymentFormProps = {
    country: Countries;
    method: PaymentMethod;
    services: AppData['services'];
}

const InFramePaymentForm: FC<InFramePaymentFormProps> = ({
    country,
    method,
    services
}) => {
    const [userId, setUserId] = useState<string>();
    const [promotion] = useState(new KZPromo(services.navigatorService));

    useEffect(() => {
        const token = services.authorizationService.getJWT().getToken();

        if (!token) {
            return;
        }
        setUserId(token.user_id.toString());

        promotion.setUserId(token.user_id.toString());
        promotion.setCityId(token.city_id);
    }, [promotion]);

    const handleCopyPaycode = useCallback((paycode: string, title: string) => {
        copyToClipboard(paycode);
        services.notificationService.success(title);
    }, []);

    const handleRedirect = useCallback((redirectUrl: string) => {
        services.navigatorService.goOutside(redirectUrl);
    }, []);

    if (method.providerName === PaymentProviderName.ImePay) {
        const referenceNumber = method.session?.imepay?.referenceNumber;
        const redirectUrl = method.session?.imepay?.redirectUrl;
        const tempRef = referenceNumber ?? userId;

        if (!tempRef) {
            return null;
        }

        return (
            <ImePayWallet
                redirectUrl={redirectUrl}
                referenceNumber={tempRef}
                onCopy={handleCopyPaycode}
                onRedirect={handleRedirect}
            />
        );
    }

    if (method.providerName === PaymentProviderName.Puntoxpress) {
        const referenceNumber = method.session?.puntoxpress?.referenceNumber;
        const tempRef = referenceNumber ?? userId;

        if (!tempRef) {
            return null;
        }

        return (
            <PuntoxpressCash
                referenceNumber={tempRef}
                countryId={country}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.providerName === PaymentProviderName.Gifty) {
        const referenceNumber = method.session?.gifty?.referenceNumber;
        const tempRef = referenceNumber ?? userId;

        if (!tempRef) {
            return null;
        }

        return (
            <GiftyCash
                referenceNumber={tempRef}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.providerName === PaymentProviderName.Kassa24) {
        const referenceNumber = method.session?.kassa24?.referenceNumber;
        const tempRef = referenceNumber ?? userId;

        if (!tempRef) {
            return null;
        }

        return (
            <Kassa24Terminal
                referenceNumber={tempRef}
                onCopy={handleCopyPaycode}
                promotion={promotion}
            />
        );
    }

    if (method.providerName === PaymentProviderName.QiwiKz) {
        const referenceNumber = method.session?.qiwi_kz?.referenceNumber;
        const tempRef = referenceNumber ?? userId;
        if (!tempRef) {
            return null;
        }
        if (method.paymentType === PaymentType.TERMINAL) {
            return (
                <QiwiKzTerminal
                    referenceNumber={tempRef}
                    onCopy={handleCopyPaycode}
                    promotion={promotion}
                />
            );
        }

        if (method.paymentType === PaymentType.WALLET) {
            return (
                <QiwiKzWallet
                    referenceNumber={tempRef}
                    onCopy={handleCopyPaycode}
                    promotion={promotion}
                />
            );
        }
    }

    if (method.providerName === PaymentProviderName.KaspiKz) {
        const referenceNumber = method.session?.kaspi?.referenceNumber;
        const redirectUrl = method.session?.kaspi?.redirectUrl;
        const tempRef = referenceNumber ?? userId;

        if (!tempRef || !redirectUrl) {
            return null;
        }

        return (
            <KaspiBankTransfer
                referenceNumber={tempRef}
                redirectUrl={redirectUrl}
                onRedirect={handleRedirect}
                onCopy={handleCopyPaycode}
                promotion={promotion}
            />
        );
    }

    if (method.providerName === PaymentProviderName.Fawry) {
        const referenceNumber = method.session?.fawry?.referenceNumber;
        const paymentExpiry = method.session?.fawry?.paymentExpiry;

        if (!paymentExpiry || !referenceNumber) {
            return null;
        }

        return (
            <FawryCash
                referenceNumber={referenceNumber}
                paymentExpiry={paymentExpiry}
                onCopy={handleCopyPaycode}
            />
        );
    }

    if (method.providerName === PaymentProviderName.Izi) {
        const voucherReference = method.session?.izi?.voucherReference;

        if (!voucherReference) {
            return null;
        }

        return (
            <IziCash
                voucherReference={voucherReference}
                onCopy={handleCopyPaycode}
            />
        );
    }

    return null;
};

export default InFramePaymentForm;
