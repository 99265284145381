import {
    BottomSheet,
    BottomSheetBody,
    BottomSheetFooter,
    BottomSheetHeader
} from '@indriver/nova';
import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import * as UI from './ui';

import Resources from '../../../../resources';

type ElementType =
    | React.ReactNode
    | React.ReactNode[]
    | JSX.Element
    | JSX.Element[]
    | string
    | undefined;

type ModalSheetProps = {
    open: boolean;
    title?: string;
    header?: ElementType;
    body?: ElementType;
    footer?: ElementType;
    onClose?: VoidFunction;
}

const PORTAL_CONTAINER_CLASSNAME = 'portal-container-modal-sheet';

const ModalSheet: FC<ModalSheetProps> = ({
    open,
    title,
    header,
    body,
    footer,
    onClose
}) => {
    const [container, setContainer] = useState<HTMLDivElement|null>(null);

    useEffect(() => {
        const mainElement = document.querySelector<HTMLBodyElement>('main');

        if (mainElement && open) {
            const portalContainer = document.createElement('div');
            portalContainer.id = PORTAL_CONTAINER_CLASSNAME;
            mainElement.appendChild(portalContainer);
            setContainer(portalContainer);
        }

        return () => {
            const bodyElement = document.querySelector<HTMLBodyElement>('main');

            if (bodyElement) {
                const portalContainer = document.getElementById(PORTAL_CONTAINER_CLASSNAME);
                if (portalContainer) {
                    bodyElement.removeChild(portalContainer);
                    setContainer(null);
                }
            }
        };
    }, [open]);

    if (!open || !container) {
        return null;
    }

    return createPortal(
        <>
            <div id='modal-root' />

            <BottomSheet
                opened={open}
                onClose={onClose}
                rootSelector='#modal-root'
            >
                {
                    header ||
                    <UI.HeaderFont data-id={Resources.test.titleModal}>
                        <BottomSheetHeader title={title} />
                    </UI.HeaderFont>
                }

                <BottomSheetBody>{body}</BottomSheetBody>
                <BottomSheetFooter>{footer}</BottomSheetFooter>
            </BottomSheet>
        </>,
        container,
    );
};

export default ModalSheet;
