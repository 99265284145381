import {
    CardType,
    REGEXP_DINERSCLUB,
    REGEXP_AMEX,
    REGEXP_JCB,
    REGEXP_DISCOVER,
    REGEXP_MAESTRO,
    REGEXP_VISA,
    REGEXP_MASTERCARD,
    REGEXP_MIR
} from './index';

export const getCardType = (cardNumber: string): CardType | null => {
    const value = cardNumber.replace(/\D/g, '');

    if (value.match(REGEXP_AMEX)) {
        return CardType.AMEX;
    }

    if (value.match(REGEXP_DINERSCLUB)) {
        return CardType.DINERSCLUB;
    }

    if (value.match(REGEXP_DISCOVER)) {
        return CardType.DISCOVER;
    }

    if (value.match(REGEXP_JCB)) {
        return CardType.JCB;
    }

    if (value.match(REGEXP_MAESTRO)) {
        return CardType.MAESTRO;
    }

    if (value.match(REGEXP_MASTERCARD)) {
        return CardType.MASTERCARD;
    }

    if (value.match(REGEXP_MIR)) {
        return CardType.MIR;
    }

    if (value.match(REGEXP_VISA)) {
        return CardType.VISA;
    }

    return null;
};
