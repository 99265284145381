import { Countries } from '@/lib/locale/countries';

import {
    getCardholderField,
    getCityField,
    getEmailField,
    getHouseField,
    getStreetField,
    getZipField
} from '../fields';
import { FormSection } from '../index';
import {
    getBillingAddressSection,
    getPersonalDetailsSection
} from '../sections';

import Validation from '../../../forms/validation';

export const getCredoraxExistedCardStruct = (
    countryId: Countries
): FormSection[] => {
    switch (countryId) {
        default:
            return [
                getPersonalDetailsSection([
                    getCardholderField(),
                ]),
            ];
    }
};

export const getCredoraxCreditCardStruct = (
    countryId: Countries
): FormSection[] => {
    switch (countryId) {
        default:
            return [
                getBillingAddressSection([
                    getCityField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(3, 30),
                        ]
                    }),
                    getStreetField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(4, 50),
                        ]
                    }),
                    getHouseField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 16),
                        ]
                    }),
                    getZipField({
                        validators: [
                            Validation.required,
                            Validation.stringSizeValidator(1, 9),
                            Validation.zipCode,
                        ]
                    }),
                    getEmailField(),
                ]),
            ];
    }
};
