import { FC } from 'react';

export type AutoTopupIconProps = {
    size?: number;
    className?: string;
}

const AutoTopupIcon: FC<AutoTopupIconProps> = ({ size = 24, className }) => {
    return (
        <svg
            width={size}
            height={size}
            className={className}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                className='fill'
                fillRule='evenodd'
                clipRule='evenodd'
                d={
                    'M20 12C20 16.4183 16.4183 20 12 20C9.8193 20 7.84407 19.1288 6.40005 17.7132C5.95217 17.2741 ' +
                '5.55587 16.7831 5.22088 16.25L6.49999 16.25C7.05227 16.25 7.49999 15.8023 7.49999 15.25C7.49999 ' +
                '14.6977 7.05227 14.25 6.49999 14.25L3.00003 14.25C2.44775 14.25 2.00003 14.6977 2.00003 ' +
                '15.25L2.00002 19.5C2.00002 20.0523 2.44773 20.5 3.00002 20.5C3.5523 20.5 4.00002 20.0523 4.00002' +
                ' 19.5L4.00002 18.0009C4.30444 18.4062 4.63887 18.7874 4.99996 19.1414C6.80268 20.9086 9.27489 22' +
                ' 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.85205 2 2.61382 5.88912 2.06098 ' +
                '10.8901C2.0003 11.4391 2.39611 11.9333 2.94505 11.9939C3.49399 12.0546 3.98819 11.6588 4.04887 ' +
                '11.1099C4.49093 7.11098 7.88285 4 12 4C16.4183 4 20 7.58172 20 12Z'
                }
                fill='#323942'
            />

            <path
                className='stroke'
                d='M12 8.5V15.5'
                stroke='#323942'
                strokeWidth='1.8'
                strokeLinecap='round'
                strokeLinejoin='round'
            />

            <path
                className='stroke'
                d='M8.5 12L15.5 12'
                stroke='#323942'
                strokeWidth='1.8'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default AutoTopupIcon;
