import { gql } from '@apollo/client';

export type TopupWithOneVisionMutation = {
    input: TopupWithOneVisionInput,
}

export type TopupWithOneVisionInput = {
    paymentMethodCode: string;
    amount: number;
    phone: string;
}

export type TopupWithOneVisionResponse = {
    topupWithOneVision: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation topupWithOneVision($input: TopupWithOneVisionInput!) {
        topupWithOneVision(input: $input) {
            redirectUrl
        }
    }
`);
