export const capitalizeFirstLetter = (value: string): string => {
    value = value.toLowerCase();

    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const withZero = (t: number | string): string =>
    t.toString().length === 1 ? `0${t}` : t.toString();

export const replaceAsterisks = (value: string): string =>
    value.replace(/\*/g, '•');

export const replaceIndriverEmail = (value:string): string =>{
    const pattern = /^\d+@(indriver|indrive)\.com$/;

    return pattern.test(value) ? '' : value;
};
