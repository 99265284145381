import EventEmitter from '@/lib/subscriber';

class LogsService extends EventEmitter<{ 'onLogsUpdate': string[] }>{
    private messages: string[];

    constructor() {
        super();

        this.messages = [];
    }
    public write = (msg: string): void => {
        const message = `${msg}:${performance.now().toFixed()}`;
        this.messages.push(message);
        this.dispatch('onLogsUpdate', this.messages);

        console.info(message);
    };

    public getLogs = (): string[] => {
        return this.messages;
    };

    public writeInitialLogLine = () => {
        this.write([
            'payment-provider-webview-log',
            `ua_${window.navigator.userAgent}`,
            `androidcheck_${window.Android?.postMessage ? 1 : 0}`,
            `ioscheck_${window.webkit?.messageHandlers?.inDriveriOSHandler ? 1 : 0}`,
            `${navigator?.languages?.toString()}`
        ].join(''));
    };
}

export default LogsService;
