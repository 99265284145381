import React, { FC } from 'react';

export type CheckboxSign = {
    className?: string;
    style?: React.CSSProperties;
    indeterminate?: boolean;
}

const CheckboxSign: FC<CheckboxSign> = (props) => {
    const { indeterminate, ...attrs } = props;

    return indeterminate ? (
        <svg
            {...attrs}
            viewBox='0 0 14 4'
            xmlns='http://www.w3.org/2000/svg'
        >
            {/* eslint-disable-next-line max-len */}
            <path d='M12 3.75C12.9665 3.75 13.75 2.9665 13.75 2C13.75 1.0335 12.9665 0.25 12 0.25V3.75ZM2 0.25C1.0335 0.25 0.25 1.0335 0.25 2C0.25 2.9665 1.0335 3.75 2 3.75V0.25ZM12 0.25H6.04731V3.75H12V0.25ZM6.04731 0.25H2V3.75H6.04731V0.25Z' />
        </svg>
    ) : (
        <svg
            {...attrs}
            viewBox='0 0 12 10'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                // eslint-disable-next-line max-len
                d='M11.6633 0.279661C12.0766 0.68672 12.1147 1.38896 11.7483 1.84817L5.54319 9.62594C5.3649 9.84941 5.11316 9.98373 4.84472 9.99862C4.57629 10.0135 4.31376 9.9077 4.11633 9.70507L0.321463 5.81012C-0.0842237 5.39374 -0.109305 4.69077 0.265442 4.24001C0.64019 3.78925 1.27286 3.76138 1.67854 4.17776L4.72364 7.30316L10.2517 0.374076C10.618 -0.0851274 11.25 -0.127398 11.6633 0.279661Z'
            />
        </svg>
    );
};

export default CheckboxSign;