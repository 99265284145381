import styled, { css } from 'styled-components';

import Resources from '../../../../resources';
import { Text16Medium } from '../../../typography/text';

export const Input = styled.input`
  ${Text16Medium};

  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
`;

export const WithIcon = styled.div<{ $hidable?: boolean }>`
  @media (max-width: 360px) {
    padding-inline-end: 10px;

    ${props => props.$hidable && css`
      padding-inline-end: initial;
    `}
  }
  
  padding-inline-end: 40px;
`;

export const Icon = styled.div<{ $hidable?: boolean }>`
  position: absolute;

  ${() => Resources.getDir() === 'rtl' ? css`left: 0;` : css`right: 0;` }
  top: 0;
  padding: 0 20px;

  @media (max-width: 360px) {
    padding: 0 8px;

    ${props => props.$hidable && css`
      display: none;
    `}
  }

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
