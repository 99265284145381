import { replaceIndriverEmail } from '@/lib/format/text';
import { Countries } from '@/lib/locale/countries';

import {
    getCityField,
    getDocumentField,
    getEmailField,
    getHouseField,
    getStateField,
    getStreetField,
    getZipField,
} from '../fields';
import { ExtraPayload, FormSection } from '../index';
import {
    getBillingAddressSection,
    getPersonalDetailsSection,
} from '../sections';

export const getDlocalExistedCardStruct = (
    countryId: Countries,
): FormSection[] => {
    switch (countryId) {
        case Countries.SouthAfrica:
            return [getPersonalDetailsSection([
                getEmailField({ formatter: replaceIndriverEmail })
            ])];
        default:
            return [getPersonalDetailsSection([
                getDocumentField(countryId),
                getEmailField({ formatter: replaceIndriverEmail })
            ])];
    }
};



export const getDlocalCreditCardStruct = (
    countryId: Countries,
    extra: ExtraPayload,
): FormSection[] => {

    switch (countryId) {
        case Countries.India:
            return [
                getPersonalDetailsSection([
                    getEmailField({ formatter: replaceIndriverEmail }),
                    getDocumentField(countryId),
                ]),
                getBillingAddressSection([
                    getStateField(),
                    getCityField(),
                    getZipField(),
                    getStreetField(),
                    getHouseField(),
                ]),
            ];
        case Countries.SouthAfrica:
            if (extra?.short) {
                return [
                    getPersonalDetailsSection([
                        getEmailField({ formatter: replaceIndriverEmail }),
                    ]),
                ];
            }

            return [
                getPersonalDetailsSection([
                    getEmailField({ formatter: replaceIndriverEmail }),
                    getStateField(),
                    getCityField(),
                    getStreetField(),
                    getHouseField(),
                    getZipField(),
                ]),
            ];
        case Countries.Morocco:
            return [getPersonalDetailsSection([
                getEmailField({ formatter: replaceIndriverEmail }),
            ])];
        default:
            return [
                getPersonalDetailsSection([
                    getEmailField({ formatter: replaceIndriverEmail }),
                    getDocumentField(countryId),
                ]),
            ];
    }
};
