import { Countries } from '@/lib/locale/countries';

import {
    getCityField,
    getDocumentField,
    getEmailField,
    getHouseField,
    getStateField,
    getStreetField,
    getUsernameField,
    getZipField
} from '../fields';
import { ExtraPayload, FormSection } from '../index';
import { getPersonalDetailsSection } from '../sections';

export const getDlocalBankTransferStruct = (
    countryId: Countries,
    extra?: ExtraPayload
): FormSection[] => {
    switch (countryId) {
        case Countries.SouthAfrica:
            if (extra?.short) {
                return [
                    getPersonalDetailsSection([
                        getUsernameField(),
                        getEmailField(),
                    ]),
                ];
            }

            return [
                getPersonalDetailsSection([
                    getUsernameField(),
                    getStateField(),
                    getCityField(),
                    getStreetField(),
                    getHouseField(),
                    getZipField(),
                    getEmailField(),
                ]),
            ];
        default:
            return [
                getPersonalDetailsSection([
                    getUsernameField(),
                    getDocumentField(countryId),
                    getEmailField(),
                ]),
            ];
    }
};
