type DlocalErrorDetails = {
    error: {
        message: string;
        param: 'number' | 'expiration' | 'cvv' | 'name';
    };
}

export class DlocalError {
    private message: string;
    private param: 'number'|'expiration'|'cvv'|'name';

    constructor(e: DlocalErrorDetails) {
        this.message = e.error.message;
        this.param = e.error.param;
    }

    static isDlocalErrorDetails(e: any): e is DlocalErrorDetails {
        return e?.error && typeof e.error.message === 'string' && typeof e.error.param === 'string';
    }

    public isCardNumberError() {
        // I don't know why dlocal makes "number" instead of "pan" here
        return this.param === 'number';
    }

    public isExpError() {
        return this.param === 'expiration';
    }

    public isCvvError() {
        return this.param === 'cvv';
    }

    public isCardHolderError() {
        return this.param === 'name';
    }

    public getMessage() {
        return this.message;
    }
}
