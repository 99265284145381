import { ChevronRightSmall } from '@indriver/mireska';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Price from '@/lib/format/price';
import { CurrencyCode } from '@/lib/locale';
import { AutoTopupInfo } from '@/services/payment-info-service';

import Resources from '../../resources';

import * as UI from './ui';

export type AutoTopupButtonProps = {
    currencyCode: CurrencyCode;
    autoTopupInfo: AutoTopupInfo;
    onClick?: VoidFunction;
}

const AutoTopupButton: FC<AutoTopupButtonProps> = ({
    currencyCode,
    autoTopupInfo,
    onClick,
}) => {
    const { t } = useTranslation();

    if (autoTopupInfo.visible === false || autoTopupInfo.enable === false) {
        return null;
    }

    let description = (
        <>
            {t('features.AutoTopupButton.isInactiveLabel')}
        </>
    );

    if (autoTopupInfo.notSettedUp) {
        description = (
            <>
                {t('features.AutoTopupButton.notSettedUp')}
            </>
        );
    }

    if (autoTopupInfo.userSettings.enabled) {
        const amount = new Price(
            autoTopupInfo.userSettings.amount,
            currencyCode,
        );

        description = (
            <>
                <UI.ActiveLabel>{t('features.AutoTopupButton.isActiveLabel')}</UI.ActiveLabel>
                {' • '}
                <span>{amount.format()}</span>
            </>
        );
    }

    return (
        <UI.Wrapper
            onClick={onClick}
            data-id={Resources.test.autoTopup.autoTopup}
        >
            <UI.InfoWrapper>
                <UI.IconWrapperOnTop>
                    <UI.AutoTopupIcon />
                </UI.IconWrapperOnTop>

                <UI.Info>
                    <UI.Title>{t('features.AutoTopupButton.title')}</UI.Title>

                    <UI.Description data-id={Resources.test.autoTopup.autoTopupStatus}>
                        {description}
                    </UI.Description>
                </UI.Info>
            </UI.InfoWrapper>

            <UI.IconWrapper>
                <ChevronRightSmall size={24} />
            </UI.IconWrapper>
        </UI.Wrapper>
    );
};

export default AutoTopupButton;
