import {
    FC,
    useState,
    useCallback,
    useRef,
    forwardRef,
    useImperativeHandle,
    Ref,
} from 'react';

import { focusOn, CreditCardForm } from '@/lib/forms/actions';
import Validation, { ValidationError } from '@/lib/forms/validation';

import CardHolder from '../../../shared/ui/domain/credit-card-field/card-holder';
import CardNumber from '../../../shared/ui/domain/credit-card-field/card-number';
import CVV from '../../../shared/ui/domain/credit-card-field/cvv';
import CVVHint from '../../../shared/ui/domain/credit-card-field/cvv-hint';
import ExpirationDate from '../../../shared/ui/domain/credit-card-field/expiration-date';
import CreditCardOutline, { CreditCardViewType } from '../../../shared/ui/domain/credit-card-outline';

export type CardData = {
    cardNumber: string;
    expirationMonth: string;
    expirationYear: string;
    cvv: string;
    cardHolder: string;
}

type CreditCardNativeProps = {
    ref: Ref<CreditCardForm<CardData>>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const CreditCardNative: FC<CreditCardNativeProps> = forwardRef((_, ref) => {
    const [cardNumber, setCardNumber] = useState('');
    const [numberError, setNumberError] = useState<ValidationError>();
    const cardNumberRef = useRef<CardNumber>(null);

    const [expirationError, setExpirationError] = useState<ValidationError>();
    const [expirationMonth, setExpirationMonth] = useState('');
    const [expirationYear, setExpirationYear] = useState('');
    const expirationDateRef = useRef<ExpirationDate>(null);

    const [cvvError, setCvvError] = useState<ValidationError>();
    const [cvv, setCvv] = useState('');
    const cvvRef = useRef<CVV>(null);

    const [cardHolder, setCardHolder] = useState('');
    const [cardHolderError, setCardHolderError] = useState<ValidationError>();
    const cardholderRef = useRef<CardHolder>(null);

    useImperativeHandle(ref, () => ({
        validate: (): boolean => {
            const numberErr = cardNumberRef.current?.validate();
            const expErr = expirationDateRef.current?.validate();
            const cvvErr = cvvRef.current?.validate();
            const cardholderErr = cardholderRef.current?.validate();

            return Boolean(numberErr && expErr && cvvErr && cardholderErr);
        },
        setCardholderError: (error) => {
            if (error?.message) {
                setCardHolderError(error);
            }
        },
        getData: async () => ({
            cardNumber: cardNumber.trim(),
            expirationMonth: expirationMonth.trim(),
            expirationYear: expirationYear.trim(),
            cvv: cvv.trim(),
            cardHolder: cardHolder.trim(),
        })
    }), [
        cardNumber,
        expirationMonth,
        expirationYear,
        cvv,
        cardHolder,
        cardNumberRef,
        expirationDateRef,
        cvvRef,
        cardholderRef
    ]);

    const [showHint, setShowHint] = useState(false);
    const toggleHint = useCallback(() => setShowHint((p) => !p), []);

    const onExpirationDateChange = useCallback((month: string, year: string) => {
        setExpirationMonth(month);
        setExpirationYear(year);
    }, []);

    return (
        <>
            <CreditCardOutline
                cardNumberInput={
                    <CardNumber
                        ref={cardNumberRef}
                        onError={setNumberError}
                        onChange={setCardNumber}
                        onCompleted={focusOn(expirationDateRef)}
                    />
                }
                cardNumberError={numberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.COMMON,
                        children: (
                            <ExpirationDate
                                ref={expirationDateRef}
                                onError={setExpirationError}
                                onChange={onExpirationDateChange}
                                onCompleted={focusOn(cvvRef)}
                            />
                        ),
                        expirationError: expirationError?.message
                    }
                }
                cvvInput={
                    <CVV
                        ref={cvvRef}
                        cardNumber={cardNumber}
                        onError={setCvvError}
                        onChange={setCvv}
                        onCompleted={focusOn(cardholderRef)}
                        onIconClick={toggleHint}
                    />
                }
                cvvError={cvvError?.message}
                cardHolderInput={
                    <CardHolder
                        ref={cardholderRef}
                        validators={[Validation.firstAndLastnameCardholder]}
                        onError={setCardHolderError}
                        onChange={setCardHolder}
                    />
                }
                cardHolderError={cardHolderError?.message}
            />

            <CVVHint
                open={showHint}
                onClose={toggleHint}
            />
        </>
    );
});

export default CreditCardNative;
