import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Resources from '../../resources';
import SubmitForm from '../../shared/ui/domain/submit-form';

import * as UI from './ui';

type HowToTopUpInfoProps = {
    loading?: boolean;
    steps?: string[];
    alertText?: string;
    providerSupportTitle?: string;
    submitCaption?: string;
    onSubmit?: VoidFunction;
}

const HowToTopUpInfo: FC<HowToTopUpInfoProps> = ({
    loading,
    steps,
    alertText,
    submitCaption = '',
    onSubmit,
}) => {
    const { t } = useTranslation();

    let howToTopUpContent = (
        <>
            {
                alertText ? (
                    <UI.AlertText>
                        {alertText}
                    </UI.AlertText>
                ) : null
            }

            {
                steps && steps.length > 0 ? (
                    <>
                        <UI.Title data-id={Resources.test.howToTopUpForm.title}>
                            {t('features.HowToTopUpInfo.title')}
                        </UI.Title>

                        <UI.List>
                            <ul>
                                {
                                    steps.map((step, index) => (
                                        <ol key={index}>{index + 1}. {step}</ol>
                                    ))
                                }
                            </ul>
                        </UI.List>
                    </>
                ) : null
            }
        </>
    );

    if (onSubmit) {
        howToTopUpContent = (
            <SubmitForm
                title={submitCaption}
                loading={loading || false}
                onSubmit={onSubmit}
            >
                {howToTopUpContent}
            </SubmitForm>
        );
    }

    return (
        <>
            <UI.InfoBlock>
                {howToTopUpContent}
            </UI.InfoBlock>
        </>
    );
};

export default HowToTopUpInfo;
