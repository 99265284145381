import { gql } from '@apollo/client';

export type TopupWithIziCashMutation = {
    input: TopupWithIziCashInput;
}

export type TopupWithIziCashInput = {
    amount: number;
    document: string;
}

export type TopupWithIziCashResponse = {
    topupWithIziCash: {
        voucherReference: string;
    }
}

export default gql(`
    mutation topupWithIziCash($input: TopupWithIziCashInput!){
      topupWithIziCash(
        input: $input
      ) {
        voucherReference
      }
    }
`);
