import { Component, createRef, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Price from '@/lib/format/price';
import { currentTargetValue } from '@/lib/forms/selectors';
import { AMOUNT_ID } from '@/lib/payments/forms/indentificators';

import * as UI from './ui';

import Resources from '../../../../resources';

type AmountInputProps = {
    symbol?: string;
    error?: string;
    amount: string;
    minAmount?: number;
    suggestedAmounts: number[]
    onChange: (value: string) => void;
    onSuggestedAmountClick: (value: number) => void;
    onSubmitByKeyboard: VoidFunction;
} & WithTranslation;

const DEFAULT_DIGIT = '0';
const EMPTY = '';

class AmountInput extends Component<AmountInputProps> {
    private inputRef: RefObject<HTMLInputElement>;

    constructor(props: AmountInputProps) {
        super(props);

        this.inputRef = createRef();
    }

    public componentDidMount = () => {
        if (this.inputRef.current) {
            this.inputRef.current.addEventListener('beforeinput', ({ inputType }) => {
                if (inputType === 'insertLineBreak') {
                    this.props.onSubmitByKeyboard();
                }
            });
        }

        window?.document.addEventListener('click', this.handleDocumentClick);
    };

    public componentWillUnmount = () => {
        window?.document.removeEventListener('click', this.handleDocumentClick);
    };

    private handleDocumentClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLElement) {
            const isChipButton = e.target.dataset.id === Resources.test.chipButton;
            const isAmountInput = e.target.id === AMOUNT_ID;

            if (isChipButton || isAmountInput) {
                return;
            }
        }

        if (this.props.amount === '') {
            this.props.onChange('0');
        }
    };

    private handleChange = (value: string) => {
        value = value.replace(/\D/g, '');
        while (value.startsWith(DEFAULT_DIGIT)) {
            value = value.substring(1);
        }

        this.props.onChange(value);
    };

    private handleFocus = (value: string) => {
        this.props.onChange(value === DEFAULT_DIGIT ? EMPTY : value);
    };

    public render() {
        const { symbol, amount, error, suggestedAmounts, minAmount } = this.props;
        const hasError = Boolean(error);

        return (
            <UI.Wrapper>
                <UI.AmountTitle>
                    { this.props.t('shared.ui.domain.AmountInput.title') }
                </UI.AmountTitle>

                <UI.InputWrapper>
                    <UI.InputContent>
                        <UI.Symbol $error={hasError}>
                            {symbol}
                        </UI.Symbol>

                        <UI.Input
                            ref={this.inputRef}
                            autoFocus
                            $error={hasError}
                            id={AMOUNT_ID}
                            data-id={AMOUNT_ID}
                            inputMode='numeric'
                            placeholder=''
                            pattern='\d*'
                            value={amount}
                            onFocus={currentTargetValue(this.handleFocus)}
                            onChange={currentTargetValue(this.handleChange)}
                        />

                    </UI.InputContent>

                    {
                        hasError ? (
                            <UI.Error data-id={`${AMOUNT_ID}-validation-error`}>
                                {error}
                            </UI.Error>
                        ) : null
                    }
                </UI.InputWrapper>

                {
                    minAmount && !hasError ? (
                        <UI.LimitLabel>
                            {
                                this.props.t('shared.ui.domain.AmountInput.minimumAlert', {
                                    amount: new Price(minAmount, symbol || '').format()
                                })
                            }
                        </UI.LimitLabel>
                    ) : null
                }

                {
                    suggestedAmounts && suggestedAmounts.length > 0 ? (
                        <UI.ChipsWrapper>
                            {
                                suggestedAmounts.map((value, index) => (
                                    <UI.Chip
                                        key={index}
                                        data-id={Resources.test.chipButton}
                                        onClick={() => this.props.onSuggestedAmountClick(value)}
                                    >
                                        {value}
                                    </UI.Chip>
                                ))
                            }
                        </UI.ChipsWrapper>
                    ) : <UI.ChipsEmptySpace $show={!this.props.error} />
                }
            </UI.Wrapper>
        );
    }
}

export default withTranslation()(AmountInput);
