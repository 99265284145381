import * as Sentry from '@sentry/react';

import { DEFAULT_LANG } from '@/lib/locale';

/**
 * @link {https://docs.dlocal.com/reference/the-dlocal-object}
 */
export const DLOCAL_SUPPORTING_LANGUAGES = [
    'es',
    'en',
    'pt',
    'zh',
    'cv',
    'tr'
];

export const onlyDlocalLanguages = (code: string): typeof DLOCAL_SUPPORTING_LANGUAGES[number] => {
    if (!code) {
        Sentry.captureEvent({
            message: 'Language for dlocal is undefied',
            level: 'info',
            extra: {
                code,
            }
        });

        return DEFAULT_LANG;
    }

    if (DLOCAL_SUPPORTING_LANGUAGES.includes(code)) {
        return code;
    }

    return DEFAULT_LANG;
};
