import { PaymentProviderGQLFormError } from './payment-provider/graphql-errors';

class DataManagerValidationError {
    public formError: PaymentProviderGQLFormError;

    constructor(formError: PaymentProviderGQLFormError) {
        this.formError = formError;
    }
}

export default DataManagerValidationError;
