import { FC } from 'react';

import * as UI from './ui';

type InputOutlineProps = {
    id?: string;
    title: string;
    children: JSX.Element|JSX.Element[];
    error?: string;
};

const InputOutline: FC<InputOutlineProps> = ({
    id,
    title,
    children,
    error = '',
}) => {
    return (
        <UI.Wrapper>
            <UI.InputWrapper $error={error?.length > 0}>
                <UI.Label>{title}</UI.Label>
                {children}
            </UI.InputWrapper>

            {
                error ? (
                    <UI.Error data-id={`${id}-validation-error`}>
                        {error}
                    </UI.Error>
                ) : null
            }
        </UI.Wrapper>
    );
};

export default InputOutline;
