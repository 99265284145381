import { replaceIndriverEmail } from '@/lib/format/text';

import { getEmailField } from '../fields';
import { FormSection } from '../index';
import { getPersonalDetailsSection } from '../sections';

export const getYunoCreditCardStruct = (): FormSection[] => {
    return [
        getPersonalDetailsSection([
            getEmailField({ formatter: replaceIndriverEmail }),
        ]),
    ];
};