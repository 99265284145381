import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text16Medium } from '@/shared/typography/text';

export const Wrapper = styled.label<{ $disabled?: boolean }>`
    margin: 12px 0;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    gap: 8px;
    cursor: ${({ $disabled })=> $disabled ? 'not-allowed' : 'pointer'};
    & span {
        ${Text16Medium};

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${({ $disabled })=> variables[$disabled ? 'text-and-icon-disabled' : 'text-and-icon-primary']};
    }
`;

export const CheckBoxControl= styled.input`
    display: none;
 `;

export const CheckBox = styled.div<{ $checked?: boolean }>`
    border-radius: 8px;
    border: 2px solid ${({ $checked }) => variables[$checked ? 'extensions-background-control' : 'border-primary']};
    margin: 0;
    height: 24px;
    width: 24px;
    background-color: ${({ $checked }) => $checked && variables['extensions-background-control']};
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
        width: 12px;
        height: 12px;
        fill: ${variables['text-and-icon-oncolor']};
        display: block;
        margin: auto;
    }
`;
