import { gql } from '@apollo/client';

import { PayrailsSession } from './index';

export type TopupWithGenericMethodInput = {
    session: PayrailsSession;
    paymentMethodCode: string;
    paymentMethodType: string;
    document?: string;
}

export type TopupWithGenericMethodPayrailsResponse = {
    topupPayrailsGenericMethod: {
        redirectUrl: string;
    }
}

export type SessionPayrailsInput = {
    sessionId: string;
    transactionId: string;
    amount: number;
}

export default gql(`
    mutation topupPayrailsGenericMethod ($input: TopupWithGenericMethodInput!){
            topupPayrailsGenericMethod(
                input: $input
            ) {
                redirectUrl
            }
        }
`);
