import i18n from 'i18next';
import { FC, Suspense, useEffect, useState } from 'react';

import CenterLoader from '../../shared/ui/layout/center-loader';

type AppResourcesProps = {
    children: React.ReactNode;
}

const AppResources: FC<AppResourcesProps> = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        i18n.on('initialized', () => {
            setLoading(false);
        });
    }, []);

    return (
        <Suspense fallback={<CenterLoader loading />}>
            <CenterLoader loading={loading}>
                {children}
            </CenterLoader>
        </Suspense>
    );
};

export default AppResources;
