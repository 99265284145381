import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text14Regular, Text16Medium } from '@/shared/typography/text';

export const Wrapper = styled.div``;

export const CellContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
`;

export const Text = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px 48px 16px;
`;

export const BodyDescription = styled.div`
    padding: 8px 16px 16px 16px;
    line-height: 1.5;
`;

export const Header = styled.span`
    ${Text16Medium};
`;

export const Description = styled.span`
    ${Text14Regular};

    color: ${variables['text-and-icon-secondary']};
`;
