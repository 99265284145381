import React from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';

type GiftyCashProps = {
    referenceNumber: string;
    onCopy: (paycode: string, title: string) => void;
};

const GiftyCash: React.FC<GiftyCashProps> = ({
    referenceNumber,
    onCopy,
}) => {
    const { t } = useTranslation();

    return (
        <PaycodeView
            title={t('views.PaymentMethods.Cash.GiftyTopUpSteps.referenceNumberLabel')}
            paycode={referenceNumber}
            onClick={() => onCopy(referenceNumber, t('views.PaymentMethods.Cash.GiftyTopUpSteps.referenceCopiedLabel'))}
        >
            <HowToTopUpInfo
                steps={
                    [
                        t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepOne'),
                        t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepTwo'),
                        t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepThree'),
                        t('views.PaymentMethods.Cash.GiftyTopUpSteps.stepFour')
                    ]
                }
            />
        </PaycodeView>
    );
};

export default GiftyCash;
