import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text16Medium } from '../../../typography/text';

export const AlertInfo = styled.div`
  ${Text16Medium};

  background: ${variables['extensions-background-lightaccent']};
  border-radius: 12px;
  padding: 13px 18px;
  display: flex;
  align-items: center;
`;

export const AlertIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-inline-end: 14px;
`;
