import * as Sentry from '@sentry/react';

import { CardData } from '@/features/credit-card/credit-card-native';
import { withZero } from '@/lib/format/text';

import Resources from '../../../../resources';

type CredoraxPkeyResponse = {
    '3d_version': string;
    '3ds_trxid': string;
    PKey: string;
    ResponseID: string;
    b2: string;
    z2: string;
    z3: string;
};

class Credorax {
    public static async getPKey(
        input: CardData,
        merchantID: string,
        staticKey: string,
    ): Promise<CredoraxPkeyResponse|undefined> {
        const params = new URLSearchParams({
            M: merchantID,
            Statickey: staticKey,
            b1: input.cardNumber.split(' ').join(''),
            b3: withZero(input.expirationMonth),
            b4: input.expirationYear,
            b5: input.cvv,
            c1: input.cardHolder,
        });

        try {
            const res = await fetch(Resources.CREDORAX_PKEY_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: params.toString(),
            });

            return res.json();
        } catch (e) {
            Sentry.captureException(e);
        }

        return undefined;
    }
}

export default Credorax;
