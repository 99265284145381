import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { Countries } from '@/lib/locale/countries';
import {
    FormValues,
    getBankTransferStruct,
} from '@/lib/payments/forms';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const DlocalBankTransferController: FC<PaymentFormData> = ({ store, services, paymentInfo }) => {
    const { t } = useTranslation();

    const handlePayment = <T extends DataType['CreateBankTransferDlocalInput']>(input: T) => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Dlocal,
            payment_option: PaymentType.BANK_TRANSFER,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.createDlocalBankTransfer(input);

            if (data?.createBankTransferDlocal?.redirectUrl === '') {
                await services.navigatorService.showFinalPage('success');
            }

            if (data?.createBankTransferDlocal?.redirectUrl) {
                await services.navigatorService.goOutside(data.createBankTransferDlocal.redirectUrl);
            }
        });
    };

    const handleSubmitPayment = async (
        formFillValues: FormValues,
    ): Promise<PaymentProviderGQLFormError> => {
        if (!store.selectedMethod.paymentMethodCode) {
            services.navigatorService.showFinalPage('error', {
                message: getSessionErrorMessage(),
            });

            return;
        }

        const data: DataType['CreateBankTransferDlocalInput'] = {
            ...formFillValues,
            username: formFillValues.username,
            email: formFillValues.email,
            document: formFillValues.document,
            amount: parseInt(store.amount, 10),
            paymentMethodCode: store.selectedMethod.paymentMethodCode,
        };

        // todo https://indriver.atlassian.net/browse/PRC-1375
        if (paymentInfo.country === Countries.SouthAfrica) {
            data.state = formFillValues.state;
            data.city = formFillValues.city;
            data.zipCode = formFillValues.zipCode;
            data.street = formFillValues.street;
            data.houseNumber = formFillValues.houseNumber;
        }

        return handlePayment(data);
    };

    return (
        <FormFieldView
            title={t('views.PaymentMethods.getVoucherSubmitCaption')}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getBankTransferStruct(
                    PaymentProviderName.Dlocal,
                    paymentInfo.country,
                    { short: store.selectedMethod.isCustomerSaved || false }
                )
            }
            onSubmit={handleSubmitPayment}
        />
    );
};

export default DlocalBankTransferController;
