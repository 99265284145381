import { gql } from '@apollo/client';

export type TopupWithDlocalWalletMutation = {
    input: TopupWithDlocalWalletInput;
}

export type TopupWithDlocalWalletInput = {
    amount: number;
    username: string;
    email: string;
    document: string;
    paymentMethodCode: string;
}

export type TopupWithDlocalWalletResponse = {
    topupWithDlocalWallet: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation topupWithDlocalWallet ($input: TopupWithDlocalWalletInput!){
        topupWithDlocalWallet(
            input: $input
        ) {
            redirectUrl
        }
    }
`);
