import * as Sentry from '@sentry/react';
import i18n from 'i18next';

import { DEFAULT_LANG, getCountryCode, getCountryLanguage } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';

import { CreateCheckoutOptions, YunoInstance } from './options';

declare global {
    interface Window {
        Yuno: {
            initialize: (publicApiKey: string) => YunoInstance;
        }
    }
}

class YunoService {
    private instance: YunoInstance;

    static SUPPORTING_LANGUAGES = [
        'es',
        'en',
        'pt',
        'id'
    ];

    static YUNO_CHECKOUT_ELEMENT_ID = 'yuno-checkout';

    constructor(instance: YunoInstance) {
        this.instance = instance;
    }

    private onlyYunoLanguages = (code: string): typeof YunoService.SUPPORTING_LANGUAGES[number] => {
        if (!code) {
            Sentry.captureEvent({
                message: 'Language for yuno is undefied',
                level: 'info',
                extra: {
                    code,
                }
            });

            return DEFAULT_LANG;
        }

        if (YunoService.SUPPORTING_LANGUAGES.includes(code)) {
            return code;
        }

        return DEFAULT_LANG;
    };

    public async checkout(options: CreateCheckoutOptions) {
        const { session, country, onCreate, onError } = options;

        if (!this.instance) {
            Sentry.captureException('Yuno is not ready');

            return;
        }

        this.instance.startCheckout({
            checkoutSession: session.sessionToken,
            elementSelector: '#' + YunoService.YUNO_CHECKOUT_ELEMENT_ID,
            countryCode: getCountryCode(country),
            language: this.onlyYunoLanguages(getCountryLanguage(country) || i18n.language),
            showLoading: true,
            card: {
                // Battle of Jamaica Conversion
                // https://indriver.slack.com/archives/C03TDLP7AQM/p1708959077412249
                cardSaveEnable: country !== Countries.Jamaica
            },
            yunoCreatePayment: onCreate,
            yunoError: onError,
        });

        this.instance.mountCheckoutLite({
            paymentMethodType: session.methodType,
            vaultedToken: session.vaultedToken
        });
    }

    public continuePayment() {
        this.instance.continuePayment();
    }
}

export default YunoService;
