import { FC, useCallback, useRef, useState } from 'react';

import DlocalCard, { DlocalCardData } from '@/features/credit-card/dlocal-card';
import { CreditCardForm } from '@/lib/forms/actions';
import DlocalService from '@/services/dlocal-service';
import { CashlessRedirectPayload } from '@/services/navigator-service';
import SubmitForm from '@/shared/ui/domain/submit-form';

import * as UI from './ui';

type CashlessFormProps = {
    dlocalService: DlocalService;
    onTokenData: (payload: CashlessRedirectPayload) => void;
    onError: (error: string) => void;
}

const CashlessForm: FC<CashlessFormProps> = ({
    dlocalService,
    onTokenData,
    onError,
}) => {
    const creditCardFormRef = useRef<CreditCardForm<DlocalCardData>>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        setLoading(true);


        if (!creditCardFormRef.current || !creditCardFormRef.current?.validate()) {
            setLoading(false);

            return;
        }

        const creditCardData = await creditCardFormRef.current.getData();
        if (creditCardData === null) {
            setLoading(false);

            return;
        }

        const { token, lastFour, binInfo } = creditCardData;

        onTokenData({
            token,
            bin: binInfo.bin,
            country: binInfo.country,
            lastFour: lastFour
        });
    }, []);

    return (
        <UI.Wrapper>
            <SubmitForm
                loading={loading}
                onSubmit={handleSubmit}
            >
                <DlocalCard
                    ref={creditCardFormRef}
                    dlocalService={dlocalService}
                    onError={onError}
                />
            </SubmitForm>
        </UI.Wrapper>
    );
};

export default CashlessForm;
