import { Countries } from '@/lib/locale/countries';

import { getDocumentField, getEmailField, getUsernameField } from '../fields';
import { FormSection } from '../index';
import { getPersonalDetailsSection } from '../sections';

export const getDlocalCashStruct = (
    countryId: Countries
): FormSection[] => {
    switch (countryId) {
        case Countries.Morocco:
            return [
                getPersonalDetailsSection([
                    getUsernameField(),
                    getEmailField(),
                ]),
            ];
        default:
            return [
                getPersonalDetailsSection([
                    getUsernameField(),
                    getEmailField(),
                    getDocumentField(countryId),
                ]),
            ];
    }
};
