import { Button } from '@indriver/nova';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as UI from './ui';

import Resources from '../../../../resources';

type SubmitFormProps =  {
    title?: string;
    loading: boolean;
    disabled?: boolean;
    children?: JSX.Element|JSX.Element[];
    onSubmit: VoidFunction;
}

const SubmitForm: FC<SubmitFormProps> = ({
    title,
    loading,
    disabled,
    children,
    onSubmit,
}) => {
    const { t } = useTranslation();

    return (
        <UI.Wrapper>
            {children}

            <UI.SubmitWrapper>
                <Button
                    data-id={Resources.test.submit}
                    type='submit'
                    loading={loading}
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    {title || t('shared.ui.domain.SubmitForm.submitCaption')}
                </Button>
            </UI.SubmitWrapper>
        </UI.Wrapper>
    );
};

export default SubmitForm;
