import jwtDecode from 'jwt-decode';

import { Countries } from './locale/countries';

/**
 * @link {https://dev.indriver.io/services/cas-backend/docs/main/docs/monolith_to_cas}
 * @link {https://indriver.atlassian.net/wiki/spaces/PRC/pages/1739194718/JWT+payment-provider}
 */
type AccessTokenData = {
    uid: number;
    exp: number;
    jti: string;
    cid: number;
    coid: number;
}

type TokenData = {
    user_id: number;
    exp: number;
    city_id: number;
    country_id: number;
    country_name: string;
}

class JWTToken {
    private jwtValue: string;

    constructor(jwtValue: string) {
        this.jwtValue = jwtValue;
    }

    public getJwtValue = (): string => {
        return this.jwtValue;
    };

    public getBearerFormat = (): `Bearer ${string}` => {
        return `Bearer ${this.jwtValue}`;
    };

    public isExpired = (token = this.getToken()) => {
        return token.exp - new Date().getTime() < 0;
    };

    public getToken = (): TokenData => {
        const inDriverToken = jwtDecode<AccessTokenData>(this.jwtValue);
        const unixEpochMs = inDriverToken.exp * 1000;

        return {
            user_id: inDriverToken.uid,
            exp: unixEpochMs,
            city_id: inDriverToken.cid,
            country_id: inDriverToken.coid,
            country_name: Countries[inDriverToken.coid],
        };
    };
}

export default JWTToken;
