import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import {
    FormType,
    FormValues,
    getBankCardStruct,
} from '@/lib/payments/forms';
import {
    PaymentType
} from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const OneVisionCardController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { t } = useTranslation();

    const handlePayment = async <T extends DataType['TopupWithOneVisionInput']>(
        input: T
    ): Promise<PaymentProviderGQLFormError> => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.OneVision,
            payment_option: PaymentType.BANK_CARD,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topUpWithOneVision(input);

            if (data?.topupWithOneVision.redirectUrl) {
                services.navigatorService.goOutside(data.topupWithOneVision.redirectUrl);

                return;
            }

            if (data?.topupWithOneVision.redirectUrl === '') {
                await services.navigatorService.showFinalPage('error');
            }
        });
    };

    const handleSubmitPayment = async (
        formFillValues: FormValues,
    ): Promise<PaymentProviderGQLFormError> => {
        if (!store.selectedMethod.paymentMethodCode) {
            services.navigatorService.showFinalPage('error', {
                message: getSessionErrorMessage(),
            });

            return;
        }

        return handlePayment({
            paymentMethodCode: store.selectedMethod.paymentMethodCode,
            phone: formFillValues.phone,
            amount: parseInt(store.amount, 10),
        });
    };

    return (
        <FormFieldView
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getBankCardStruct(
                    PaymentProviderName.OneVision,
                    paymentInfo.country,
                    FormType.NEW,
                    { short: store.selectedMethod.isCustomerSaved || false }
                )
            }
            onSubmit={handleSubmitPayment}
        />
    );
};

export default OneVisionCardController;
