import { gql } from '@apollo/client';

export type TopupWithIyzicoWalletMutation = { input: TopupWithIyzicoWalletInput };

export type TopupWithIyzicoWalletInput = {
    amount: number;
    document: string;
    city: string;
    address: string;
    billingCity: string;
    billingAddress: string;
};

export type TopupWithIyzicoWalletResponse = {
    topupWithIyzicoWallet: {
        redirectUrl: string;
    };
};

export default gql(`
    mutation topupWithIyzicoWallet ($input: TopupWithIyzicoInput!){
        topupWithIyzicoWallet(
            input: $input
        ) {
            redirectUrl
        }
    }
`);
