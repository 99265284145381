import { HintFilled } from '@indriver/mireska';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../core/alert';
import FormInput from '../../core/form-input';

import CVVHint from '../credit-card-field/cvv-hint';

import * as UI from './ui';

export enum CreditCardViewType {
    COMMON,
    SEPARATE
}

type CreditCardOutlineProps = {
    cvvOnly?: boolean;
    cardNumberInput?: JSX.Element;
    cardNumberError?: string;
    expirationInput?: {
        type: CreditCardViewType.COMMON;
        children: JSX.Element;
        expirationError?: string;
    } | {
        type: CreditCardViewType.SEPARATE;
        expirationMonth: JSX.Element,
        expirationMonthError?: string;
        expirationYear: JSX.Element,
        expirationYearError?: string;
    };

    cvvInput: JSX.Element;
    cvvError?: string;
    cardHolderInput?: JSX.Element;
    cardHolderError?: string;
}

const CreditCardOutline: FC<CreditCardOutlineProps> = ({
    cvvOnly,
    cardNumberInput,
    cardNumberError,
    expirationInput,
    cvvInput,
    cvvError,
    cardHolderInput,
    cardHolderError,
}) => {
    const { t } = useTranslation();
    const [showHint, setShowHint] = useState(false);
    const toggleHint = useCallback(() => setShowHint((p) => !p), []);

    if (cvvOnly) {
        return (
            <>
                <UI.ShortWrapper>
                    <FormInput
                        title={t('shared.ui.domain.CreditCardOutline.cvvCaption')}
                        error={cvvError}
                        icon={HintFilled}
                        onIconClick={toggleHint}
                    >
                        {cvvInput}
                    </FormInput>
                </UI.ShortWrapper>

                <CVVHint
                    open={showHint}
                    onClose={toggleHint}
                />
            </>
        );
    }

    return (
        <>
            <UI.Title>
                <Alert>
                    {t('shared.ui.domain.CreditCardOutline.alertMessage')}
                </Alert>
            </UI.Title>

            <UI.InputWrapper>
                <FormInput
                    data-id='cardnumber'
                    title={t('shared.ui.domain.CreditCardOutline.panCaption')}
                    error={cardNumberError}
                >
                    {cardNumberInput}
                </FormInput>
            </UI.InputWrapper>

            {
                expirationInput? (
                    <>
                        {
                            expirationInput.type === CreditCardViewType.SEPARATE? (
                                <UI.SeparateWrapper>
                                    <FormInput
                                        data-id='expmonth'
                                        title={t('shared.ui.domain.CreditCardOutline.expirationMonth')}
                                        error={expirationInput.expirationMonthError}
                                    >
                                        {expirationInput.expirationMonth}
                                    </FormInput>

                                    <FormInput
                                        data-id='expyear'
                                        title={t('shared.ui.domain.CreditCardOutline.expirationYear')}
                                        error={expirationInput.expirationYearError}
                                    >
                                        {expirationInput.expirationYear}
                                    </FormInput>

                                    <FormInput
                                        data-id='cvv'
                                        title={t('shared.ui.domain.CreditCardOutline.cvvCaption')}
                                        error={cvvError}
                                        icon={HintFilled}
                                        short
                                        onIconClick={toggleHint}
                                    >
                                        {cvvInput}
                                    </FormInput>
                                </UI.SeparateWrapper>
                            ): (
                                <UI.ShortWrapper>
                                    <FormInput
                                        data-id='expmonthyear'
                                        title={t('shared.ui.domain.CreditCardOutline.expirationCaption')}
                                        error={expirationInput.expirationError}
                                    >
                                        {expirationInput.children}
                                    </FormInput>

                                    <FormInput
                                        data-id='cvv'
                                        title={t('shared.ui.domain.CreditCardOutline.cvvCaption')}
                                        error={cvvError}
                                        icon={HintFilled}
                                        onIconClick={toggleHint}
                                    >
                                        {cvvInput}
                                    </FormInput>
                                </UI.ShortWrapper>
                            )
                        }
                    </>
                ): null
            }

            {
                cardHolderInput ? (
                    <UI.InputWrapper>
                        <FormInput
                            data-id='cardholder'
                            title={t('shared.ui.domain.CreditCardOutline.cardHolderCaption')}
                            error={cardHolderError}
                        >
                            {cardHolderInput}
                        </FormInput>
                    </UI.InputWrapper>
                ) : null
            }

            <CVVHint
                open={showHint}
                onClose={toggleHint}
            />
        </>
    );
};

export default CreditCardOutline;
