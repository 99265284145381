import Cookie from '@/lib/cookie';
import DisplayedError from '@/lib/errors/displayed-error';
import PaymentProviderError from '@/lib/errors/payment-provider/rest';
import JWTToken from '@/lib/jwt';
import EventEmitter from '@/lib/subscriber';
import ConfigurationService from '@/services/configuration-service';
import IPCService from '@/services/ipc-service';
import LogsService from '@/services/logs-service';

class AuthorizationService extends EventEmitter<{
    'onToken': JWTToken;
    'onTimeout': DisplayedError;
}>{
    private jwt: JWTToken | null;
    private ipcService: IPCService;
    private logsService: LogsService;

    constructor(ipc: IPCService, logsService: LogsService) {
        super();

        this.ipcService = ipc;
        this.logsService = logsService;
        this.jwt = null;
    }

    private requestAuth = async (prev?: string): Promise<string> => {
        let authValue;

        const fromCookie = Cookie.parse(document.cookie || '', ConfigurationService.COOKIE_AUTH_NAME);
        if (fromCookie) {
            this.logsService.write('get auth from cookie');
            authValue = fromCookie;
        }

        if (authValue) {
            return authValue;
        }

        authValue = await this.ipcService.refreshJWT(prev);

        this.logsService.write('token refresh success');

        return authValue;
    };

    public async refresh(callback?: (jwt: JWTToken) => void) {
        try {
            this.logsService.write('request auth');
            const jwtValue = await this.requestAuth();
            this.jwt = new JWTToken(jwtValue);

            if (callback) {
                this.dispatch('onToken', this.jwt);
                callback(this.jwt);
            }
        } catch (e) {
            const error = new PaymentProviderError();

            this.dispatch('onTimeout', new DisplayedError(
                error.unauhthorized(),
            ));
        }
    }

    public getJWT = (): JWTToken => {
        if (!this.jwt) {
            const error = new PaymentProviderError();
            throw new DisplayedError(error.unauhthorized());
        }

        return this.jwt;
    };

    /** for dev panel only */
    public writeJWT(jwt: JWTToken) {
        Cookie.write(ConfigurationService.COOKIE_AUTH_NAME, jwt.getJwtValue());
    }
}

export default AuthorizationService;
