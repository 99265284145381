import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';
import KZPromo from '@/services/kz-promo';

type Kassa24TerminalProps = {
    referenceNumber: string;
    promotion: KZPromo;
    onCopy: (paycode: string, title: string) => void;
  }

const Kassa24Terminal: FC<Kassa24TerminalProps> = ({
    referenceNumber,
    onCopy,
    promotion,
}) => {
    const { t } = useTranslation();

    return (
        <PaycodeView
            paycode={referenceNumber}
            title={t('views.PaymentMethods.Terminal.Kassa24.caption')}
            onClick={() => onCopy(referenceNumber, t('views.PaymentMethods.Terminal.Kassa24.numberCopiedLabel'))}
            promotion={promotion}
        >
            <HowToTopUpInfo
                steps={
                    [
                        t('views.PaymentMethods.Terminal.Kassa24.instructionStep1'),
                        t('views.PaymentMethods.Terminal.Kassa24.instructionStep2'),
                        t('views.PaymentMethods.Terminal.Kassa24.instructionStep3')
                    ]
                }
            />
        </PaycodeView>
    );
};

export default Kassa24Terminal;
