import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';
import { Countries } from '@/lib/locale/countries';

type PuntoxpressCashProps = {
    referenceNumber: string;
    countryId: number;
    onCopy: (paycode: string, title: string) => void;
};

const PuntoxpressCash: FC<PuntoxpressCashProps> = ({
    referenceNumber,
    countryId,
    onCopy,
}) => {
    const { t } = useTranslation();

    return (
        <PaycodeView
            paycode={referenceNumber}
            title={t('views.PaymentMethods.Cash.PuntoExpress.referenceTitle')}
            onClick={
                () => onCopy(referenceNumber, t('views.PaymentMethods.Cash.PuntoExpress.referenceNumberCopiedLabel'))
            }
        >
            <HowToTopUpInfo
                steps={
                    (() => {
                        switch (countryId) {
                            case Countries.Salvador:
                                return [
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoSalvadorInstructionStep1'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoSalvadorInstructionStep2'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoSalvadorInstructionStep3'),
                                ];
                            case Countries.Nicaragua:
                                return [
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoNicaraguaInstructionStep1'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoNicaraguaInstructionStep2'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoNicaraguaInstructionStep3'),
                                ];
                            case Countries.Guatemala:
                                return [
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoGuatemalaInstructionStep1'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoGuatemalaInstructionStep2'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoGuatemalaInstructionStep3'),
                                ];
                            case Countries.Honduras:
                                return [
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoHondurasInstructionStep1'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoHondurasInstructionStep2'),
                                    t('views.PaymentMethods.Cash.PuntoExpress.puntoHondurasInstructionStep3'),
                                ];
                            default:
                                return [];
                        }
                    })()
                }
                providerSupportTitle={t('views.PaymentMethods.Cash.PuntoExpress.providerSupportTitle')}
            />
        </PaycodeView>
    );
};

export default PuntoxpressCash;
