import { FC } from 'react';

import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import ErrorStatus from '@/lib/errors/status';
import { FormValues, getIyzicoStruct } from '@/lib/payments/forms';
import {
    FULL_ADDRESS_ID,
    FULL_BILLING_ADDRESS_ID,
    BILLING_CITY_ID,
    CITY_ID,
    DOCUMENT_ID,
} from '@/lib/payments/forms/indentificators';

import IyzicoCheckout from '../checkouts/iyzico-checkout';
import { PaymentFormData } from '../index';

const IyzicoWalletController: FC<PaymentFormData> = ({ store, services, paymentInfo }) => {
    const handleSubmit = async (
        checked: boolean,
        formFillValues: FormValues
    ): Promise<PaymentProviderGQLFormError> => {
        const billingAddress = checked ? {
            billingCity: formFillValues[CITY_ID],
            billingAddress: formFillValues[FULL_ADDRESS_ID],
        } : {
            billingCity: formFillValues[BILLING_CITY_ID],
            billingAddress: formFillValues[FULL_BILLING_ADDRESS_ID],
        };

        const body = {
            amount: parseInt(store.amount, 10),
            document: formFillValues[DOCUMENT_ID],
            city: formFillValues[CITY_ID],
            address: formFillValues[FULL_ADDRESS_ID],
            ...billingAddress,
        };

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithIyzicoWallet(body);
            if (data) {
                const redirect = data.topupWithIyzicoWallet.redirectUrl;
                services.navigatorService.goOutside(redirect);

                return;
            }
            await services.navigatorService.showFinalPage('error', {
                errorStatus: ErrorStatus.COMMON_INTERNAL_ERROR,
            });
        });
    };

    const { personalFields, billingFields } = getIyzicoStruct();

    return (
        <IyzicoCheckout
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            personalFields={personalFields}
            billingFields={billingFields}
            handleSubmit={handleSubmit}
        />
    );
};

export default IyzicoWalletController;
