import Validation from '@/lib/forms/validation';
import { FormSection } from '@/lib/payments/forms';
import { getPhoneField } from '@/lib/payments/forms/fields';
import { getPersonalDetailsSection } from '@/lib/payments/forms/sections';

export const getSimpaisaProviderControlledStruct = (): FormSection[] => {
    return [
        getPersonalDetailsSection([
            getPhoneField(
                {
                    mask:'+{0}00000000000000',
                    validators: [Validation.required]
                }
            )
        ])
    ];
};