import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FillForm from '@/features/payment-fill-form';
import { PaymentProviderGQLFormError } from '@/lib/errors/payment-provider/graphql-errors';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import FormInput from '@/shared/ui/core/form-input';
import SubmitForm from '@/shared/ui/domain/submit-form';

import Resources from '../../../../../../../resources';
import { AppData } from '../../../../../index';

type PayrailsSavedCardViewProps = {
    country: Countries;
    onSubmit: (
        formFillValues: FormValues,
    ) => Promise<PaymentProviderGQLFormError>;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    panValue: string;
    onError: (error: string) => void;
};

const PayrailsSavedCardView: FC<PayrailsSavedCardViewProps> = (props) => {
    const { country, onSubmit, savedDocuments, formFields, panValue } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const paymentFillFormRef = useRef<FillForm>(null);

    const handleSubmit = useCallback(async () => {
        let data: Record<string, string> = {};

        if (paymentFillFormRef.current) {
            if (!paymentFillFormRef.current.validate()) {
                return;
            }

            const paymentFillFormData = paymentFillFormRef.current.getData();

            data = {
                ...paymentFillFormData,
            };
        }

        setLoading(true);

        const error = await onSubmit(data);
        if (error) {
            if (paymentFillFormRef.current) {
                paymentFillFormRef.current.setError(error);
            }

            setLoading(false);
        }
    }, [paymentFillFormRef]);

    return (
        <SubmitForm
            loading={loading}
            onSubmit={handleSubmit}
        >
            <FormInput
                disabled
                title={t('shared.ui.domain.CreditCardOutline.panCaption')}
                data-id={Resources.test.existedCard}
                // https://indriver.atlassian.net/browse/PRC-2269
                value={panValue}
            />

            <FillForm
                ref={paymentFillFormRef}
                country={country}
                savedDocuments={savedDocuments}
                fields={formFields}
            />
        </SubmitForm>
    );
};
export default PayrailsSavedCardView;
