import { ChangeEvent, FC } from 'react';

import CheckboxSign from './checkbox-icon';
import * as UI from './ui';

import Resources from '../../../../resources';

type CheckboxProps = {
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  title: string;
  disabled?: boolean;
};

const Checkbox: FC<CheckboxProps> = ({
    title,
    checked,
    onChange,
    id,
    disabled,
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }
        onChange(e);
    };

    return (
        <UI.Wrapper
            data-id={Resources.test.checkbox}
            htmlFor={id}
            $disabled={disabled}
        >
            <span>{title}</span>

            <UI.CheckBox $checked={checked}>
                {checked ? <CheckboxSign /> : null}
            </UI.CheckBox>

            <UI.CheckBoxControl
                id={id}
                data-id={id}
                type='checkbox'
                checked={checked}
                onChange={handleChange}
            />
        </UI.Wrapper>
    );
};

export default Checkbox;
