import { InfoOutline } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { FC } from 'react';

import * as UI from './ui';

type AlertProps = {
    children: JSX.Element | string;
};

const Alert: FC<AlertProps> = ({ children }) => {
    return (
        <UI.AlertInfo>
            <UI.AlertIcon>
                <InfoOutline
                    size={20}
                    color={variables['text-and-icon-accent']}
                />
            </UI.AlertIcon>

            {children}
        </UI.AlertInfo>
    );
};

export default Alert;
