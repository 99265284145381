import { variables } from '@indriver/yrel';
import { css } from 'styled-components';

const FontSmooth = css`
    color: ${variables['text-and-icon-primary']};
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
`;

/** @link {https://www.figma.com/file/JRCIzocVrRugUMZvExJqZO/%F0%9F%96%8D-Typography} */

// todo problem with 400-500 font-weight
export const Display48Bold = css`
    font-size: 48px;
    line-height: 65px;
    font-weight: 700;
    letter-spacing: -0.2px;

    ${FontSmooth};
`;

export const Header32Bold = css`
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    letter-spacing: -0.2px;

    ${FontSmooth};
`;

export const Header24Bold = css`
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: -0.2px;

    ${FontSmooth};
`;

export const Header20Bold = css`
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    letter-spacing: -0.2px;

    ${FontSmooth};
`;

export const Header20Medium = css`
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Header20Regular = css`
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text18Bold = css`
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text18Medium = css`
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text18Regular = css`
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text16Bold = css`
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text16Medium = css`
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text16Regular = css`
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text14Bold = css`
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text14Medium = css`
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text14Regular = css`
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0;

    ${FontSmooth};
`;

export const Text12Medium = css`
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0;

    ${FontSmooth};
`;
