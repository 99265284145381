import { gql } from '@apollo/client';

export type CreateCashDlocalMutation = {
    input: CreateCashDlocalInput;
}

export type CreateCashDlocalInput = {
    amount: number;
    username: string;
    email: string;
    document: string;
    paymentMethodCode: string;
}

export type CreateCashDlocalResponse = {
    createCashDlocal: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation createCashDlocal($input: CreateCashDlocalInput!){
      createCashDlocal(
        input: $input
      ) {
        redirectUrl
      }
    }
`);
