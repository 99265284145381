import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';
import KZPromo from '@/services/kz-promo';

type KaspiBankTransferProps = {
    referenceNumber: string;
    redirectUrl: string;
    onRedirect: (redirectUrl: string) => void;
    onCopy: (paycode: string, title: string) => void;
    promotion: KZPromo;
}

const KaspiBankTransfer: FC<KaspiBankTransferProps> = ({
    referenceNumber,
    redirectUrl,
    onRedirect,
    onCopy,
    promotion,
}) => {
    const { t } = useTranslation();

    const handleSubmit = useCallback(() => {
        onRedirect(redirectUrl);
    }, [redirectUrl]);

    return (
        <PaycodeView
            title={t('views.PaymentMethods.BankTransfer.Kaspi.referenceTitle')}
            paycode={referenceNumber}
            onClick={
                () => onCopy(referenceNumber, t('views.PaymentMethods.BankTransfer.Kaspi.referenceCopiedLabel'))
            }
            promotion={promotion}
        >
            <HowToTopUpInfo
                steps={
                    [
                        t('views.PaymentMethods.BankTransfer.Kaspi.stepOne'),
                        t('views.PaymentMethods.BankTransfer.Kaspi.stepTwo'),
                    ]
                }
                submitCaption={t('views.PaymentMethods.BankTransfer.Kaspi.submitCaption')}
                onSubmit={handleSubmit}
            />
        </PaycodeView>
    );
};

export default KaspiBankTransfer;
