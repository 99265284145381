import { FC, useCallback, useState } from 'react';

import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import { getPhoneBalanceStruct } from '@/lib/payments/forms';
import {
    CONFIRMATION_CODE_ID,
    PHONE_ID
} from '@/lib/payments/forms/indentificators';
import { getCodeConfirmationSections } from '@/lib/payments/forms/sections';
import { PaymentProviderName } from '@/lib/payments/providers';

import { PaymentFormData } from '../index';

import WooppayPhoneBalanceView from './wooppay';

import { WooppaySession } from '../../../../../../graphql/phone-balance/woopay';

const WooppayPhoneTransferController: FC<PaymentFormData> = ({ services, store, paymentInfo }) => {
    const [detailsFields] = useState(getPhoneBalanceStruct(
        PaymentProviderName.Wooppay,
        paymentInfo.country
    ));
    const [confirmationFields] = useState(getCodeConfirmationSections());
    const [session, setSession] = useState<WooppaySession|undefined>(undefined);

    const handleCreateSession = useCallback((
        input: Record<string, string>
    ): Promise<PaymentProviderGQLFormError> => {
        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.createWoopaySession({
                [PHONE_ID]: input.phone,
                amount: parseInt(store.amount, 10)
            });

            if (data?.createWooppaySession) {
                setSession(data.createWooppaySession);
            }
        });
    }, []);

    const handleCodeConfirm = useCallback((
        input: Record<string, string>
    ): Promise<PaymentProviderGQLFormError> => {
        return services.paymentProviderService.process(async (req) => {
            if (!session) {
                return;
            }

            const { data } = await req.topupWooppay({
                session,
                [CONFIRMATION_CODE_ID]: input[CONFIRMATION_CODE_ID],
            });

            if (data?.topupWooppay.result) {
                services.navigatorService.showFinalPage('success');

                return;
            }

            services.navigatorService.showFinalPage('error');
        });
    }, [session]);

    if (!session) {
        return (
            <WooppayPhoneBalanceView
                country={paymentInfo.country}
                savedDocuments={paymentInfo.savedDocuments}
                formFields={detailsFields}
                onSubmit={handleCreateSession}
            />
        );
    }

    return (
        <WooppayPhoneBalanceView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={confirmationFields}
            onSubmit={handleCodeConfirm}
        />
    );
};

export default WooppayPhoneTransferController;
