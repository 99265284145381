import { Countries } from '@/lib/locale/countries';

import { getPhoneField } from '../fields';
import { FormSection } from '../index';
import { getPersonalDetailsSection } from '../sections';

export const getWooppayPhoneBalanceStruct = (country: Countries): FormSection[] => {
    switch (country) {
        case Countries.Kazakhstan:
            return [
                getPersonalDetailsSection([
                    getPhoneField({
                        mask: '+{7}(000)000-00-00',
                    }),
                ]),
            ];

        default:
            return [
                getPersonalDetailsSection([
                    getPhoneField(),
                ]),
            ];
    }

};
