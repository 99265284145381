import { gql } from '@apollo/client';

export type TopupWithSimpaisaMutation = {
    input: TopupWithSimpaisaInput;
}

export type TopupWithSimpaisaInput = {
    amount: number;
    phone: string;
}

export type TopupWithSimpaisaResponse = {
    topupWithSimpaisa: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation topupWithSimpaisa($input: TopupWithSimpaisaInput!) {
        topupWithSimpaisa(input: $input) {
            redirectUrl
        }
    }
`);
