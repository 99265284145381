import { Loader } from '@indriver/nova';
import React, { FC } from 'react';

import { Center } from '../center';

import * as UI from './ui';

type CenterLoaderProps = {
    loading: boolean;
    /** Semi-opacity loading overlay above content */
    overlay?: boolean;
    /** Makes the content below the overlay flexible and forces it to stretch. */
    flex?: boolean;
    title?: string;
    children?: React.ReactNode;
}

const CenterLoader: FC<CenterLoaderProps> = ({
    title,
    loading,
    overlay,
    flex,
    children
}) => {
    if (overlay) {
        return (
            <UI.Wrapper
                $overlay={overlay}
                $flex={flex}
            >
                <UI.Overlay $hide={!loading}>
                    <Center>
                        <Loader />

                        <UI.Title>
                            {title}
                        </UI.Title>
                    </Center>
                </UI.Overlay>

                <UI.Content
                    $showWithOpacity={loading}
                    $flex={flex}
                >
                    {children}
                </UI.Content>
            </UI.Wrapper>
        );
    }

    if (loading) {
        return (
            <Center>
                <Loader />
            </Center>
        );
    }

    if (children) {
        return <>{children}</>;
    }

    return <></>;
};

export default CenterLoader;
