import { FC, useCallback, useEffect, useState } from 'react';

import { PayrailsCardData } from '@/features/credit-card/payrails-card';
import {
    PaymentProviderGQLFormError
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { FormValues } from '@/lib/payments/forms';
import { DOCUMENT_ID } from '@/lib/payments/forms/indentificators';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

import PayrailsBankCardView from './payrails';
import PayrailsSavedCardView from './payrails/saved-card';

import { PayrailsInitOptions } from '../../../../../../graphql/payrails';

const PayrailsBankCardController: FC<PaymentFormData> = ({
    store,
    paymentInfo,
    services,
}) => {
    const [initOptions, setInitOptions] = useState<PayrailsInitOptions | undefined>();

    useEffect(() => {
        services.paymentProviderService.process(async (req) => {
            const { data } = await req.getPayrailsInitOptions({
                instrumentID: store.selectedMethod.session?.payrails?.instrumentId || '',
            });

            setInitOptions(data?.payrailsInitOptions);
        });
    }, [store]);

    const handleSubmitPayment = async (
        formFillValues: FormValues<PayrailsCardData>,
    ): Promise<PaymentProviderGQLFormError> => {
        return services.paymentProviderService.process(async (req) => {
            if (!store.selectedMethod.session?.payrails) {
                services.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const payrailsData = formFillValues?.numberToken ? {
                numberToken: formFillValues.numberToken,
                securityCodeToken: formFillValues.securityCodeToken,
                vaultedToken: formFillValues.vaultedToken,
            } : {
                collectData: {
                    vaultProviderConfigId: formFillValues.vaultProviderConfigId,
                    encryptedData: formFillValues.encryptedData,
                },
            };

            const { data } = await req.topUpWithPayrailsCard({
                session: {
                    sessionId: store.selectedMethod.session.payrails.session,
                    transactionId: store.selectedMethod.session.payrails.paymentId,
                    amount: store.amount,
                },
                ...payrailsData,
                document: formFillValues[DOCUMENT_ID],
            });

            if (data?.topupWithCardPayrails.redirectUrl) {
                services.navigatorService.goOutside(
                    data.topupWithCardPayrails.redirectUrl,
                );

                return;
            }

            services.navigatorService.showFinalPage('success');
        });
    };

    const handleExistedCard = async (formFillValues: FormValues) => {
        return services.paymentProviderService.process(async (req) => {
            if (!store.selectedMethod.session?.payrails) {
                services.navigatorService.showFinalPage('error', {
                    message: getSessionErrorMessage(),
                });

                return;
            }

            const { data } = await req.topUpWithSavedPayrailsCard({
                session: {
                    sessionId: store.selectedMethod.session.payrails.session,
                    transactionId: store.selectedMethod.session.payrails.paymentId,
                    amount: store.amount,
                },
                instrumentID: store.selectedMethod.session.payrails.instrumentId,
                amount: parseInt(store.amount, 10),
                document: formFillValues[DOCUMENT_ID],
            });

            if (data?.topupWithSavedCardPayrails.redirectUrl) {
                services.navigatorService.goOutside(
                    data.topupWithSavedCardPayrails.redirectUrl,
                );

                return;
            }

            services.navigatorService.showFinalPage('success');
        });
    };

    const handleError = useCallback((error: string) => {
        services.navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    if (!initOptions) {
        return <CenterLoader loading />;
    }

    return store.selectedMethod.isInstrument ? (
        <PayrailsSavedCardView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            onSubmit={handleExistedCard}
            panValue={
                services.paymentMethodsViewService.formatPayrailsMethodName(
                    store.selectedMethod.methodName,
                )
            }
            formFields={store.selectedMethod.formFields}
            onError={handleError}
        />
    ) : (
        <PayrailsBankCardView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            initOptions={initOptions}
            logsService={services.logsService}
            configurationService={services.configurationService}
            formFields={store.selectedMethod.formFields}
            onSubmit={handleSubmitPayment}
            onError={handleError}
        />
    );
};

export default PayrailsBankCardController;
