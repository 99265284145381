import { variables } from '@indriver/yrel';
import styled, { css } from 'styled-components';

import { Text16Medium } from '../../../typography/text';

export const Wrapper = styled.div<{
    exitanimation: 1 | 0;
    exittimeoutms: number;
}>`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 16px;
  z-index: 1001;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  transition: opacity ${props => props.exittimeoutms}ms;

  ${props => props.exitanimation === 1? css`
    opacity: 0;
  `: css`
    opacity: 1;
    animation: fadeIn 250ms ease-in;
  `}
`;

export const Content = styled.div<{
    color: string;
}>`
  ${Text16Medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  color: ${variables['text-and-icon-oncolor']};
  background-color: ${props => props.color};
  padding: 12px 16px;
  min-height: 40px;

`;

export const Text = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

export const Icon = styled.div`
  margin-right: 14px;
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  ${Text16Medium};

  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8.5px 16px;
  border-radius: 12px;
`;
