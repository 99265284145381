import { Countries } from '@/lib/locale/countries';

import { getDocumentField } from '../fields';
import { FormSection } from '../index';
import { getPersonalDetailsSection } from '../sections';

export const getIziBankCardStruct = (
    countryId: Countries
): FormSection[] => {
    switch (countryId) {
        default:
            return [
                getPersonalDetailsSection([
                    getDocumentField(countryId),
                ]),
            ];
    }
};
