import { variables } from '@indriver/yrel';
import styled from 'styled-components';

export const Wrapper = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: ${variables['background-primary']};
`;
