import { gql } from '@apollo/client';

export type CreateCardDlocalMutation = {
    input: CreateCardDlocalInput,
}

export type CreateCardDlocalInput = NewDlocalCard | ExistedDlocalCard;

type NewDlocalCard = {
    token: string;
    cardHolderName: string;
    amount: number;
    email: string;
    document: string;
    state?: string;
    city?: string;
    zipCode?: string;
    street?: string;
    houseNumber?: string;
}

export type DlocalTokenizedCardData = Pick<NewDlocalCard, 'token' | 'cardHolderName'>;

type ExistedDlocalCard = {
    token?: string;
    amount: number;
    document: string;
}

export type CreateCardDlocalResponse = {
    createCardDlocal: {
        threeDSecureRedirectUrl?: string;
    }
}

export default gql(`
    mutation createCardDlocal($input: CreateCardDlocalInput!) {
        createCardDlocal(input: $input) {
            threeDSecureRedirectUrl
        }
    }
`);
