import i18n from 'i18next';

import { getCountryCode, getCountryLanguage } from '@/lib/locale';
import { Countries } from '@/lib/locale/countries';
import EventEmitter from '@/lib/subscriber';
import LogsService from '@/services/logs-service';

import { DlocalError } from './error';
import { onlyDlocalLanguages } from './locale';
import {
    DLocalInstance,
    DlocalFields,
    DlocalFieldType,
    getFieldOptions,
    DlocalSecureField,
    GetBinInformationResponse,
    GetLastFourResponse,
} from './options';

declare global {
    interface Window {
        dlocal: (uuid: string) => DLocalInstance;
    }
}

export type DlocalCardInfo = {
    token: string
    lastFour: string
    cardHolderName: string;
    binInfo: {
        bin: string
        country: string
    }
};

// https://docs.dlocal.com/reference/the-fields-object
class DlocalService extends EventEmitter<{
    'ready': void;
}> {
    private readonly dlocal: DLocalInstance;
    private readonly fields: DlocalFields;
    private fieldMap: Record<DlocalFieldType, DlocalSecureField | undefined>;
    private readyState: Record<DlocalFieldType, boolean>;
    private logsService: LogsService;

    constructor(
        country: Countries,
        instance: DLocalInstance,
        logsService: LogsService,
    ) {
        super();

        this.dlocal = instance;
        this.logsService = logsService;
        this.fields = this.dlocal.fields({
            country: getCountryCode(country),
            locale: onlyDlocalLanguages(getCountryLanguage(country) || i18n.language),
        });

        this.fieldMap = {
            [DlocalFieldType.PAN]: undefined,
            [DlocalFieldType.EXPIRATION]: undefined,
            [DlocalFieldType.CVV]: undefined,
            [DlocalFieldType.CVV_ONLY]: undefined,
        };

        this.readyState = {
            [DlocalFieldType.PAN]: false,
            [DlocalFieldType.EXPIRATION]: false,
            [DlocalFieldType.CVV]: false,
            [DlocalFieldType.CVV_ONLY]: false,
        };
    }

    public mountField(
        type: DlocalFieldType,
        element: HTMLElement,
        theme: string
    ): DlocalSecureField | null {
        if (this.fields === null) {
            return null;
        }

        if (!this.fieldMap[type]) {
            const field = this.fields.create(
                type,
                getFieldOptions(type, theme)
            );

            this.fieldMap[type] = field;

            field?.on('ready', () => {
                this.logsService.write(`dlocal ${type} mounted`);
                if (type === DlocalFieldType.CVV_ONLY) {
                    this.dispatch('ready', undefined);

                    return;
                }

                this.readyState[type] = true;

                if (
                    this.readyState[DlocalFieldType.PAN] === true &&
                    this.readyState[DlocalFieldType.EXPIRATION] === true &&
                    this.readyState[DlocalFieldType.CVV] === true
                ) {
                    this.dispatch('ready', undefined);
                }
            });
        }

        this.fieldMap[type]?.mount(element);

        return this.fieldMap[type] ?? null;
    }

    public unmountField(
        type: DlocalFieldType,
        element: HTMLElement,
        theme: string
    ): DlocalSecureField | null {
        if (this.fields === null) {
            return null;
        }

        if (!this.fieldMap[type]) {
            this.fieldMap[type] = this.fields.create(
                type,
                getFieldOptions(type, theme)
            );
        }

        this.fieldMap[type]?.mount(element);

        return this.fieldMap[type] ?? null;
    }

    private async getToken(cvv: DlocalSecureField, cardHolderName = ''): Promise<string> {
        try {
            const tokenData = await this.dlocal.createToken(cvv, {
                name: cardHolderName,
            });

            if (!tokenData.token) {
                throw new Error('Empty dlocal token data');
            }

            return tokenData.token;
        } catch (e) {
            if (DlocalError.isDlocalErrorDetails(e)) {
                throw new DlocalError(e);
            }

            throw e;
        }
    }

    private async getInfo(
        pan: DlocalSecureField
    ): Promise<GetBinInformationResponse & GetLastFourResponse> {
        try {
            const {
                bin,
                country,
                brand,
                type
            } = await this.dlocal.getBinInformation(pan);
            const { lastFour } = await this.dlocal.getLastFour(pan);

            return { bin, country, lastFour, brand, type };
        } catch (e) {
            if (DlocalError.isDlocalErrorDetails(e)) {
                throw new DlocalError(e);
            }

            throw e;
        }
    }

    public async getCvvTokenOnly() {
        const cvv = this.getSecureField(DlocalFieldType.CVV_ONLY);

        if (!cvv) {
            throw new Error(`${DlocalFieldType.CVV_ONLY} does not exist`);
        }

        try {
            return this.getToken(cvv);
        } catch (e) {
            if (DlocalError.isDlocalErrorDetails(e)) {
                throw new DlocalError(e);
            }

            throw e;
        }

        return null;
    }

    public async getCardInfo(cardHolderName: string): Promise<DlocalCardInfo> {
        const cvv = this.getSecureField(DlocalFieldType.CVV);

        if (!cvv) {
            throw new Error(`${DlocalFieldType.CVV} does not exist`);
        }

        const cardInfo: DlocalCardInfo = {
            token: '',
            lastFour: '',
            cardHolderName,
            binInfo: {
                bin: '',
                country: '',
            },
        };

        try {
            cardInfo.token = await this.getToken(cvv, cardHolderName);
        } catch (e) {
            if (DlocalError.isDlocalErrorDetails(e)) {
                throw new DlocalError(e);
            }

            throw e;
        }

        try {
            const pan = this.getSecureField(DlocalFieldType.PAN);
            if (!pan) {
                return cardInfo;
            }

            const { bin, country, lastFour } = await this.getInfo(pan);

            cardInfo.lastFour = lastFour;
            cardInfo.binInfo.bin = bin;
            cardInfo.binInfo.country = country;
        } catch (_) {
            /**
             * do nothing because bin info is optional and need for cashless only
             */
        }

        return cardInfo;
    }

    public getSecureField(type: DlocalFieldType) {
        return this.fieldMap[type];
    }
}

export default DlocalService;
