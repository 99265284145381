import { gql } from '@apollo/client';

export type TopupWithFawryCashMutation = {
    input: TopupWithFawryCashInput;
}

export type TopupWithFawryCashInput = {
    amount: number;
    email: string;
}

export type TopupWithFawryCashResponse = {
    topupWithFawryCash: {
        referenceNumber: string
        paymentExpiry: number;
    }
}

export default gql(`
    mutation TopupWithFawryCash($input: TopupWithFawryCashInput!){
      topupWithFawryCash(
        input: $input
      ) {
        referenceNumber
        paymentExpiry
      }
    }`
);
