import { ChevronLeft, ChevronRight } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { FC } from 'react';

import Resources from '../../../../resources';

type ChevronArrowProps = {
    size?: number;
}

export const ChevronArrow: FC<ChevronArrowProps> = ({ size = 24 }) => {
    return Resources.isRtl() ? (
        <ChevronLeft
            size={size}
            color={variables['text-and-icon-secondary']}
        />
    ) : (
        <ChevronRight
            size={size}
            color={variables['text-and-icon-secondary']}
        />
    );
};

export default ChevronArrow;
