import * as MIcons from '@indriver/mireska';
import { Button, RoundIcon } from '@indriver/nova';
import { variables } from '@indriver/yrel';

import * as UI from './ui';

type InformationScreenProps = {
    icon: (typeof MIcons)[keyof typeof MIcons];
    type: 'error' | 'in-progress' | 'neutral';
    heading: string;
    description?: string | React.ReactNode;
    errorStatus?: string;
    buttonText: string;
    onClickButton: VoidFunction;
};

export const InformationScreen = ({
    icon,
    type,
    heading,
    description,
    errorStatus,
    onClickButton,
    buttonText,
}: InformationScreenProps) => {
    const IconContent = icon;

    return (
        <UI.Wrapper>
            <UI.ContentWrapper>
                <RoundIcon
                    size='xxxl'
                    design={type === 'error' ? 'error' : 'neutral'}
                    style={{ marginBottom: variables['spacers-xl'] }}
                    content={<IconContent size={40} />}
                />

                {heading ? <UI.Title data-id='title'>{heading}</UI.Title> : null}

                {
                    description? (
                        <UI.TextWrapper>
                            <UI.Description
                                data-id='description'
                            >
                                {description}
                            </UI.Description>
                        </UI.TextWrapper>
                    ): null
                }

                {
                    errorStatus? (
                        <UI.TextWrapper>
                            <UI.ErrorStatusDescription
                                data-id='errorStatus'
                            >
                                {errorStatus}
                            </UI.ErrorStatusDescription>
                        </UI.TextWrapper>
                    ): null
                }
            </UI.ContentWrapper>

            <UI.BottomWrapper>
                {
                    onClickButton? (
                        <Button
                            data-id='submit'
                            size='l'
                            style={{ width: '100%' }}
                            onClick={onClickButton}
                        >
                            {buttonText}
                        </Button>
                    ): null
                }
            </UI.BottomWrapper>
        </UI.Wrapper>
    );
};
