import { PinOutline } from '@indriver/mireska';
import { Cell } from '@indriver/nova';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Resources from '../../resources';

type PaymentPointProps = {
    onClick: VoidFunction;
}

const PaymentPoints: FC<PaymentPointProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Cell
            data-id={Resources.test.howToTopUpForm.points}
            dir={Resources.getDir()}
            prefix={<PinOutline size={24} />}
            size='m'
            withChevron
            design='default'
            as='button'
            onClick={onClick}
        >
            <div>{t('features.PaymentPoints.paymentPointsButton')}</div>
        </Cell>
    );
};

export default PaymentPoints;
