import {
    FC,
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import { CreditCardForm } from '@/lib/forms/actions';
import { ValidationError } from '@/lib/forms/validation';
import InDriverSecureService from '@/services/indrive-secure-service';

import InDriverSecureField from '../in-driver-secure-field';

import CardHolder from '../../../shared/ui/domain/credit-card-field/card-holder';
import CreditCardOutline, { CreditCardViewType } from '../../../shared/ui/domain/credit-card-outline';
import CenterLoader from '../../../shared/ui/layout/center-loader';

export type InDriverCardData = {
    cardToken: string
};

type InDriverCardProps = {
    ref: Ref<CreditCardForm<InDriverCardData>>;
    indriveSecureService: InDriverSecureService;
}

const CARDNUMBER_ID = 'indriver-cardnumber';
const EXPIRATION_ID = 'indriver-exp';
const CVV_ID = 'indriver-cvv';

// eslint-disable-next-line @typescript-eslint/naming-convention
const InDriverCard: FC<InDriverCardProps> = forwardRef(({ indriveSecureService }, ref) => {
    const [cardNumberError, setCardNumberError] = useState<ValidationError>();
    const [expirationError, setExpirationError] = useState<ValidationError>();

    const [cvvError, setCvvError] = useState<ValidationError>();

    const [cardHolder, setCardHolder] = useState('');
    const [cardHolderError, setCardHolderError] = useState<ValidationError>();
    const cardholderRef = useRef<CardHolder>(null);

    useEffect(() => {
        indriveSecureService.createFields({
            cardnumberId: CARDNUMBER_ID,
            expirationId: EXPIRATION_ID,
            cvvId: CVV_ID,
            style: {
                fontSize: '16',
            }
        });

        indriveSecureService.addEventListener('cardnumber-input', ({ cardType }) => {
            console.log(cardType);
            setCardNumberError(undefined);
        });

        indriveSecureService.addEventListener('cardnumber-error', (error) => {
            setCardNumberError({
                message: error.type,
            });
        });

        indriveSecureService.addEventListener('expiration-input', () => {
            setExpirationError(undefined);
        });

        indriveSecureService.addEventListener('expiration-error', (error) => {
            setExpirationError({
                message: error.type,
            });
        });

        indriveSecureService.addEventListener('cvv-input', () => {
            setCvvError(undefined);
        });

        indriveSecureService.addEventListener('cvv-error', (error) => {
            setCvvError({
                message: error.type,
            });
        });
    }, [indriveSecureService]);

    useImperativeHandle(ref, () => ({
        validate: (): boolean => {
            const cardholderErr = cardholderRef.current?.validate();

            return Boolean(cardholderErr);
        },
        setCardholderError: (error) => {
            if (error?.message) {
                setCardHolderError(error);
            }
        },
        getData: async () => {
            return {
                cardToken: await indriveSecureService.createToken(cardHolder)
            };
        }
    }), [cardHolder, cardholderRef]);

    return (
        <CenterLoader
            overlay
            loading={false}
        >
            <CreditCardOutline
                cardNumberInput={
                    <InDriverSecureField
                        type={CARDNUMBER_ID}
                        onError={setCardNumberError}
                    />
                }
                cardNumberError={cardNumberError?.message}
                expirationInput={
                    {
                        type: CreditCardViewType.COMMON,
                        children: (
                            <InDriverSecureField
                                type={EXPIRATION_ID}
                                onError={setExpirationError}
                            />
                        ),
                        expirationError: expirationError?.message,
                    }
                }
                cvvInput={
                    <InDriverSecureField
                        type={CVV_ID}
                        onError={setCvvError}
                    />
                }
                cvvError={cvvError?.message}
                cardHolderInput={
                    <CardHolder
                        ref={cardholderRef}
                        onError={setCardHolderError}
                        onChange={setCardHolder}
                    />
                }
                cardHolderError={cardHolderError?.message}
            />
        </CenterLoader>
    );
});

export default InDriverCard;
