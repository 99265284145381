import { MessageI18n } from '../../i18n/payment-provider';
import { PaymentType } from '../../lib/payments/payments';
import { PaymentProviderName } from '../../lib/payments/providers';

export type PayrailsSession = {
    sessionId: string;
    transactionId: string;
    amount: string;
}

type InstrumentInfo = {
    last4: string;
    brand: string;
}

export type PayrailsInitOptions = {
    data: string;
    version: string;
}

// Todo надо подумать над списком подпровайдеров, его переводов и где хранить
export const PAYRAILS_TYPE_FAWRY = 'fawryPay';
export const PAYRAILS_TYPE_EASYPAISA = 'easypaisa';
export const PAYRAILS_TYPE_JAZZCASH = 'jazzCash';
export const PAYRAILS_TYPE_ALFA = 'alfa';
export const PAYRAILS_TYPE_KONNECT = 'konnect';
export const PAYRAILS_TYPE_GENERIC = 'genericRedirect';
export const PAYRAILS_TYPE_CARD = 'card';
export const PAYRAILS_TYPE_WAFA_CASH = 'wafaCashWallet';

export type PayrailsPaymentMethod = {
    category: PaymentType;
    paymentMethodCode: string;
    integrationType: string;
    removeIsPossible: boolean;
    instrumentID: string;
    instrument?: InstrumentInfo;
    enabled: boolean;
    message: MessageI18n;
    vendor: PaymentProviderName;
}
