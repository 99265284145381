import { Button } from '@indriver/nova';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Frame from '@/features/frame';
import CenterLoader from '@/shared/ui/layout/center-loader';

import * as UI from './ui';

import Resources from '../../../../../resources';

type PaymentPointsView = {
    src: string;
    onGoBack: VoidFunction;
}

const PaymentPointsView: FC<PaymentPointsView> = ({
    src,
    onGoBack
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const handleLoad = useCallback(() => {
        setLoading(false);
    }, []);

    return (
        <>
            <CenterLoader
                loading={loading}
                overlay
                flex
            >
                <UI.FrameWrapper>
                    <Frame
                        id={Resources.test.iframeMap}
                        src={src}
                        onLoad={handleLoad}
                    />
                </UI.FrameWrapper>
            </CenterLoader>

            <UI.GoBackWrapper>
                <Button
                    data-id={Resources.test.backButton}
                    design='secondary'
                    type='submit'
                    onClick={onGoBack}
                >
                    {t('shared.ui.domain.SubmitForm.goBackCaption')}
                </Button>
            </UI.GoBackWrapper>
        </>
    );
};

export default PaymentPointsView;
