import React, { ChangeEvent, RefObject } from 'react';

import Validation, { ValidationError, Validator } from '@/lib/forms/validation';

import { Input } from '../ui';

type CardHolderProps = {
    ref: RefObject<CardHolder>;
    validators?: Validator[];
    onError: (error: ValidationError) => void;
    onChange: (cardNumber: string) => void;
}

class CardHolder extends React.Component<CardHolderProps> {
    private inputRef: RefObject<HTMLInputElement>;
    constructor(props: CardHolderProps) {
        super(props);

        this.inputRef = React.createRef();
    }

    private handleValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value.replace(/\d/g, '').toUpperCase();
        this.props.onChange(value);

        const selectionStart = evt.currentTarget.selectionStart || 0;
        evt.currentTarget.value = value;
        evt.currentTarget.setSelectionRange(selectionStart, selectionStart);
    };

    private handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
        this.validate(evt.target.value.trim());
    };

    public validate = (value = this.inputRef.current?.value || ''): boolean => {
        const error = Validation.validate(
            value,
            [
                Validation.required,
                Validation.cardHolder,
                ...this.props.validators || [],
                Validation.stringSizeValidator(1, 100),
            ]
        );

        this.props.onError(error);

        return !error;
    };

    public focus = (): void => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    render() {
        return (
            <div>
                <Input
                    ref={this.inputRef}
                    autoComplete='cc-name'
                    onBlur={this.handleBlur}
                    onChange={this.handleValueChange}
                />
            </div>
        );
    }
}

export default CardHolder;
