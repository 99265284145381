import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import AuthorizationService from '@/services/authorization-service';
import ConfigurationService from '@/services/configuration-service';
import IPCService from '@/services/ipc-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';

import Singlepage from './app';
import NonAuthRoutes, { isNonAuth } from './app/non-auth-routes';
import S3Router from './app/s3-router';

type AppRouterProps = {
    ipcService: IPCService;
    logsService: LogsService;
    authorizationService: AuthorizationService;
    configurationService: ConfigurationService;
}

const AppRouter: FC<AppRouterProps> = ({
    ipcService,
    logsService,
    authorizationService,
    configurationService
}) => {
    const [isNonAuthRoute, setIsNonAuthRoute] = useState(isNonAuth());
    const location = useLocation();

    const [navigatorService] = useState(new NavigatorService(
        useNavigate(),
        logsService,
        configurationService,
        authorizationService
    ));

    useEffect(() => {
        const signEl = window?.document.getElementById('body-sign');
        signEl?.remove();

        setIsNonAuthRoute(isNonAuth());
    }, [location.pathname]);

    /** @deprecated */
    // todo https://indriver.atlassian.net/browse/PRC-1904
    const notSPARoute = navigatorService.getNotSPARoute();
    /** @deprecated */
    if (notSPARoute) {
        return (
            <S3Router
                route={notSPARoute}
                navigatorService={navigatorService}
                ipcService={ipcService}
            />
        );
    }

    if (isNonAuthRoute) {
        return (
            <NonAuthRoutes
                navigatorService={navigatorService}
                ipcService={ipcService}
                logsService={logsService}
                configurationService={configurationService}
            />
        );
    }

    return (
        <Singlepage
            authorizationService={authorizationService}
            navigatorService={navigatorService}
            ipcService={ipcService}
            logsService={logsService}
            configurationService={configurationService}
        />
    );
};

export default AppRouter;
