import { FC, useEffect } from 'react';

import IPCService from '@/services/ipc-service';
import NavigatorService, { AppRoute } from '@/services/navigator-service';

import InternalErrorPage from './pages/internal-error-page';
import SuccessPage from './pages/success-page';

type S3RouterProps = {
    route: AppRoute;
    ipcService: IPCService;
    navigatorService: NavigatorService;
};

/** @deprecated */
const S3Router: FC<S3RouterProps> = (props) => {
    useEffect(() => {
        props.navigatorService.clearUrlParams();
    }, []);

    if (props.route === NavigatorService.AppRoutes.SUCCESS_PAGE) {
        return <SuccessPage {...props} />;
    }

    return <InternalErrorPage {...props} />;
};

export default S3Router;
