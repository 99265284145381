namespace Global {
    export namespace LocalStorage {
        export const getItem: typeof window.localStorage.getItem = (...args) => {
            if (window?.localStorage?.getItem) {
                return window.localStorage.getItem(...args);
            }

            return null;
        };

        export const setItem: typeof window.localStorage.setItem = (...args) => {
            if (window?.localStorage?.setItem) {
                return window.localStorage.setItem(...args);
            }

            return undefined;
        };

        export const removeItem: typeof window.localStorage.removeItem = (...args) => {
            if (window?.localStorage?.removeItem) {
                return window.localStorage.removeItem(...args);
            }

            return undefined;
        };
    }
}

export default Global;
