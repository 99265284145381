import styled from 'styled-components';

export const FrameWrapper = styled.div`
    display: flex;
    flex: 1;
`;

export const GoBackWrapper = styled.div`
    padding: 16px;

    button {
        height: 56px;
        width: 100%;
    }
`;
