import { FC } from 'react';

import { CardData } from '@/features/credit-card/credit-card-native';
import {
    PaymentProviderGQLFormError
} from '@/lib/errors/payment-provider/graphql-errors';
import { FormType, FormValues, getBankCardStruct } from '@/lib/payments/forms';
import Credorax from '@/lib/payments/integrations/credorax';
import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

import CredoraxBankCardView from './credorax';
import CredoraxSavedCardView from './credorax/saved-card';

const CredoraxBankCardController: FC<PaymentFormData> = ({
    store,
    paymentInfo,
    services,
}) => {
    const handlePayment = async <T extends DataType['TopupWithCredoraxCardInput']>(
        input: T
    ): Promise<PaymentProviderGQLFormError> => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Credorax,
            payment_option: PaymentType.BANK_CARD,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            await req.topUpWithCredorax(input);
            services.navigatorService.showFinalPage('success');
        });
    };

    const handleSavedTopUpClick = () => {
        services.analyticService.event('processing.topup_saved_card_pay.click', undefined);
    };

    const handleExistedCard = (
        formFillValues: FormValues
    ): Promise<PaymentProviderGQLFormError> => {
        const [firstName, lastName] = formFillValues.cardHolder.split(' ');

        return handlePayment({
            amount: parseInt(store.amount, 10),
            firstName,
            lastName,
            /**
             * Backend asking send below empty strings because it is required fields
             */
            pKeyResponse: '',
            email: '',
            zipCode: '',
            city: '',
            street: '',
            houseNumber: '',
        });
    };

    const handleSubmitPayment = async (
        formFillValues: FormValues<CardData>
    ): Promise<PaymentProviderGQLFormError> => {
        const session = store.selectedMethod.session?.credorax;
        const pKeyResponse = await Credorax.getPKey(formFillValues, session?.merchantID??'', session?.staticKey??'');

        const {
            cardHolder,
            email,
            zipCode,
            city,
            street,
            houseNumber,
        } = formFillValues;

        const [firstName, lastName] = cardHolder.split(' ');

        return handlePayment({
            amount: parseInt(store.amount, 10),
            pKeyResponse: JSON.stringify(pKeyResponse),
            email: email,
            zipCode: zipCode,
            city: city,
            street: street,
            houseNumber: houseNumber,
            firstName,
            lastName,
        });
    };

    /*TODO https://indriver.atlassian.net/browse/PRC-2208*/
    return store.selectedMethod.savedInstruments?.bankCard? (
        <CredoraxSavedCardView
            country={paymentInfo.country}
            panValue={store.selectedMethod.savedInstruments?.bankCard[0].last4}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getBankCardStruct(
                    PaymentProviderName.Credorax,
                    paymentInfo.country,
                    FormType.EXISTED,
                    { short: store.selectedMethod.isCustomerSaved || false }
                )
            }
            onTopUpClick={handleSavedTopUpClick}
            onSubmit={handleExistedCard}
        />
    ) : (
        <CredoraxBankCardView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getBankCardStruct(
                    PaymentProviderName.Credorax,
                    paymentInfo.country,
                    FormType.NEW,
                    { short: store.selectedMethod.isCustomerSaved || false }
                )
            }
            onSubmit={handleSubmitPayment}
        />
    );
};

export default CredoraxBankCardController;
