export const formatExpDate = (expirationDate: Date) => {
    // todo pass Locale for better internationalization
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });

    const formattedDateParts = dateFormatter.formatToParts(expirationDate);

    return formattedDateParts.map(({ type, value }) => {
        switch (type) {
            case 'literal':
                return value;
            default:
                return value;
        }
    }).join('');
};
