import { FC } from 'react';

import { ValidationError } from '@/lib/forms/validation';

import * as UI from './ui';

type InDriverSecureFieldProps = {
    type: string;
    onError: (error?: ValidationError) => void;
    onEmpty?: VoidFunction;
}

const InDriverSecureField: FC<InDriverSecureFieldProps> = ({
    type,
    // onError,
    // onEmpty = Function.prototype,
}) => {

    return <UI.Wrapper id={type} />;
};

export default InDriverSecureField;
