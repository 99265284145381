import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';

type ImePayWalletProps = {
    referenceNumber: string;
    redirectUrl?: string;
    onRedirect: (redirectUrl: string) => void;
    onCopy: (paycode: string, title: string) => void;
}

const ImePayWallet: React.FC<ImePayWalletProps> = ({
    referenceNumber,
    redirectUrl,
    onRedirect,
    onCopy,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(() => {
        if (!redirectUrl) {
            return;
        }

        setLoading(true);
        onRedirect(redirectUrl);
    }, [redirectUrl]);

    return (
        <PaycodeView
            title={t('views.PaymentMethods.Wallet.ImePayTopUpSteps.referenceNumberLabel')}
            paycode={referenceNumber}
            onClick={
                () => onCopy(referenceNumber, t('views.PaymentMethods.Wallet.ImePayTopUpSteps.referenceCopiedLabel'))
            }
        >
            <HowToTopUpInfo
                steps={
                    [
                        t('views.PaymentMethods.Wallet.ImePayTopUpSteps.stepOne'),
                        t('views.PaymentMethods.Wallet.ImePayTopUpSteps.stepTwo'),
                        t('views.PaymentMethods.Wallet.ImePayTopUpSteps.stepThree'),
                    ]
                }
                submitCaption={t('views.PaymentMethods.Wallet.ImePayTopUpSteps.submitCapton')}
                loading={loading}
                onSubmit={redirectUrl ? handleSubmit : undefined}
            />
        </PaycodeView>
    );
};

export default ImePayWallet;

