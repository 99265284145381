import styled, { css } from 'styled-components';

import { Text12Medium } from '@/shared/typography/text';

export const Wrapper = styled.div<{ $hide: boolean}>`
    ${Text12Medium};
  
    position: fixed;
    left: 12px;
    bottom: 12px;
    background: rgba(255, 255, 255, .5);
    padding: 12px;
    border-radius: 12px;
    opacity: .5;
    box-sizing: content-box;
    z-index: 5;
  
    ${props => props.$hide && css`
      display: none;
    `}
`;

export const Line = styled.div`
    padding-top: 4px;
    display: flex;
`;

export const Close = styled.div`
    margin-inline-start: 12px;
    
    :hover {
        color: gray;
    }
`;

export const Button = styled.button<{ $enabled?: 1|0 }>`
    ${props => props.$enabled === 1 && css`
      background: #4087e1;
      color: #ffffff;
    `}
    padding: 0;
`;

export const Checkbox = styled.input`
`;

export const DrowndownWrapper = styled.div`
    position: relative;
`;

export const Items = styled.ul<{ $hide: boolean }>`
  ${props => props.$hide && css`
    display: none;
  `}

  padding: 2px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  position: absolute;
  bottom: 20px;
  left: 0;
  background: #ffffff;
  width: 100%;
  
  :hover {
    background: #88da09;;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  :hover {
    color: lightgray;
  }
`;

export const NamespaceInput = styled.input<{ $error: boolean }>`
  font-size: 12px;
  width: 100px;
  
  ${props => props.$error && css`
    border: 2px solid red;
  `}
`;

export const LogWrapper = styled.div`
  word-break: break-all;
`;

export const Log = styled.p``;
