import { Button, Loader } from '@indriver/nova';
import { DesignButton } from '@indriver/nova';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ModalSheet from '../../core/modal-sheet';

type ConfirmModalProps = {
    loading?: boolean;
    open: boolean;
    title?: string;
    design?: [DesignButton, DesignButton];
    confirmCaption?: string;
    confirmDataId?: string;
    cancelCaption?: string;
    cancelDataId?: string;
    onSubmit: VoidFunction;
    onCancel: VoidFunction;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
    loading,
    open,
    title,
    design,
    confirmCaption,
    confirmDataId,
    cancelCaption,
    cancelDataId,
    onSubmit,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [confirmDesign, cancelDesign] = design || [];

    return (
        <ModalSheet
            open={open}
            title={title || t('shared.ui.domain.ConfirmModal.defaultTitle')}
            footer={
                <>
                    <Button
                        size='l'
                        data-id={confirmDataId}
                        loading={loading}
                        disabled={loading}
                        design={confirmDesign || 'secondary'}
                        onClick={onSubmit}
                    >
                        {
                            loading ? (
                                <Loader
                                    size='s'
                                    design='primary'
                                />
                            ) : confirmCaption || t('shared.ui.domain.ConfirmModal.confirmCaption')
                        }
                    </Button>

                    <Button
                        size='l'
                        data-id={cancelDataId}
                        design={cancelDesign}
                        disabled={loading}
                        onClick={onCancel}
                    >
                        {cancelCaption || t('shared.ui.domain.ConfirmModal.cancelCaption')}
                    </Button>
                </>
            }
        />
    );
};

export default ConfirmModal;
