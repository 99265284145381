import { gql } from '@apollo/client';

import { PayrailsInitOptions } from './index';

export type PayrailsInitOptionsInput = {
    instrumentID: string;
}

export type PayrailsInitOptionsResponse = {
    payrailsInitOptions: PayrailsInitOptions;
}

export default gql(`
    query payrailsInitOptions ($input: PayrailsInitOptionsInput!){
            payrailsInitOptions(
                input: $input
            ) {
                data
                version
            }
        }
`);
