import { variables } from '@indriver/yrel';
import styled, { css } from 'styled-components';

import { Text14Medium, Text14Regular } from '../../../typography/text';

export const Wrapper = styled.div`
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const Label = styled.label`
    ${Text14Regular};

    margin-bottom: 8px;
    display: flex;
    align-items: center;
    color: ${variables['text-and-icon-secondary']};
`;

export const InputWrapper = styled.div<{ $error: boolean }>`
    position: relative;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: ${variables['background-secondary']};
    box-sizing: border-box;

    border: 1px solid var(--dtkadak-0);
    ${({ $error }) => $error  && css`
        border: 1px solid ${variables['extensions-border-error']};
    `}
`;

export const Error = styled.div`
    ${Text14Medium};
  
    color: ${variables['extensions-text-and-icon-error']};
    margin-top: 4px;
`;
