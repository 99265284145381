import { HintFilled } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { FC } from 'react';

import * as UI from './ui';

type SectionFormProps = {
    id?: string;
    title: string;
    children: JSX.Element | JSX.Element[];
    onClick?: VoidFunction;
}

const SectionForm: FC<SectionFormProps> = ({ id, title, children, onClick }) => {
    return (
        <>
            <UI.Heading
                data-id={id ? id : undefined}
                onClick={onClick}
            >
                <UI.Title>{title}</UI.Title>

                {
                    onClick? (
                        <UI.Icon>
                            <HintFilled
                                size={20}
                                color={variables['text-and-icon-disabled']}
                            />
                        </UI.Icon>
                    ): null
                }
            </UI.Heading>

            {children}
        </>
    );
};

export default SectionForm;
