import { variables } from '@indriver/yrel';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    max-height: 18px;
    justify-content: space-between;
`;

export const PayrailsInput = styled.div`
    max-height: 18px;
    overflow: hidden;
    width: 100%;
`;

export const PayrailsMonthInput = styled.div`
    padding: 0;
    overflow: hidden;
    max-height: 18px;
    width: 100%;
`;
export const PayrailsYearInput = styled.div`
    margin-left: 5px;
    overflow: hidden;
    max-height: 18px;
    width: 100%;
`;

export const getSdkInputStyles = () => ({
    base: {
        color: getComputedStyle(document.querySelector('.theme-dark') || document.documentElement)
            .getPropertyValue(variables['text-and-icon-primary']
                .replace('var(', '')
                .replace(')', '')
            ),
        border: 'none',
        fontSize: '16px',
    },
});
