import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

export const errorLink = (on401: VoidFunction) => onError(({
    networkError,
}) => {
    const isUnauthorized = networkError?.message?.includes('401');
    if (isUnauthorized) {
        on401();
    }

    return;
});

export const authLink = (
    auth: `Bearer ${string}`,
    shieldId: string,
    isDev: boolean,
) => {
    const setHeaders: Record<string, string> = {
        ['shield-session-id']: shieldId,
        authorization: auth
    };

    if (isDev) {
        setHeaders['X-Real-Ip'] = '127.0.0.1';
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    return setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                ...setHeaders,
            },
        };
    });
};

export const createApolloClient = async (
    auth: `Bearer ${string}`,
    shieldId: string,
    isDev: boolean,
    uri: string,
) => {
    return new ApolloClient({
        link: ApolloLink.from([
            await authLink(auth, shieldId, isDev),
            createUploadLink({ uri }),
        ]),
        cache: new InMemoryCache(),
    });
};
