import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Text16Medium } from '@/shared/typography/text';

export const ImageContainer = styled.div`
  margin-bottom: ${variables['spacers-s']};
  border-radius: 12px;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  padding: 0 ${variables['spacers-m']};
`;

export const Image = styled.img`
  width: 100%;
  margin-top: -2px;
`;

export const Text = styled.div`
  ${Text16Medium};

  display: flex;
  justify-content: center;
`;

export const InfoContent = styled.div`
  padding: 0 16px 26px;
  box-sizing: border-box;

  span {
    ${Text16Medium};
  }
`;
