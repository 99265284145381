import { gql } from '@apollo/client';

import { WooppaySession } from './index';

export type CreateWooppaySessionMutation = {
    input: CreateWooppaySessionInput,
}

export type CreateWooppaySessionInput = {
    amount: number;
    phone: string;
}

export type CreateWooppaySessionResponse = {
    createWooppaySession: WooppaySession;
}

export default gql(`
    mutation createWooppaySession($input: CreateWooppaySessionInput!) {
      createWooppaySession(input: $input) {
        sessionId
        amount
        phone
      }
    }
`);
