import { Countries } from '@/lib/locale/countries';

import { getPhoneField } from '../fields';
import { FormSection } from '../index';
import { getPersonalDetailsSection } from '../sections';

export const getOneVisionBankCardStruct = (
    countryId: Countries
): FormSection[] => {
    switch (countryId) {
        default:
            return [
                getPersonalDetailsSection([
                    getPhoneField({
                        mask: '+{000} 000 000 000',
                    }),
                ]),
            ];
    }
};
