import { FC, useCallback, useEffect, useState } from 'react';

import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { Countries } from '@/lib/locale/countries';
import { FormType, FormValues, getBankCardStruct } from '@/lib/payments/forms';
import {
    PaymentType
} from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import DlocalService from '@/services/dlocal-service';
import { DataType } from '@/services/payment-provider-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

import DLocalBankCardView from './dlocal';
import DlocalSavedCardView from './dlocal/saved-card';

import {
    DlocalTokenizedCardData
} from '../../../../../../graphql/bank-card/dlocal/create-card-dlocal';

const DlocalBankCardController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { country } = paymentInfo;

    const [dlocalService, setDlocalService] = useState<DlocalService|undefined>();

    useEffect(() => {
        const sdk = new SDK(services.logsService);
        sdk.get(PaymentProviderName.Dlocal)
            .then((sdkRef) => {
                if (!store.selectedMethod.session?.dlocal) {
                    services.navigatorService.showFinalPage('error', {
                        message: getSessionErrorMessage(),
                    });

                    return;
                }

                const dlocal = new DlocalService(
                    paymentInfo.country,
                    sdkRef(store.selectedMethod.session.dlocal.apiKey),
                    services.logsService,
                );
                setDlocalService(dlocal);
            })
            .catch(event => {
                services.navigatorService.showFinalPage('error', {
                    message: event
                });
            });
    }, []);

    const handlePayment = async <T extends DataType['CreateCardDlocalInput']>(
        input: T
    ): Promise<PaymentProviderGQLFormError> => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Dlocal,
            payment_option: PaymentType.BANK_CARD,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.createCardDlocal(input);

            if (data?.createCardDlocal.threeDSecureRedirectUrl === '') {
                await services.navigatorService.showFinalPage('success');

                return;
            }

            if (data?.createCardDlocal.threeDSecureRedirectUrl) {
                services.navigatorService.goOutside(data?.createCardDlocal?.threeDSecureRedirectUrl);
            }
        });
    };

    const handleSavedTopUpClick = () => {
        services.analyticService.event('processing.topup_saved_card_pay.click', undefined);
    };

    const handleExistedCard = (
        formFillValues: FormValues,
    ): Promise<PaymentProviderGQLFormError> => {
        const data: DataType['CreateCardDlocalInput'] = {
            amount: parseInt(store.amount, 10),
            document: formFillValues.document,
            email: formFillValues.email,
        };

        // todo https://indriver.atlassian.net/browse/PRC-1375
        if (country === Countries.India) {
            data.token =  formFillValues.token;
        }

        return handlePayment(data);
    };

    const handleSubmitPayment = async (
        formFillValues: FormValues<DlocalTokenizedCardData>,
    ): Promise<PaymentProviderGQLFormError> => {
        const data: DataType['CreateCardDlocalInput'] = {
            token: formFillValues.token,
            amount: parseInt(store.amount, 10),
            cardHolderName: formFillValues.cardHolderName,
            email: formFillValues.email,
            document: formFillValues.document,
        };

        // todo https://indriver.atlassian.net/browse/PRC-1375
        if (country === Countries.India || country === Countries.SouthAfrica) {
            data.state = formFillValues.state;
            data.city = formFillValues.city;
            data.zipCode = formFillValues.zipCode;
            data.street = formFillValues.street;
            data.houseNumber = formFillValues.houseNumber;
        }

        return handlePayment(data);
    };

    const handleError = useCallback((error: string) => {
        services.navigatorService.showFinalPage('error', {
            message: error,
        });
    }, []);

    if (!dlocalService) {
        return <CenterLoader loading />;
    }

    {/*TODO https://indriver.atlassian.net/browse/PRC-2208*/}

    return store.selectedMethod.savedInstruments?.bankCard ? (
        <DlocalSavedCardView
            dlocalService={dlocalService}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            panValue={store.selectedMethod.savedInstruments?.bankCard[0].last4}
            cvvOnly={country === Countries.India}
            formFields={
                getBankCardStruct(
                    PaymentProviderName.Dlocal,
                    country,
                    FormType.EXISTED,
                    { short: store.selectedMethod.isCustomerSaved || false }
                )
            }
            onTopUpClick={handleSavedTopUpClick}
            onSubmit={handleExistedCard}
            onError={handleError}
        />
    ) : (
        <DLocalBankCardView
            dlocalService={dlocalService}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getBankCardStruct(
                    PaymentProviderName.Dlocal,
                    country,
                    FormType.NEW,
                    { short: store.selectedMethod.isCustomerSaved || false }
                )
            }
            onSubmit={handleSubmitPayment}
            onError={handleError}
        />
    );
};

export default DlocalBankCardController;
