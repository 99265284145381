import { Component } from 'react';

import { getCurrencyCode } from '@/lib/locale';
import { PaymentMethod } from '@/services/payment-info-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { AppData } from '../../index';

import AutoTopupSettingsForm from './auto-topup-settings-form';
import PaymentForm from './payment-form';
import PaymentInfo from './payment-info';
import PaymentPointsView from './payment-points-view';

export type MainStore = {
    store: {
        amount: string;
        selectedMethod: PaymentMethod;
    };
    onHideGoBack: VoidFunction;
}

type MainState = {
    loading: boolean;
    amount: string;
    selectedMethod: PaymentMethod | null;
    selectedMethodIndex: number | null;
    showAutoTopupView: boolean;
    mapSrcToShow?: string;
    hideGoBack: boolean;
}

class Main extends Component<AppData, MainState> {
    constructor(props: AppData) {
        super(props);

        this.state = {
            loading: false,
            amount: '',
            selectedMethodIndex: this.props.paymentInfo.defaultMethod,
            selectedMethod: null,
            showAutoTopupView: false,
            mapSrcToShow: undefined,
            hideGoBack: false,
        };

        // todo describe better what that flow is
        this.props.services.paymentInfoService.addEventListener('onPaymentInfoUpdate', () => {
            this.setState({
                selectedMethod: null,
            });
        });
    }

    private handleSelectedMethodIndexChange = (selectedMethodIndex: number | null) => {
        this.setState({ selectedMethodIndex });
    };

    /**
     * Temporary hack with goBack button while native nav of mobile is not ready
     * Please see ticket https://indriver.atlassian.net/browse/PL-6344
     */
    private handleGoBack = () => {
        this.setState({
            mapSrcToShow: '',
            selectedMethod: null,
            showAutoTopupView: false,
            hideGoBack: false,
        });
    };

    /**
     * Temporary hack with goBack button while native nav of mobile is not ready
     * Please see ticket https://indriver.atlassian.net/browse/PL-6344
     */
    private handleHideGoBack = () => {
        this.setState({
            hideGoBack: true,
        });
    };

    private handlePaymentMethod = (amount: string, selectedMethod: PaymentMethod) => {
        const { analyticService, paymentMethodsViewService } = this.props.services;

        analyticService.event('processing.topup_payment_option.click', {
            payment_option: selectedMethod.paymentType,
            provider: selectedMethod.providerName,
        });

        if (paymentMethodsViewService.isInFrameMethod(selectedMethod)) {
            return;
        }

        if (selectedMethod.isFormless) {
            this.setState({ loading: true });
            this.props.services.topupService.topUp(amount, selectedMethod, {});

            return;
        }

        // that means redirect to payment form
        this.setState({
            amount,
            selectedMethod,
        });
    };

    private handleAutoTopupShow = () => {
        this.props.services.analyticService.event('processing.topup_pay_auto.click', undefined);

        this.setState({ showAutoTopupView: true });
    };

    private handlePaymentPointsClick = (mapSrc: string) => {
        this.setState({ mapSrcToShow: mapSrc });
    };

    public render() {
        const { amount, selectedMethod } = this.state;
        const { paymentInfo, services } = this.props;

        if (this.state.loading) {
            return <CenterLoader loading />;
        }

        if (this.state.showAutoTopupView) {
            return (
                <AutoTopupSettingsForm
                    autoTopupInfo={paymentInfo.autoTopup}
                    currencyCode={getCurrencyCode(paymentInfo.country)}
                    services={services}
                    onGoBack={this.handleGoBack}
                />
            );
        }

        if (this.state.mapSrcToShow) {
            return (
                <PaymentPointsView
                    src={this.state.mapSrcToShow}
                    onGoBack={this.handleGoBack}
                />
            );
        }

        if (selectedMethod) {
            const store: MainStore = {
                store: {
                    amount,
                    selectedMethod: selectedMethod
                },
                onHideGoBack: this.handleHideGoBack,
            };

            return (
                <PaymentForm
                    method={selectedMethod}
                    hideGoBack={this.state.hideGoBack}
                    onGoBack={this.handleGoBack}
                    {...this.props}
                    {...store}
                />
            );
        }

        return (
            <PaymentInfo
                selectedMethodIndex={this.state.selectedMethodIndex}
                country={paymentInfo.country}
                currencyCode={getCurrencyCode(paymentInfo.country)}
                paymentMethods={paymentInfo.paymentMethods}
                autoTopupInfo={paymentInfo.autoTopup}
                suggestedAmounts={paymentInfo.suggestedAmounts}
                services={services}
                onSelectedMethodIndexChange={this.handleSelectedMethodIndexChange}
                onPaymentMethod={this.handlePaymentMethod}
                onAutoTopupClick={this.handleAutoTopupShow}
                onPaymentPointsClick={this.handlePaymentPointsClick}
            />
        );
    }
}

export default Main;
