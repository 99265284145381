import { CardType } from '@/lib/payments/credit-card';
import { getCardType } from '@/lib/payments/credit-card/cardtypes';
import { getCardMask } from '@/lib/payments/credit-card/format';

export const formatDate = (date: string): string => {
    let value = date.replace(/\D/g, '');

    if (value.length >= 2) {
        value = value.substring(0, 2);
    }

    return value;
};

const formatCardNumber = (cardNumber: string): string => {
    const value = cardNumber.replace(/\D/g, '');
    const mask = getCardMask(value);

    if (!mask) {
        return value;
    }

    const blocks: string[][] = mask.map(() => []);
    let current = 0;

    value.split('').forEach((symbol) => {
        if (blocks[current].length === mask[current]) {
            current++;
        }

        if (current < mask.length) {
            blocks[current].push(symbol);
        }
    });

    return blocks
        .filter((block) => block.length)
        .map((block) => block.join(''))
        .join(' ');
};

export const getPanLen = (cardNumber: string): number => {
    const isMaestro = getCardType(cardNumber) === CardType.MAESTRO;
    const isMIR = getCardType(cardNumber) === CardType.MIR;

    return isMaestro || isMIR ? 23 : 19;
};

export const formatCardNumberAndMoveCaret = (input: HTMLInputElement): void => {
    const selectionStart = input.selectionStart || 0;
    const valueTillSelectionStart = input.value.substring(0, selectionStart);
    const spacesMatch = valueTillSelectionStart.match(/\s/g);
    const amountOfSpaces = spacesMatch ? spacesMatch.length : 0;
    const digitCaretPosition = selectionStart - amountOfSpaces;

    input.value = formatCardNumber(input.value);

    // set new caret position to non-space symbol
    let newDigitCaretPosition = 0;
    let i;
    let l;
    for (i = 0, l = input.value.length; i < l; i++) {
        if (newDigitCaretPosition === digitCaretPosition) {
            break;
        }

        if (input.value[i] === ' ') {
            continue;
        }

        newDigitCaretPosition++;
    }

    input.setSelectionRange(i, i);
};

export const formatCVC = (cvc: string, isAmex: boolean): string => {
    const length = isAmex ? 4 : 3;
    let value = cvc.replace(/\D/g, '');

    if (value.length >= length) {
        value = value.substring(0, length);
    }

    return value;
};

export const isCVCFilled = (cvc: string, isAmex: boolean): boolean => {
    const length = isAmex ? 4 : 3;

    return cvc.replace(/\D/g, '').length === length;
};
