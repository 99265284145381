import styled from 'styled-components';

import { Header20Bold } from '@/shared/typography/text';

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const BottomHeaderBold= styled.div`
    ${Header20Bold};
`;
