import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
`;

export const GoBackWrapper = styled.div`
  padding: 0 16px 16px;

  button {
    height: 56px;
    width: 100%;
  }
`;
