class Price {
    private amount: number | string | undefined;
    private currency: string;

    constructor(amount: number | string | undefined, currency: string) {
        this.amount = amount;
        this.currency = currency;
    }

    private makeHumanReadableAmount = (value?: number | string): string => {
        if (!value) {
            return '0';
        }

        return value.toLocaleString('en-US');
    };

    public format() {
        const readableAmount = this.makeHumanReadableAmount(this.amount);

        return `${this.currency}\u00A0${readableAmount}`;
    }
}

export default Price;
