import { variables } from '@indriver/yrel';
import styled from 'styled-components';

import { Header24Bold } from '@/shared/typography/text';
import { Text16Medium } from '@/shared/typography/text';

export const CheckoutBlock = styled.div`
  background: ${variables['background-secondary']};
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  & svg > path {
    fill: ${variables['text-and-icon-primary']};
  }
`;

export const Paycode = styled.div`
  ${Header24Bold};
`;

export const Description = styled.div`
  ${Text16Medium};
  color: ${variables['text-and-icon-secondary']};
`;

export const PromotionDescription = styled(Description)`
  & span{
    font-weight: 500;
    color: ${variables['text-and-icon-primary']};
    text-decoration: underline;
  } 
`;

export const PaycodeBlock = styled.div`
  width: 100%;
`;
