import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HowToTopUpInfo from '@/features/how-to-top-up-info';
import PaycodeView from '@/features/paycode-view';


type IziCashProps = {
    voucherReference: string;
    onCopy: (paycode: string, title: string) => void;
}

const IziCash: FC<IziCashProps> = ({ voucherReference, onCopy }) => {
    const { t } = useTranslation();

    const handleCopyPaycode = useCallback(() => {
        onCopy(voucherReference, t('views.PaymentMethods.Cash.Izi.voucherCopiedLabel'));
    }, [voucherReference, onCopy]);

    return (
        <PaycodeView
            title={t('views.PaymentMethods.Cash.Izi.referenceTitle')}
            paycode={voucherReference}
            onClick={handleCopyPaycode}
        >
            <HowToTopUpInfo
                alertText={t('views.PaymentMethods.Cash.Izi.instructionStep4')}
                steps={
                    [
                        t('views.PaymentMethods.Cash.Izi.instructionStep1'),
                        t('views.PaymentMethods.Cash.Izi.instructionStep2'),
                        t('views.PaymentMethods.Cash.Izi.instructionStep3'),
                    ]
                }
            />
        </PaycodeView>
    );
};

export default IziCash;
