import { FC, useEffect } from 'react';

import {
    PaymentProviderGQLFormError
} from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { getYunoCreditCardStruct } from '@/lib/payments/forms/bank-card/yuno';
import { PaymentType } from '@/lib/payments/payments';
import YunoService from '@/services/yuno-service';

import { PaymentFormData } from '../index';

import YunoCheckoutView from '../../../../../routing/pages/main-page/payment-form/yuno-checkout/yuno';

const YunoCheckoutController: FC<PaymentFormData> = ({
    paymentInfo,
    services,
    store,
    onHideGoBack
}) => {
    const cardFlow = store.selectedMethod.paymentType === PaymentType.BANK_CARD;

    const handlePayment = async (
        yuno: YunoService,
        oneTimeToken: string,
        email?: string
    ): Promise<PaymentProviderGQLFormError> => {
        return services.paymentProviderService.process(async (req) => {
            if (!store.selectedMethod.session?.yuno) {
                return;
            }

            const { data } = await req.topUpWithYuno({
                methodName: store.selectedMethod.session.yuno.methodName,
                vaultedToken: store.selectedMethod.session.yuno.vaultedToken,
                session: store.selectedMethod.session.yuno.sessionToken,
                paymentMethodCode: store.selectedMethod.paymentMethodCode || '',
                amount: parseInt(store.amount, 10),
                email,
                oneTimeToken
            });

            if (data?.topupWithYuno.sdkActionIsRequired) {
                yuno.continuePayment();

                return;
            }

            services.navigatorService.showFinalPage('success');
        });
    };

    useEffect(() => {
        if (!store.selectedMethod.session?.yuno) {
            services.navigatorService.showFinalPage('error', {
                message: getSessionErrorMessage()
            });

            onHideGoBack();

            return;
        }
    }, []);

    return (
        <YunoCheckoutView
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            handlePayment={handlePayment}
            navigatorService={services.navigatorService}
            logsService={services.logsService}
            session={store.selectedMethod.session?.yuno}
            formFields={cardFlow ? getYunoCreditCardStruct() : []}
        />
    );
};

export default YunoCheckoutController;
