import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import YunoCheckout, { YunoCheckoutFeature } from '@/features/yuno-checkout';
import { PaymentProviderGQLFormError } from '@/lib/errors/payment-provider/graphql-errors';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import { PaymentInfo, YunoSession } from '@/services/payment-info-service';
import YunoService from '@/services/yuno-service';
import CenterLoader from '@/shared/ui/layout/center-loader';

type YunoCheckoutControllerProps = {
    navigatorService: NavigatorService;
    handlePayment: (
        yuno: YunoService,
        oneTimeToken: string,
        email?: string
    ) => Promise<PaymentProviderGQLFormError>;
    logsService: LogsService;
    country: Countries;
    savedDocuments: PaymentInfo['savedDocuments'];
    session?: YunoSession;
    formFields: FormSection[];
}

const YunoCheckoutView: FC<YunoCheckoutControllerProps> = ({
    navigatorService,
    handlePayment,
    logsService,
    country,
    savedDocuments,
    session,
    formFields
}) => {
    const { t } = useTranslation();
    const yunoCheckoutRef = useRef<YunoCheckoutFeature>(null);
    const [loading, setLoading] = useState(false);
    //  formFields.length > 0;
    // TODO придумать как назвать переменную для formFields.length > 0;
    const isFormFieldNeeded = formFields.length > 0;


    const handleSubmit = async (formFillValues: FormValues) => {
        setLoading(true);
        if (session && yunoCheckoutRef.current) {
            return  yunoCheckoutRef.current.checkout(
                country,
                session,
                isFormFieldNeeded ? formFillValues.email : undefined
            );
        }

        return;
    };

    useEffect(() => {
        if (!isFormFieldNeeded) {
            handleSubmit({});
        }
    }, []);

    return (
        <>
            <YunoCheckout
                ref={yunoCheckoutRef}
                logsService={logsService}
                navigatorService={navigatorService}
                handlePayment={handlePayment}
            />

            <CenterLoader loading={loading} />

            {
                (loading || !isFormFieldNeeded ) ? null : (
                    <FormFieldView
                        country={country}
                        savedDocuments={savedDocuments}
                        formFields={formFields}
                        onSubmit={handleSubmit}
                        childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
                    />
                )
            }
        </>
    );
};

export default YunoCheckoutView;