import { getIziBankCardStruct } from '@/lib/payments/forms/bank-card/izi';
import {
    getOneVisionBankCardStruct
} from '@/lib/payments/forms/bank-card/onevision';
import { getIziCashStruct } from '@/lib/payments/forms/cash/izi';
import { getSimpaisaProviderControlledStruct } from '@/lib/payments/forms/provider-controled';

import Validation, { Validator } from '../../forms/validation';
import { Countries } from '../../locale/countries';

import { PaymentProviderName } from '../providers';

import {
    getCredoraxCreditCardStruct,
    getCredoraxExistedCardStruct,
} from './bank-card/credorax';
import {
    getDlocalCreditCardStruct,
    getDlocalExistedCardStruct,
} from './bank-card/dlocal';
import { getDlocalBankTransferStruct } from './bank-transfer/dlocal';
import { getDlocalCashStruct } from './cash/dlocal';
import { getFawryCashStruct } from './cash/fawry';
import {
    getBillingCityField,
    getCityField,
    getDocumentField,
    getFullAddressField,
    getFullBillingAddressField,
} from './fields';
import { getWooppayPhoneBalanceStruct } from './phone-balance/wooppay';
import {
    getBillingAddressSection,
    getDefaultSections,
    getHomeAddressSection,
    getPersonalDetailsSection,
} from './sections';

// TODO https://indriver.atlassian.net/browse/PRC-2144
/**
 * Enabled payment methods by vendor/country/payment type in payment-provider service
 */
export type FormId = string;

export type ExtraPayload = {
    short: boolean;
};

export enum FormType {
    NEW,
    EXISTED,
}

export type FormField = {
    id: FormId;
    title: string;
    mask?: string | typeof Number | typeof String;
    type?: string;
    formatter?: (value: string) => string;
    validators: Validator[];
};

export type FormSection = {
    id: FormId;
    title: string;
    fields: FormField[];
};

export type FormValues<T = Record<string, string>> = Record<string, string> & T;

const newCardRouter = (
    vendor: PaymentProviderName,
    countryId: Countries,
    extra: ExtraPayload,
) => {
    switch (vendor) {
        case PaymentProviderName.Dlocal:
            return getDlocalCreditCardStruct(countryId, extra);
        case PaymentProviderName.Credorax:
            return getCredoraxCreditCardStruct(countryId);
        case PaymentProviderName.Izi:
            return getIziBankCardStruct(countryId);
        case PaymentProviderName.OneVision:
            return getOneVisionBankCardStruct(countryId);
        default:
            return getDefaultSections();
    }
};

const existedCardRouter = (
    vendor: PaymentProviderName,
    countryId: Countries,
) => {
    switch (vendor) {
        case PaymentProviderName.Dlocal:
            return getDlocalExistedCardStruct(countryId);
        case PaymentProviderName.Credorax:
            return getCredoraxExistedCardStruct(countryId);
        default:
            return getDefaultSections();
    }
};

export const getBankCardStruct = (
    vendor: PaymentProviderName,
    countryId: Countries,
    formType: FormType,
    extra: ExtraPayload,
) => {
    const router = formType === FormType.NEW ? newCardRouter : existedCardRouter;

    return router(vendor, countryId, extra);
};

export const getBankTransferStruct = (
    vendor: PaymentProviderName,
    countryId: Countries,
    extra?: ExtraPayload,
) => {
    switch (vendor) {
        case PaymentProviderName.Dlocal:
            return getDlocalBankTransferStruct(countryId, extra);
        default:
            return getDefaultSections();
    }
};

export const getCashStruct = (
    vendor: PaymentProviderName,
    countryId: Countries,
) => {
    switch (vendor) {
        case PaymentProviderName.Dlocal:
            return getDlocalCashStruct(countryId);
        case PaymentProviderName.Fawry:
            return getFawryCashStruct(countryId);
        case PaymentProviderName.Izi:
            return getIziCashStruct(countryId);
        default:
            return getDefaultSections();
    }
};

export const getWalletStruct = (
    vendor: PaymentProviderName,
    countryId: Countries,
    extra?: ExtraPayload,
) => {
    switch (vendor) {
        case PaymentProviderName.Dlocal:
            return getDlocalBankTransferStruct(countryId, extra);
        default:
            return getDefaultSections();
    }
};

export const getPhoneBalanceStruct = (
    vendor: PaymentProviderName,
    countryId: Countries,
) => {
    switch (vendor) {
        case PaymentProviderName.Wooppay:
            return getWooppayPhoneBalanceStruct(countryId);
        default:
            return getDefaultSections();
    }
};

export const getProviderControlledStruct = (
    vendor: PaymentProviderName,
) => {
    switch (vendor) {
        case PaymentProviderName.Simpaisa:
            return getSimpaisaProviderControlledStruct();
        default:
            return getDefaultSections();
    }
};

export const getIyzicoStruct = () => {
    return {
        personalFields: [
            getPersonalDetailsSection([getDocumentField(Countries.Turkey)]),
            getHomeAddressSection([
                getCityField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 50),
                    ],
                }),
                getFullAddressField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 255),
                    ],
                }),
            ]),
        ],
        billingFields: [
            getBillingAddressSection([
                getBillingCityField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 50),
                    ],
                }),
                getFullBillingAddressField({
                    validators: [
                        Validation.required,
                        Validation.stringSizeValidator(1, 255),
                    ],
                }),
            ]),
        ],
    };
};
