import {
    BottomSheet,
    BottomSheetBody,
    BottomSheetCloseIcon,
    BottomSheetHeader,
    BottomSheetHeaderPostfix
} from '@indriver/nova';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import * as UI from './ui';

import Resources from '../../../../resources';

export type PaymentProviderBottomSheetProps = {
    title?: string;
    children: ReactElement;
    rootId: string;
    open: boolean;
    onClose: VoidFunction;
}

const PaymentProviderBottomSheet: FC<PaymentProviderBottomSheetProps> = ({
    title,
    rootId,
    open,
    onClose,
    children
}) => {
    const { t } = useTranslation();

    return (
        <>
            <UI.Modal id={rootId} />

            <BottomSheet
                opened={open}
                rootSelector={'#' + rootId}
            >
                <UI.BottomHeaderBold>
                    <BottomSheetHeader
                        title={title}
                        data-id={Resources.test.bottomSheetHeading}
                    />
                </UI.BottomHeaderBold>

                <BottomSheetHeaderPostfix
                    aria-label={t('features.PaymentMethodsList.closeLabel')}
                    data-id={Resources.test.bottomSheetClose}
                    onClick={onClose}
                >
                    <BottomSheetCloseIcon />
                </BottomSheetHeaderPostfix>

                <BottomSheetBody>
                    {children}
                </BottomSheetBody>
            </BottomSheet>
        </>
    );
};

export default PaymentProviderBottomSheet;
