import { FC, useEffect } from 'react';

import { PaymentType } from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import CenterLoader from '@/shared/ui/layout/center-loader';

import { PaymentFormData } from '../index';

// todo https://indriver.atlassian.net/browse/PRC-2181
const SlickBankCardController: FC<PaymentFormData> = ({
    store,
    services,
    onHideGoBack,
}) => {
    useEffect(() => {
        onHideGoBack();
        handleEnterAmount();
    }, []);

    const handleEnterAmount = () => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Slick,
            payment_option: PaymentType.BANK_CARD,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topupWithSlick({
                amount: parseInt(store.amount, 10),
            });

            if (data?.topupWithSlick.redirectURL) {
                services.navigatorService.goOutside(data.topupWithSlick.redirectURL);

                return;
            }

            services.navigatorService.showFinalPage('success');
        });
    };

    return (
        <CenterLoader loading />
    );
};

export default SlickBankCardController;
