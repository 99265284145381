import { variables } from '@indriver/yrel';
import styled, { css } from 'styled-components';

import { Header32Bold, Text16Medium, Text18Bold } from '@/shared/typography/text';
import PaymentMethodSelectCore from '@/shared/ui/domain/payment-method-select';

export const TopContent = styled.div`
    padding: 16px;
`;

export const Title = styled.div`
    ${Header32Bold};

    line-height: 44px;
    margin-bottom: 4px;
`;

export const SubTitle = styled.div`
    ${Text16Medium};

    padding: 4px 0 12px;
    line-height: 22px;
`;

export const DoneList = styled.ul`
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    display: flex;

    flex-direction: column;
  
    & > li {
        ${Text16Medium};
        display: flex;
        align-items: center;
        height: 36px;
        gap: 8px;
      
      & > svg > path {
        fill: ${variables['text-and-icon-primary']};
      }
    }
`;

export const SettingsWrapper = styled.div`
    padding-top: 4px;
    display: flex;
    flex-direction: column;
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  padding-top: 16px;
`;

export const FlexSpacer = styled.div``;

export const UnsettedSettingsWrapper = styled.div`
  padding: 0 16px;
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

export const Setting = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
  
    &:last-child {
        margin-bottom: 0;
    }
`;

export const SettingHeader = styled.span`
    ${Text18Bold};

    line-height: 24px;
    margin-bottom: 4px;
`;

export const Chips = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 12px;
`;

export const Chip = styled.div<{ $active?: boolean }>`
    color: ${variables['text-and-icon-primary']};
    border-radius: 24px;
    background: ${variables['background-secondary']};
    padding: 8px 12px;
    cursor: pointer;

    ${({ $active }) => $active && css`
        background: ${variables['text-and-icon-primary']};
        color: ${variables['statement-text-and-icon-oncolorpressed']};
    `};
    transition: all 0.3s;
`;

export const PaymentMethodSelect = styled(PaymentMethodSelectCore)`
    padding: 16px 0;    
`;

export const MethodListWrapper = styled.div`
    margin: 0 8px;
`;

export const MethodListItem = styled.div<{
    selected: boolean;
}>`
    padding-inline: 16px;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 20px;

    max-height: 93px;
    opacity: 1;
    transition: .3s all ease-out;

    ${props => props.selected && css`
        border-color: ${variables['text-and-icon-primary']};
    `}
    
    &:last-child {
        margin-bottom: 28px;
    }
`;

export const GoBackWrapper = styled.div`
    padding: 16px 0 0;

    button {
        height: 56px;
        width: 100%;
    }
`;
