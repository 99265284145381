import {
    MASK_AMEX,
    MASK_DINERSCLUB,
    MASK_DISCOVER,
    MASK_JCB,
    MASK_MAESTRO,
    MASK_MASTERCARD,
    MASK_MIR,
    MASK_VISA,
    REGEXP_AMEX,
    REGEXP_DINERSCLUB,
    REGEXP_DISCOVER,
    REGEXP_JCB,
    REGEXP_MAESTRO,
    REGEXP_MASTERCARD,
    REGEXP_MIR,
    REGEXP_VISA
} from './index';

export const getCardMask = (cardNumber: string): number[] | undefined => {
    const value = cardNumber.replace(/\D/g, '');

    if (value.match(REGEXP_AMEX)) {
        return MASK_AMEX;
    }

    if (value.match(REGEXP_DINERSCLUB)) {
        return MASK_DINERSCLUB;
    }

    if (value.match(REGEXP_DISCOVER)) {
        return MASK_DISCOVER;
    }

    if (value.match(REGEXP_JCB)) {
        return MASK_JCB;
    }

    if (value.match(REGEXP_MAESTRO)) {
        return MASK_MAESTRO;
    }

    if (value.match(REGEXP_MASTERCARD)) {
        return MASK_MASTERCARD;
    }

    if (value.match(REGEXP_MIR)) {
        return MASK_MIR;
    }

    if (value.match(REGEXP_VISA)) {
        return MASK_VISA;
    }

    return undefined;
};

export const formatLast4toShortDots = (last4: string): string =>
    ` • ${last4}`;

export const formatLast4toDots = (last4: string): string =>
    `•••• •••• •••• ${last4}`;
