import React, { ChangeEvent, RefObject } from 'react';

import { ValidationError } from '@/lib/forms/validation';
import { CardType } from '@/lib/payments/credit-card';
import { getCardType } from '@/lib/payments/credit-card/cardtypes';

import { formatCardNumberAndMoveCaret, getPanLen } from '../lib/format';
import { getCardIcon } from '../lib/icons';
import { isCardFilled, validateCardValue } from '../lib/validate';
import { Input } from '../ui';

import * as UI from './ui';

import Resources from '../../../../../resources';

type CardNumberProps = {
    ref: RefObject<CardNumber>;
    onError: (error: ValidationError) => void;
    onChange: (cardNumber: string) => void;
    onCompleted: VoidFunction;
}

type CardNumberState = {
    cardType: CardType | null;
    value: string;
}

class CardNumber extends React.Component<CardNumberProps, CardNumberState> {
    constructor(props: CardNumberProps) {
        super(props);

        this.state = {
            cardType: null,
            value: '',
        };
    }

    private handleValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const { onChange, onError, onCompleted } = this.props;
        const inputValue = evt.target.value.replace(/\s/g, '');
        if (!Resources.isRtl()) {
            // todo improve format for Arabic
            formatCardNumberAndMoveCaret(evt.target);
        }
        const cardType = getCardType(inputValue);

        this.setState({ cardType, value: inputValue });
        onChange(inputValue);
        onError(undefined);
        evt.target.setAttribute('maxlength', getPanLen(inputValue).toString());

        if (isCardFilled(inputValue)) {
            const error = validateCardValue(inputValue);

            if (error) {
                onError(error);

                return;
            }

            onCompleted();
        }
    };

    private handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
        this.validate(evt.target.value);
    };

    public validate = (value = this.state.value): boolean => {
        const { onError } = this.props;
        const error = validateCardValue(value);

        if (error) {
            onError(error);
        }

        return !error;
    };

    public render() {
        const { cardType } = this.state;

        return (
            <UI.Wrapper>
                {cardType ? <UI.CardIcon>{getCardIcon(cardType)}</UI.CardIcon> : null}

                <Input
                    autoComplete='cc-number'
                    pattern='\d*'
                    onBlur={this.handleBlur}
                    onChange={this.handleValueChange}
                />
            </UI.Wrapper>
        );
    }
}

export default CardNumber;
