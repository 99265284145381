import { gql } from '@apollo/client';

import { WooppaySession } from '.';

export type TopupWooppayMutation = {
    input: TopupWooppayInput,
}

export type TopupWooppayInput = {
    session: WooppaySession;
    confirmationCode: string;
}

export type TopupWooppayResponse = {
    topupWooppay: {
        result: boolean;
    }
}

export default gql(`
    mutation topupWooppay($input: TopupWooppayInput!) {
      topupWooppay(input: $input) {
        result
      }
    }
`);
