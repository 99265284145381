export const isValidByLuhnAlgorithm = (cardNumber: string): boolean => {
    return cardNumber
        .replace(/\D/g, '')
        .split('')
        .reverse()
        .map((symbol) => Number(symbol))
        .reduce((result, number, index) => {
            if (index % 2 === 0) {
                return result + number;
            }

            if (number > 4) {
                return result + Number(String(2 * number)[0]) + Number(String(2 * number)[1]);
            }

            return result + 2 * number;
        }, 0) % 10 === 0;
};