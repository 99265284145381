import { gql } from '@apollo/client';

import { PayrailsSession } from './index';

export type TopupWithSavedCardPayrailsInput = {
    session: PayrailsSession;
    instrumentID: string;
    document: string;
    amount: number;
};

export type TopupWithSavedCardPayrailsResponse = {
    topupWithSavedCardPayrails: {
        redirectUrl: string;
    };
};

export default gql(`
    mutation topupWithSavedCardPayrails ($input: TopupWithSavedCardPayrailsInput!){
            topupWithSavedCardPayrails(
                input: $input
            ) {
                redirectUrl
            }
        }
`);
