import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import PayrailsCard, { PayrailsCardData } from '@/features/credit-card/payrails-card';
import FormFieldView from '@/features/form-field-view';
import {
    extractOnlyOneError,
    PaymentProviderGQLFormError
} from '@/lib/errors/payment-provider/graphql-errors';
import { CreditCardForm } from '@/lib/forms/actions';
import { Countries } from '@/lib/locale/countries';
import {
    FormSection,
    FormValues,
} from '@/lib/payments/forms';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';

import { PayrailsInitOptions } from '../../../../../../../graphql/payrails';
import { AppData } from '../../../../../index';

type PayrailsBankCardViewProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    formFields: FormSection[];
    initOptions: PayrailsInitOptions;
    logsService: LogsService;
    configurationService: ConfigurationService;
    onSubmit: (
        formFillValues: FormValues<PayrailsCardData>
    ) => Promise<PaymentProviderGQLFormError>;
    onError: (error: string) => void;
}

const PayrailsBankCardView: FC<PayrailsBankCardViewProps> = ({
    country,
    savedDocuments,
    initOptions,
    formFields,
    logsService,
    configurationService,
    onSubmit,
    onError,
}) => {
    const { t } = useTranslation();
    const creditCardFormRef = useRef<CreditCardForm<PayrailsCardData>>(null);

    const handleSubmit = async (paymentFillFormData: FormValues) => {
        if (!creditCardFormRef.current) {
            return;
        }

        const creditCardData = await creditCardFormRef.current.getData();
        if (creditCardData === null) {
            return;
        }

        const error = await onSubmit({
            ...creditCardData,
            ...paymentFillFormData,
        });

        if (error) {
            onError(extractOnlyOneError(error));

            return;
        }

        return error;
    };

    return (
        <FormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={formFields}
            onSubmit={handleSubmit}
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
        >
            <PayrailsCard
                initOptions={initOptions}
                logsService={logsService}
                configurationService={configurationService}
                ref={creditCardFormRef}
                onError={onError}
            />
        </FormFieldView>
    );
};

export default PayrailsBankCardView;
