import { gql } from '@apollo/client';

export type DeletePayrailsPaymentMethodInput = {
    instrumentID: string;
}

export type DeletePayrailsPaymentMethodResponse = {
    deletePayrailsPaymentMethod: {
        success: boolean;
    }
}

export default gql(`
    mutation DeletePayrailsPaymentMethod($input: DeletePayrailsPaymentMethodInput!) {
        deletePayrailsPaymentMethod(input: $input) {
            success
        }
    }
`);
