import styled from 'styled-components';

import { Header20Bold } from '../../../typography/text';

export const Heading = styled.div`
  margin-top: 8px;
  height: 48px;
  align-items: center;
  display: flex;
`;

export const Title = styled.h2`
  ${Header20Bold};
`;

export const Icon = styled.div`
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-inline-start: 6px;
  }
`;
