import { ThemeProvider } from '@indriver/nova';
import React from 'react';
import { createRoot } from 'react-dom/client';

import NotificationView, {
    CallbackOptions,
    NotificationDesignType
} from '@/shared/ui/core/notification';

class NotificationService {
    static PORTAL_CONTAINER_CLASSNAME = 'portal-notification-container';

    private render(
        text: string,
        design: NotificationDesignType,
        options: CallbackOptions
    ): void {
        const body = window.document.querySelector<HTMLBodyElement>('body');
        if (!body) {
            throw Error('Cannot spawn notification, element body doesnt exist');
        }

        const portalContainer = document.createElement('div');
        portalContainer.id = NotificationService.PORTAL_CONTAINER_CLASSNAME;
        body.appendChild(portalContainer);

        const ref = React.createRef<NotificationView>();

        createRoot(portalContainer).render(
            <ThemeProvider>
                <NotificationView
                    ref={ref}
                    design={design}
                    text={text}
                    callbackOptions={options}
                    onClose={this.handleRemove}
                />
            </ThemeProvider>
        );

        if (options.timeout) {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.close();
                }
            }, options.timeout);
        }
    }

    private handleRemove(): void {
        const container = document.getElementById(NotificationService.PORTAL_CONTAINER_CLASSNAME);
        if (!container) {
            return;
        }

        document.body.removeChild(container);
    }

    public success(text: string): void {
        this.render(text, 'success', {
            timeout: 5000,
            caption: text,
        });
    }
}

export default NotificationService;
