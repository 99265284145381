import * as Sentry from '@sentry/react';
import { FC, forwardRef, Ref, useCallback, useImperativeHandle, useRef } from 'react';

import { PaymentProviderGQLFormError } from '@/lib/errors/payment-provider/graphql-errors';
import { getSessionErrorMessage } from '@/lib/errors/session';
import { Countries } from '@/lib/locale/countries';
import { PaymentProviderName } from '@/lib/payments/providers';
import SDK from '@/lib/payments/sdk';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import { YunoSession } from '@/services/payment-info-service';
import YunoService from '@/services/yuno-service';

export type YunoCheckoutFeature = {
    checkout: (
        country: Countries,
        session: YunoSession,
        email?: string
    ) => Promise<PaymentProviderGQLFormError>;
};

type YunoCheckoutProps = {
    ref: Ref<YunoCheckoutFeature>;
    logsService: LogsService;
    navigatorService: NavigatorService;
    handlePayment: (
        yuno: YunoService,
        oneTimeToken: string,
        email?: string
    ) => Promise<PaymentProviderGQLFormError>;
}

const YunoCheckout: FC<YunoCheckoutProps> = forwardRef((props, yunoCheckoutRef) => {
    const { handlePayment, logsService, navigatorService } = props;
    const ref = useRef(null);

    const handleError = useCallback((e: string) => {
        logsService.write(e);

        if (e === 'CANCELED_BY_USER') {
            navigatorService.goHome();

            return;
        }
        navigatorService.showFinalPage('error', {
            message: `yuno checkout: ${e}`
        });
    }, []);

    useImperativeHandle(yunoCheckoutRef, () => ({
        checkout: async (
            country,
            session,
            email
        ): Promise<PaymentProviderGQLFormError> => {
            if (!ref.current) {
                return;
            }

            const sdk = new SDK(logsService);
            sdk.get(PaymentProviderName.Yuno)
                .then(() => {
                    if (!session) {
                        navigatorService.showFinalPage('error', {
                            message: getSessionErrorMessage()
                        });

                        return;
                    }

                    // when window.Yuno.initialize passed by args, it invokes a bug
                    const yuno = window.Yuno.initialize(session.apiKey);
                    const service = new YunoService(yuno);

                    service.checkout({
                        session,
                        country,
                        onCreate: (oneTimeToken: string) => handlePayment(service, oneTimeToken, email),
                        onError: handleError
                    }).catch((e) => {
                        Sentry.captureException(`yuno checkout error: ${e}`);
                        logsService.write(`yuno checkout error: ${e}`);
                    }).then(() => {
                        logsService.write('yuno checkout success');
                    });

                })
                .catch(e => {
                    navigatorService.showFinalPage('error', {
                        message: e
                    });
                });

            return;
        }
    }), [yunoCheckoutRef]);

    return (
        <div
            ref={ref}
            id={YunoService.YUNO_CHECKOUT_ELEMENT_ID}
        />
    );

});

export default YunoCheckout;