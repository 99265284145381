import { useTheme } from '@indriver/nova';
import { FC, useEffect, useRef } from 'react';

import { ValidationError } from '@/lib/forms/validation';
import DlocalService from '@/services/dlocal-service';
import { DlocalFieldType, DlocalSecureField } from '@/services/dlocal-service/options';

import * as UI from './ui';


type DlocalInputProps = {
    dlocalService: DlocalService;
    type: DlocalFieldType;
    onError: (error?: ValidationError) => void;
    onEmpty?: VoidFunction;
}

const DlocalInput: FC<DlocalInputProps> = ({
    dlocalService,
    type,
    onError,
    onEmpty = Function.prototype,
}) => {
    const { theme } = useTheme();
    const ref = useRef(null);

    useEffect(() => {
        let field: DlocalSecureField|null = null;
        if (ref.current) {
            field = dlocalService.mountField(type, ref.current, theme);

            field?.on('change', (event) => {
                if (event.empty) {
                    onEmpty();
                }

                if (event.error) {
                    onError({ message: event.error.message });

                    return;
                }

                onError();
            });

            field?.on('blur', (event) => {
                if (event.error) {
                    onError({ message: event.error.message });

                    return;
                }

                onError();
            });
        }

        return () => {
            field?.unmount();
        };
    }, [ref, dlocalService]);

    return <UI.Wrapper ref={ref} />;
};

export default DlocalInput;
