import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ConfigurationService from '@/services/configuration-service';
import IPCService from '@/services/ipc-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';

import CashlessPage from './pages/cashless-page';
import InternalErrorPage from './pages/internal-error-page';
import PCIDSSBankCardPage from './pages/pcidss-bank-card-page';
import SuccessPage from './pages/success-page';

const NON_AUTH_PATHS = [
    NavigatorService.AppRoutes.SUCCESS_PAGE,
    NavigatorService.AppRoutes.INTERNAL_ERROR,
    NavigatorService.AppRoutes.PROVIDER_ERROR,
    NavigatorService.AppRoutes.CASHLESS,
    NavigatorService.AppRoutes.CASHLESS_FORM,
    NavigatorService.AppRoutes.PCI_DSS_TEST_FORM,
] as const;

export const isNonAuth = () =>
    NON_AUTH_PATHS.includes(window.location.pathname as (typeof NON_AUTH_PATHS)[number]);

type NonAuthRouterProps = {
    ipcService: IPCService;
    navigatorService: NavigatorService;
    logsService: LogsService;
    configurationService: ConfigurationService;
}

const NonAuthRouter: FC<NonAuthRouterProps> = (props) => {
    return (
        <Routes>
            <Route
                path={NavigatorService.AppRoutes.SUCCESS_PAGE}
                element={<SuccessPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.INTERNAL_ERROR}
                element={<InternalErrorPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.PROVIDER_ERROR}
                element={<InternalErrorPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.CASHLESS}
                element={<CashlessPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.CASHLESS_FORM}
                element={<CashlessPage {...props} />}
            />

            <Route
                path={NavigatorService.AppRoutes.PCI_DSS_TEST_FORM}
                element={<PCIDSSBankCardPage {...props} />}
            />
        </Routes>
    );
};

export default NonAuthRouter;
