import { gql } from '@apollo/client';

export type TopupWithIyzicoCardMutation = {
    input: TopupWithIyzicoCardInput,
}

export type TopupWithIyzicoCardInput = {
    amount: number;
    document: string;
    city: string;
    address: string;
    billingCity: string;
    billingAddress: string;
}

export type TopupWithIyzicoCardResponse = {
    topupWithIyzicoCard: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation topupWithIyzicoCard ($input: TopupWithIyzicoInput!){
        topupWithIyzicoCard(
            input: $input
        ) {
            redirectUrl
        }
    }
`);
