import styled from 'styled-components';

export const Title = styled.h1`
    margin: 13px 0;
    display: inline-block;
    width: 100%;
`;

export const InputWrapper = styled.div`
    padding: 8px 0;
`;

export const ShortWrapper = styled.div`
    min-width: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 8px 0;
`;

export const SeparateWrapper = styled.div`
    min-width: 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    padding: 8px 0;
`;
