import { Progress } from '@indriver/mireska';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationScreen } from '@/features/information-screen';
import IPCService from '@/services/ipc-service';
import NavigatorService from '@/services/navigator-service';

type SuccessPageProps = {
    ipcService: IPCService;
    navigatorService: NavigatorService;
}

const SuccessPage: FC<SuccessPageProps> = ({ ipcService, navigatorService }) => {
    const { t } = useTranslation();

    useEffect(() => {
        ipcService.sendPaymentInProgress();
    }, []);

    const handleClickButton = useCallback(() => {
        navigatorService.goWallet();
    }, []);

    return (
        <InformationScreen
            type='in-progress'
            heading={t('pages.successPage.title')}
            icon={Progress}
            description={t('pages.successPage.description')}
            buttonText={t('pages.successPage.submitCaption')}
            onClickButton={handleClickButton}
        />
    );
};

export default SuccessPage;
