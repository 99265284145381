import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormFieldView from '@/features/form-field-view';
import {
    PaymentProviderGQLFormError,
} from '@/lib/errors/payment-provider/graphql-errors';
import {
    FormValues,
    getProviderControlledStruct
} from '@/lib/payments/forms';
import {
    PaymentType
} from '@/lib/payments/payments';
import { PaymentProviderName } from '@/lib/payments/providers';
import { DataType } from '@/services/payment-provider-service';

import { PaymentFormData } from '../index';

const SimpaisaController: FC<PaymentFormData> = ({ store, paymentInfo, services }) => {
    const { t } = useTranslation();

    const handlePayment = async <T extends DataType['TopupWithSimpaisaInput']>(
        input: T
    ): Promise<PaymentProviderGQLFormError> => {
        services.analyticService.event('processing.topup_pay.click', {
            provider: PaymentProviderName.Simpaisa,
            payment_option: PaymentType.PROVIDER_CONTROLLED,
            sum: parseInt(store.amount, 10),
        });

        return services.paymentProviderService.process(async (req) => {
            const { data } = await req.topUpWithSimpaisa(input);

            if (data?.topupWithSimpaisa.redirectUrl) {
                services.navigatorService.goOutside(data.topupWithSimpaisa.redirectUrl);

                return;
            }

            if (data?.topupWithSimpaisa.redirectUrl === '') {
                await services.navigatorService.showFinalPage('error');
            }
        });
    };

    const handleSubmitPayment = async (
        formFillValues: FormValues,
    ): Promise<PaymentProviderGQLFormError> => {
        return handlePayment({
            phone: formFillValues.phone,
            amount: parseInt(store.amount, 10),
        });
    };

    return (
        <FormFieldView
            childrenTitle={t('views.PaymentMethods.BankCard.creditCardTitle')}
            country={paymentInfo.country}
            savedDocuments={paymentInfo.savedDocuments}
            formFields={
                getProviderControlledStruct(
                    PaymentProviderName.Simpaisa,
                )
            }
            onSubmit={handleSubmitPayment}
        />
    );
};

export default SimpaisaController;
