import { CopyClone, ForwardArrowRight } from '@indriver/mireska';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import KZPromo from '@/services/kz-promo';

import Resources from '../../resources';

import * as UI from './ui';

type PaycodeViewProps = {
    title?: string;
    paycode: string;
    children?: React.ReactNode;
    onClick: VoidFunction;
    promotion?: KZPromo;
}

const PaycodeView: FC<PaycodeViewProps> = ({
    title = '',
    paycode,
    children,
    onClick,
    promotion
}) => {
    const { t } = useTranslation();

    return (
        <div>
            {
                promotion?.shouldShowPromo() ? (
                    <UI.CheckoutBlock
                        onClick={promotion?.showPromo}
                    >
                        <UI.PaycodeBlock>
                            <UI.Paycode>{promotion.getUserId()}</UI.Paycode>

                            <UI.PromotionDescription>
                                <Trans i18nKey='features.PaycodeView.promotionText'>
                                    Your ID for <span>participation</span> in the gasoline giveaway
                                </Trans>
                            </UI.PromotionDescription>
                        </UI.PaycodeBlock>

                        <ForwardArrowRight size={24} />
                    </UI.CheckoutBlock>
                ) : null
            }

            <UI.CheckoutBlock onClick={onClick}>
                <UI.PaycodeBlock>
                    <UI.Paycode data-id={Resources.test.paycode}>{paycode}</UI.Paycode>

                    <UI.Description>
                        {title || t('features.PaycodeView.title')}
                    </UI.Description>
                </UI.PaycodeBlock>

                <div data-id={Resources.test.paycodeViewButtonCopy}>
                    <CopyClone
                        size={24}
                    />
                </div>
            </UI.CheckoutBlock>

            {children}
        </div>
    );
};

export default PaycodeView;
