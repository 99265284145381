import { gql } from '@apollo/client';

export type CreateBankTransferDlocalMutation = {
    input: CreateBankTransferDlocalInput;
}

export type CreateBankTransferDlocalInput = {
    amount: number;
    username: string;
    email: string;
    document: string;
    paymentMethodCode: string;
    state?: string;
    city?: string;
    zipCode?: string;
    street?: string;
    houseNumber?: string;
}

export type CreateBankTransferDlocalResponse = {
    createBankTransferDlocal: {
        redirectUrl: string;
    }
}

export default gql(`
    mutation createBankTransferDlocal($input: CreateBankTransferDlocalInput!) {
        createBankTransferDlocal(
            input: $input
        ) {
            redirectUrl
        }
    }
`);
