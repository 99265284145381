export const REGEXP_AMEX = /^3[47]/;
export const REGEXP_DINERSCLUB = /^3(?:0[0-5]|[68][0-9])/;
export const REGEXP_DISCOVER = /^6(?:011|5[0-9]{2})/;
export const REGEXP_JCB = /^(?:2131|1800|35[0-9]{3})/;
export const REGEXP_MAESTRO = /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{2,15}$/;
export const REGEXP_MASTERCARD = /^5[1-5]|^222[1-9]|^2([3-6]|2(2[1]|[3-9])|7([0-1]|2[0]))[0-9]|^2720/;
export const REGEXP_MIR = /^220/;
export const REGEXP_VISA = /^4/;

export const MASK_AMEX: number[] = [4, 6, 5];
export const MASK_DINERSCLUB: number[] = [4, 4, 4, 2];
export const MASK_DISCOVER: number[] = [4, 4, 4, 4];
export const MASK_JCB: number[] = [4, 4, 4, 4];
export const MASK_MAESTRO: number[] = [4, 4, 4, 4, 3];
export const MASK_MASTERCARD: number[] = [4, 4, 4, 4];
export const MASK_MIR: number[] = [4, 4, 4, 4, 3];
export const MASK_VISA: number[] = [4, 4, 4, 4];

export enum CardType {
    AMEX = 'amex',
    DINERSCLUB = 'dinersclub',
    DISCOVER = 'discover',
    JCB = 'jcb',
    MAESTRO = 'maestro',
    MASTERCARD = 'mastercard',
    MIR = 'mir',
    VISA = 'visa',
}