import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HidableFormFieldView from '@/features/hidable-form-field-view';
import { PaymentProviderGQLFormError } from '@/lib/errors/payment-provider/graphql-errors';
import { Countries } from '@/lib/locale/countries';
import { FormSection, FormValues } from '@/lib/payments/forms';
import Checkbox from '@/shared/ui/core/checkbox';

import { AppData } from '../../../../../index';

type IyzicoCheckoutSubmit = (
    checked: boolean,
    formFillValues: FormValues
) => Promise<PaymentProviderGQLFormError>;

type IyzicoCheckoutProps = {
    country: Countries;
    savedDocuments: AppData['paymentInfo']['savedDocuments'];
    personalFields: FormSection[];
    billingFields: FormSection[];
    handleSubmit: IyzicoCheckoutSubmit;
}

const IyzicoCheckout: FC<IyzicoCheckoutProps> = ({
    country,
    savedDocuments,
    personalFields,
    billingFields,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(true);

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    const handleFormFieldSubmit = (formFillValues: FormValues) => {
        return handleSubmit(checked, formFillValues);
    };

    return (
        <HidableFormFieldView
            country={country}
            savedDocuments={savedDocuments}
            formFields={personalFields}
            billingFields={billingFields}
            checked={checked}
            checkbox={
                <Checkbox
                    title={t('views.PaymentMethods.Wallet.Iyzico.checkbox.sameAddressLabel')}
                    onChange={handleCheckboxChange}
                    checked={checked}
                />
            }
            onSubmit={handleFormFieldSubmit}
        />
    );
};

export default IyzicoCheckout;
