import { FC } from 'react';

import * as UI from './ui';

type FrameProps = {
    id?: string;
    src: string;
    onLoad: VoidFunction;
}

const Frame: FC<FrameProps> = ({
    id,
    src,
    onLoad,
}) => {
    return (
        <UI.Iframe
            src={src}
            data-id={id}
            onLoad={onLoad}
        />
    );
};

export default Frame;
