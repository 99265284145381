import { FC } from 'react';

import * as UI from './ui';

import Resources from '../../../../resources';

type PaymentMethodSelectProps = {
    title: string;
    description?: string;
    highlight?: boolean;
    icon: React.ReactElement;
    children?: React.ReactElement;
    onClick: VoidFunction;
}

const PaymentMethodSelect: FC<PaymentMethodSelectProps> = ({
    title,
    description,
    highlight,
    icon,
    children,
    onClick
}) => {
    return (
        <UI.Wrapper onClick={onClick}>
            <UI.IconWrapper>
                {icon}
            </UI.IconWrapper>

            <UI.Body>
                <UI.TextWrapper>
                    <UI.Text data-id={Resources.test.paymentMethodSelectTitle}>{title}</UI.Text>

                    <UI.Description
                        data-id={Resources.test.paymentMethodSelectDescription(highlight)}
                        $highlight={highlight}
                    >
                        {description}
                    </UI.Description>
                </UI.TextWrapper>

                {children}
            </UI.Body>
        </UI.Wrapper>
    );
};

export default PaymentMethodSelect;
