import { Button } from '@indriver/nova';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as UI from './ui';

import Resources from '../../../../../resources';
import ModalSheet from '../../../core/modal-sheet';

type CVVHintProps = {
    open: boolean;
    onClose: VoidFunction;
}

const CVVHint: FC<CVVHintProps> = ({
    open,
    onClose
}) => {
    const { t } = useTranslation();

    return (
        <ModalSheet
            open={open}
            title={t('shared.ui.domain.CreditCardField.CVVHint.validationMessage')}
            body={
                <UI.ContentWrapper>
                    <UI.ImageContainer>
                        <img
                            src={Resources.files.cvcHelper}
                            alt='cvc image explanation'
                        />
                    </UI.ImageContainer>

                    <UI.Text as='div'>{t('shared.ui.domain.CreditCardField.CVVHint.location')}</UI.Text>
                </UI.ContentWrapper>
            }
            footer={
                <Button
                    data-id={Resources.test.submitCVV}
                    size='l'
                    design='secondary'
                    onClick={onClose}
                >
                    {t('shared.ui.domain.CreditCardField.CVVHint.submitCaption')}
                </Button>
            }
        />
    );
};

export default CVVHint;
